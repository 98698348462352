import React, {useContext} from 'react';
import {Link, useNavigate} from "react-router-dom";
import {AuthContext} from "../context/Context";
import Loadit from "../funcs/Load";
import OneSubOrder from "./OneSubOrder";
import IcoTrash from "./IcoTrash";
import IcoClone from "./IcoClone";
import IcoArr from "./IcoArr";

const OneOrder = (props) => {
    // states and vars
    const navigate = useNavigate()
    const {Q_, Qs_, Lg_,
        isSysDat,
        isLandscape,
        deleteOrder, setOrders, setPaOrders,
        ordersReload, setOrdersReload,
        isPrices,
        openMessage,

        isHideOldChecked,
        filterCity,
        filterClient,
        filterPartner,
        filterWorker,

        filterPaType,
        filterPaCity,
        filterPaStep,
        filterPaClient,
        filterPaPartner,
        filterPaWorker,
    } = useContext(AuthContext)

    // clone order
    async function cloneOrder(str) {
        Q_('.popup-wait').style.display = 'grid'
        let resp1 = await Loadit.cloneOrder(str)

        // reload orders
        let tmQ = Qs_('.page-orders-wrp .one-order').length
        let countOfHaving = tmQ ? tmQ + 1 : 100
        let resp2 = await Loadit.getOrders('two', countOfHaving, '', filterCity, isHideOldChecked, filterClient, filterPartner, filterWorker)
        setOrders(resp2)
        setOrdersReload(!ordersReload)

        // reload pa-orders
        let resp3 = await Loadit.getPaOrders(filterPaType, 999, '', filterPaCity, filterPaStep, filterPaClient, filterPaPartner, filterPaWorker)
        setPaOrders(resp3)

        window.scrollTo(0, 0);
        Q_('.popup-wait').style.display = 'none';
    }

    return (
        <div className={`one one-order ${props.thisOrder.subs ? 'big' : ''}`} data-open="closed">
            <div className="one-order-ico" onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                if(e.target.closest('.one-order').getAttribute('data-open') === 'closed'){
                    e.target.closest('.one-order').setAttribute('data-open', 'open')
                }else{
                    e.target.closest('.one-order').setAttribute('data-open', 'closed')
                }
            }}>
                {props.thisOrder.subs
                    ?   <IcoArr/>
                    :   ''
                }
                {props.thisOrder.subs && !isLandscape
                    ?   <span>Подзаказы</span>
                    :   ''
                }
            </div>
            <div className="one-order-wrp" onClick={()=>{
                navigate(`/orders/${props.thisOrder.sb ? 'p' : ''}${props.thisOrder.id}`);
            }}>
                <p className="one-order-id">{isLandscape ? <i>id:</i> : ''}<span>{props.thisOrder.id}</span></p>
                <p className="one-order-step"><span data-tooltip="Стадия заказа">{isLandscape ? '' : '• '}{isSysDat.st[props.thisOrder.st]}</span></p>
                <p className="one-order-create"><span>{isLandscape ? '' : '• '}{props.thisOrder.cr}</span></p>
                <p className={`one-order-price ${Lg_('auth-role-prices') && Lg_('auth-role-prices') * 1 === 7 ? 'hideWhileCalc' : ''}`}>
                    <span>{props.thisOrder.pc[isPrices - 1].toLocaleString('ru-RU')} р</span>
                </p>
                <p className="one-order-name">
                    <span data-tooltip={`Название ${props.thisOrder.sb ? 'фирмы заказчика' : 'клиента'}`}
                        className={`one-order-name-in ${
                            !props.thisOrder.sb && props.thisOrder.cl
                            ?   props.thisOrder.cl === "3059" 
                                ?   'hideWhile' 
                                :   ''
                            :   ''
                        }`}
                    >
                        {props.thisOrder.cl
                            ?   props.thisOrder.cl === "3059"
                                ?   props.thisOrder.sb
                                    ?   'Сторонний поставщик'
                                    :   ''
                                :   isSysDat.companies[props.thisOrder.cl] ? isSysDat.companies[props.thisOrder.cl].nm : ''
                            :   'Фирма заказчик не указана'
                        }
                    </span> {props.thisOrder.ds
                        ?   <i data-tooltip="Первые 5 слов из описания заказа">
                                {props.thisOrder.cl !== "3059" && props.thisOrder.ds || props.thisOrder.sb && props.thisOrder.ds
                                    ?   '• '
                                    :   ''
                                }
                                {props.thisOrder.ds + '...'}
                            </i>
                        :   ''
                    }
                    {props.thisOrder.nw
                        ?   <span className="one-order-new"
                                  data-tooltip="Внесите нужные изменения и сохраните заказ, после чего эта надпись исчезнет"
                            >Не отредактированный {props.thisOrder.sb ? 'под' : ''}заказ</span>
                        :   ''
                    }
                </p>
                <p className="one-order-men">
                <span data-tooltip="Фирма исполнитель">
                    {props.thisOrder.is
                        ?   props.thisOrder.is == 3059
                            ?   'Сторонний поставщик'
                            :   isSysDat.companies[props.thisOrder.is] ? isSysDat.companies[props.thisOrder.is].nm : ''
                        :   props.thisOrder.sb
                            ?   'Исполнитель не указан'
                            :   ''
                    }
                </span> <i data-tooltip="Ответственный сотрудник">
                    • {isSysDat.users.find(el => el.value === props.thisOrder.rs) ? isSysDat.users.find(el => el.value === props.thisOrder.rs).name : ''}
                </i>
                </p>
                <p className="one-order-city"><span>{isLandscape ? '' : '• '}{isSysDat.cities[props.thisOrder.ct]}</span></p>
            </div>
            <div className="one-order-btns" onClick={(e)=>{
                e.preventDefault()
                e.stopPropagation()
                if(!isLandscape){
                    e.target.classList.add('active')
                }
            }}>
                <div className={`one-order-clone ${props.thisOrder.sb ? 'hideWhileCalc' : ''}`}
                     data-tooltip="Создать дубль"
                     onClick={(ev) => {
                         ev.preventDefault()
                         ev.stopPropagation()
                         if(Lg_('auth-id') == 5){
                             openMessage() // if guest account
                         }else{
                             cloneOrder(props.thisOrder.id)
                         }
                     }}
                ><IcoClone/></div>
                <div className="one-order-delete"
                     data-tooltip="Удалить" onClick={(ev) => {
                        ev.preventDefault()
                        ev.stopPropagation()
                        if(Lg_('auth-id') == 5){
                            openMessage() // if guest account
                        }else{
                            deleteOrder(props.thisOrder.id)
                        }
                     }}
                ><IcoTrash/></div>
            </div>
            {props.thisOrder.subs
                ?   <div className="one-order-suborders">
                        <OneSubOrder thisOrder={props.thisOrder.subs[props.thisOrder.subs.length-1]}
                                     notSub={true}
                                     fromOrderList={true}
                                     curOrder={props.thisOrder.id}
                                     curSum={0}
                                     curIspo={0} setCurIspo={''}
                                     curStep={0} setCurStep={''}
                                     curDtPlan={0} setCurDtPlan={''}
                                     setOrder={''}
                                     suborders={props.thisOrder.subs} setSuborders={''}
                                     setIsContent={''}
                                     setChanges={''}
                                     setSaving={''}
                                     loadThisOrder={''}
                                     createSubOrder={''}
                        />
                    </div>
                :   ''
            }
        </div>
    );
};

export default OneOrder;