import React, {useContext, useEffect, useState} from 'react';
import {Link, useParams, useNavigate} from "react-router-dom";
import Loadit from "../funcs/Load";
import OneElement from "../components/OneElement";
import {AuthContext} from "../context/Context";
import {evaluate} from 'mathjs';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import OneSubOrder from "../components/OneSubOrder";
import IcoGear from "../components/IcoGear";
import IcoMenEdit from "../components/IcoMenEdit";
import IcoMenNew from "../components/IcoMenNew";
import BlockAddElement from "../components/BlockAddElement";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const PgOrder = () => {
    // states and vars
    const params = useParams()
    const navigate = useNavigate()
    const {Q_, Qs_, Lg_,
        isSysDat,
        setOrders, setPaOrders,
        isPrices, setIsPrices,
        setIsPage,
        sumReady, setSumReady,
        ordersReload, setOrdersReload,

        isHideOldChecked,
        filterCity,
        filterClient,
        filterPartner,
        filterWorker,

        filterPaType,
        filterPaCity,
        filterPaStep,
        filterPaClient,
        filterPaPartner,
        filterPaWorker,

        setPopClient,
        popClientBlank,
        setPopClientCur,
        isLandscape,
        popKP_picDel, setPopKP_picDel,
        popKP_txtDel, setPopKP_txtDel,
        popKP_setDel, setPopKP_setDel,

        deleteOrder,
        openMessage,
        pushRout,
    } = useContext(AuthContext)
    // popups
    const [paramsVars] = useState({
        a : 1,
        b : 1,
        c : 1,
        d : 1,
        e : 1,
        f : 1,
        g : 1,
        h : 1,
        i : 1,
        j : 1,
        k : 1,
        l : 1,
        m : 1,
        n : 1,
        o : 1,
        p : 1,
        q : 1,
        r : 1,
        s : 1,
        t : 1,
        u : 1,
        v : 1,
        w : 1,
        x : 1,
        y : 1,
        z : 1,
        aa : 1,
        ba : 1,
        ca : 1,
        da : 1,
        ea : 1,
        fa : 1,
        ga : 1,
        ha : 1,
        ia : 1,
        ja : 1,
        ka : 1,
        la : 1,
        ma : 1,
        na : 1,
        oa : 1,
        pa : 1,
        qa : 1,
        ra : 1,
        sa : 1,
        ta : 1,
        ua : 1,
        va : 1,
        wa : 1,
        xa : 1,
        ya : 1,
        za : 1,
        ab : 1,
        bb : 1,
        cb : 1,
        db : 1,
        eb : 1,
        fb : 1,
        gb : 1,
        hb : 1,
        ib : 1,
        jb : 1,
        kb : 1,
        lb : 1,
        mb : 1,
        nb : 1,
        ob : 1,
        pb : 1,
        qb : 1,
        rb : 1,
        sb : 1,
        tb : 1,
        ub : 1,
        vb : 1,
        wb : 1,
        xb : 1,
        yb : 1,
        zb : 1,
        ac : 1,
        bc : 1,
        cc : 1,
        dc : 1,
        ec : 1,
        fc : 1,
        gc : 1,
        hc : 1,
        ic : 1,
        jc : 1,
        kc : 1,
        lc : 1,
        mc : 1,
        nc : 1,
        oc : 1,
        pc : 1,
        qc : 1,
        rc : 1,
        sc : 1,
        tc : 1,
        uc : 1,
        vc : 1,
        wc : 1,
        xc : 1,
        yc : 1,
        zc : 1,
        ad : 1,
        bd : 1,
        cd : 1,
        dd : 1,
        ed : 1,
        fd : 1,
        gd : 1,
        hd : 1,
        id : 1,
        jd : 1,
        kd : 1,
        ld : 1,
        md : 1,
        nd : 1,
        od : 1,
        pd : 1,
        qd : 1,
        rd : 1,
        sd : 1,
        td : 1,
        ud : 1,
        vd : 1,
        wd : 1,
        xd : 1,
        yd : 1,
        zd : 1,
        ae : 1,
        be : 1,
        ce : 1,
        de : 1,
        ee : 1,
        fe : 1,
        ge : 1,
        he : 1,
        ie : 1,
        je : 1,
        ke : 1,
        le : 1,
        me : 1,
        ne : 1,
        oe : 1,
        pe : 1,
        qe : 1,
        re : 1,
        se : 1,
        te : 1,
        ue : 1,
        ve : 1,
        we : 1,
        xe : 1,
        ye : 1,
        ze : 1,
        af : 1,
        bf : 1,
        cf : 1,
        df : 1,
        ef : 1,
        ff : 1,
        gf : 1,
        hf : 1,
        if : 1,
        jf : 1,
        kf : 1,
        lf : 1,
        mf : 1,
        nf : 1,
        of : 1,
        pf : 1,
        qf : 1,
        rf : 1,
        sf : 1,
        tf : 1,
        uf : 1,
        vf : 1,
        wf : 1,
        xf : 1,
        yf : 1,
        zf : 1,
        ag : 1,
        bg : 1,
        cg : 1,
        dg : 1,
        eg : 1,
        fg : 1,
        gg : 1,
        hg : 1,
        ig : 1,
        jg : 1,
        kg : 1,
        lg : 1,
        mg : 1,
        ng : 1,
        og : 1,
        pg : 1,
        qg : 1,
        rg : 1,
        sg : 1,
        tg : 1,
        ug : 1,
        vg : 1,
        wg : 1,
        xg : 1,
        yg : 1,
        zg : 1,
        ah : 1,
        bh : 1,
        ch : 1,
        dh : 1,
        eh : 1,
        fh : 1,
        gh : 1,
        hh : 1,
        ih : 1,
        jh : 1,
        kh : 1,
        lh : 1,
        mh : 1,
        nh : 1,
        oh : 1,
        ph : 1,
        qh : 1,
        rh : 1,
        sh : 1,
        th : 1,
        uh : 1,
        vh : 1,
        wh : 1,
        xh : 1,
        yh : 1,
        zh : 1,
        ai : 1,
        bi : 1,
        ci : 1,
        di : 1,
        ei : 1,
        fi : 1,
        gi : 1,
        hi : 1,
        ii : 1,
        ji : 1,
        ki : 1,
        li : 1,
        mi : 1,
        ni : 1,
        oi : 1,
        pi : 1,
        qi : 1,
        ri : 1,
        si : 1,
        ti : 1,
        ui : 1,
        vi : 1,
        wi : 1,
        xi : 1,
        yi : 1,
        zi : 1,
        aj : 1,
        bj : 1,
        cj : 1,
        dj : 1,
        ej : 1,
        fj : 1,
        gj : 1,
        hj : 1,
        ij : 1,
        jj : 1,
        kj : 1,
        lj : 1,
        mj : 1,
        nj : 1,
        oj : 1,
        pj : 1,
        qj : 1,
        rj : 1,
        sj : 1,
        tj : 1,
        uj : 1,
        vj : 1,
        wj : 1,
        xj : 1,
        yj : 1,
        zj : 1,
        ak : 1,
        bk : 1,
        ck : 1,
        dk : 1,
        ek : 1,
        fk : 1,
        gk : 1,
        hk : 1,
        ik : 1,
        jk : 1,
        kk : 1,
        lk : 1,
        mk : 1,
        nk : 1,
        ok : 1,
        pk : 1,
        qk : 1,
        rk : 1,
        sk : 1,
        tk : 1,
        uk : 1,
        vk : 1,
        wk : 1,
        xk : 1,
        yk : 1,
        zk : 1,
        al : 1,
        bl : 1,
        cl : 1,
        dl : 1,
        el : 1,
        fl : 1,
        gl : 1,
        hl : 1,
        il : 1,
        jl : 1,
        kl : 1,
        ll : 1,
        ml : 1,
        nl : 1,
        ol : 1,
        pl : 1,
        ql : 1,
        rl : 1,
        sl : 1,
        tl : 1,
        ul : 1,
        vl : 1,
        wl : 1,
        xl : 1,
        yl : 1,
        zl : 1,
        am : 1,
        bm : 1,
        cm : 1,
        dm : 1,
        em : 1,
        fm : 1,
        gm : 1,
        hm : 1,
        im : 1,
        jm : 1,
        km : 1,
        lm : 1,
        mm : 1,
        nm : 1,
        om : 1,
        pm : 1,
        qm : 1,
        rm : 1,
        sm : 1,
        tm : 1,
        um : 1,
        vm : 1,
        wm : 1,
        xm : 1,
        ym : 1,
        zm : 1,
        an : 1,
        bn : 1,
        cn : 1,
        dn : 1,
        en : 1,
        fn : 1,
        gn : 1,
        hn : 1,
        in : 1,
        jn : 1,
        kn : 1,
        ln : 1,
        mn : 1,
        nn : 1,
        on : 1,
        pn : 1,
        qn : 1,
        rn : 1,
        sn : 1,
        tn : 1,
        un : 1,
        vn : 1,
        wn : 1,
        xn : 1,
        yn : 1,
        zn : 1,
        ao : 1,
        bo : 1,
        co : 1,
        do : 1,
        eo : 1,
        fo : 1,
        go : 1,
        ho : 1,
        io : 1,
        jo : 1,
        ko : 1,
        lo : 1,
        mo : 1,
        no : 1,
        oo : 1,
        po : 1,
        qo : 1,
        ro : 1,
        so : 1,
        to : 1,
        uo : 1,
        vo : 1,
        wo : 1,
        xo : 1,
        yo : 1,
        zo : 1,
        ap : 1,
        bp : 1,
        cp : 1,
        dp : 1,
        ep : 1,
        fp : 1,
        gp : 1,
        hp : 1,
        ip : 1,
        jp : 1,
        kp : 1,
        lp : 1,
        mp : 1,
        np : 1,
        op : 1,
        pp : 1,
        qp : 1,
        rp : 1,
        sp : 1,
        tp : 1,
        up : 1,
        vp : 1,
        wp : 1,
        xp : 1,
        yp : 1,
        zp : 1,
        aq : 1,
        bq : 1,
        cq : 1,
        dq : 1,
        eq : 1,
        fq : 1,
        gq : 1,
        hq : 1,
        iq : 1,
        jq : 1,
        kq : 1,
        lq : 1,
        mq : 1,
        nq : 1,
        oq : 1,
        pq : 1,
        qq : 1,
        rq : 1,
        sq : 1,
        tq : 1,
        uq : 1,
        vq : 1,
        wq : 1,
        xq : 1,
        yq : 1,
        zq : 1,
        ar : 1,
        br : 1,
        cr : 1,
        dr : 1,
        er : 1,
        fr : 1,
        gr : 1,
        hr : 1,
        ir : 1,
        jr : 1,
        kr : 1,
        lr : 1,
        mr : 1,
        nr : 1,
        or : 1,
        pr : 1,
        qr : 1,
        rr : 1,
        sr : 1,
        tr : 1,
        ur : 1,
        vr : 1,
        wr : 1,
        xr : 1,
        yr : 1,
        zr : 1,
        as : 1,
        bs : 1,
        cs : 1,
        ds : 1,
        es : 1,
        fs : 1,
        gs : 1,
        hs : 1,
        is : 1,
        js : 1,
        ks : 1,
        ls : 1,
        ms : 1,
        ns : 1,
        os : 1,
        ps : 1,
        qs : 1,
        rs : 1,
        ss : 1,
        ts : 1,
        us : 1,
        vs : 1,
        ws : 1,
        xs : 1,
        ys : 1,
        zs : 1,
        at : 1,
        bt : 1,
        ct : 1,
        dt : 1,
        et : 1,
        ft : 1,
        gt : 1,
        ht : 1,
        it : 1,
        jt : 1,
        kt : 1,
        lt : 1,
        mt : 1,
        nt : 1,
        ot : 1,
        pt : 1,
        qt : 1,
        rt : 1,
        st : 1,
        tt : 1,
        ut : 1,
        vt : 1,
        wt : 1,
        xt : 1,
        yt : 1,
        zt : 1,
        au : 1,
        bu : 1,
        cu : 1,
        du : 1,
        eu : 1,
        fu : 1,
        gu : 1,
        hu : 1,
        iu : 1,
        ju : 1,
        ku : 1,
        lu : 1,
        mu : 1,
        nu : 1,
        ou : 1,
        pu : 1,
        qu : 1,
        ru : 1,
        su : 1,
        tu : 1,
        uu : 1,
        vu : 1,
        wu : 1,
        xu : 1,
        yu : 1,
        zu : 1,
        av : 1,
        bv : 1,
        cv : 1,
        dv : 1,
        ev : 1,
        fv : 1,
        gv : 1,
        hv : 1,
        iv : 1,
        jv : 1,
        kv : 1,
        lv : 1,
        mv : 1,
        nv : 1,
        ov : 1,
        pv : 1,
        qv : 1,
        rv : 1,
        sv : 1,
        tv : 1,
        uv : 1,
        vv : 1,
        wv : 1,
        xv : 1,
        yv : 1,
        zv : 1,
        aw : 1,
        bw : 1,
        cw : 1,
        dw : 1,
        ew : 1,
        fw : 1,
        gw : 1,
        hw : 1,
        iw : 1,
        jw : 1,
        kw : 1,
        lw : 1,
        mw : 1,
        nw : 1,
        ow : 1,
        pw : 1,
        qw : 1,
        rw : 1,
        sw : 1,
        tw : 1,
        uw : 1,
        vw : 1,
        ww : 1,
        xw : 1,
        yw : 1,
        zw : 1,
        ax : 1,
        bx : 1,
        cx : 1,
        dx : 1,
        ex : 1,
        fx : 1,
        gx : 1,
        hx : 1,
        ix : 1,
        jx : 1,
        kx : 1,
        lx : 1,
        mx : 1,
        nx : 1,
        ox : 1,
        px : 1,
        qx : 1,
        rx : 1,
        sx : 1,
        tx : 1,
        ux : 1,
        vx : 1,
        wx : 1,
        xx : 1,
        yx : 1,
        zx : 1,
        ay : 1,
        by : 1,
        cy : 1,
        dy : 1,
        ey : 1,
        fy : 1,
        gy : 1,
        hy : 1,
        iy : 1,
        jy : 1,
        ky : 1,
        ly : 1,
        my : 1,
        ny : 1,
        oy : 1,
        py : 1,
        qy : 1,
        ry : 1,
        sy : 1,
        ty : 1,
        uy : 1,
        vy : 1,
        wy : 1,
        xy : 1,
        yy : 1,
        zy : 1,
        az : 1,
        bz : 1,
        cz : 1,
        dz : 1,
        ez : 1,
        fz : 1,
        gz : 1,
        hz : 1,
        iz : 1,
        jz : 1,
        kz : 1,
        lz : 1,
        mz : 1,
        nz : 1,
        oz : 1,
        pz : 1,
        qz : 1,
        rz : 1,
        sz : 1,
        tz : 1,
        uz : 1,
        vz : 1,
        wz : 1,
        xz : 1,
        yz : 1,
        zz : 1,
    })
    const [nomPopParams, setNomPopParams] = useState({})
    const [nomPopFinal, setNomPopFinal] = useState(()=>{
        let returnedParams = {...paramsVars}
        returnedParams['kol'] = 1
        returnedParams['kolvo'] = 1
        return returnedParams
    })
    const [nomPopLogic, setNomPopLogic] = useState({...paramsVars})
    const [nomPopChange, setNomPopChange] = useState(false)
    const [pricePopData, setPricePopData] = useState({
        cena : -1,
        summ : -1,
        newsumm : -1,
        count : 0,
        self : '',
        papa : '',
    })
    const [printPopDoc, setPrintPopDoc] = useState('1')
    const [printPop2Pay, setPrintPop2Pay] = useState('2')
    const [printPopKP, setPrintPopKP] = useState({})
    const [printPopKP_builder, setPrintPopKP_builder] = useState(true)
    const [printPopKP_pre, setPrintPopKP_pre] = useState({
        top: [],
        bottom: [],
    })
    const [printPopKP_pic, setPrintPopKP_pic] = useState(['',['','','','','','','','','','','']])
    const [printPopKP_txt, setPrintPopKP_txt] = useState(['',['','','','','','','','','','','']])
    const [printPopKP_set, setPrintPopKP_set] = useState('1')
    // add nomenclature
    const [addNom, setAddNom] = useState(0)
    const [addCat, setAddCat] = useState('-')
    const [addActive, setAddActive] = useState(false)
    // status indicators
    const [changes, setChanges] = useState(false)
    const [saving, setSaving] = useState(false)
    const [curClient, setCurClient] = useState('')
    const [curIspo, setCurIspo] = useState('')
    const [curStep, setCurStep] = useState('')
    const [curDtPlan, setCurDtPlan] = useState('')
    const [rolled, setRolled] = useState({})
    // listeners
    const [popFullNom, setPopFullNom] = useState(false)
    const NomResizeObserver = new ResizeObserver((entries) => {
        for (let entry of entries) {
            if(Q_(".popup-nom .popup-wrp-dad")){
                if(entry.target.clientHeight + 6 >= window.innerHeight){
                    Q_(".popup-nom .popup-wrp-dad").classList.add('popup-wrp-full')
                }else{
                    Q_(".popup-nom .popup-wrp-dad").classList.remove('popup-wrp-full')
                }
            }
        }
    })
    const [popFullPrinter, setPopFullPrinter] = useState(false)
    const PrinterResizeObserver = new ResizeObserver((entries) => {
        for (let entry of entries) {
            if(Q_(".popup-printer .popup-wrp-dad")){
                if(entry.target.clientHeight + 6 >= window.innerHeight){
                    Q_(".popup-printer .popup-wrp-dad").classList.add('popup-wrp-full')
                }else{
                    Q_(".popup-printer .popup-wrp-dad").classList.remove('popup-wrp-full')
                }
            }
        }
    })
    // data
    const [isContent, setIsContent] = useState([])
    const [order, setOrder] = useState(() => loadThisOrder(''))
    const [suborders, setSuborders] = useState([])
    const [sumTotal, setSumTotal] = useState(0)
    const pregLine = /^\s*\d*[\.\,]?\d*\s*$/

    // load Order
    async function loadThisOrder(incId){
        let strId = params.id

        // if incaming data
        if(incId){strId = incId}

        // load order and suborders
        let response
        if(strId === 'new'){
            response = {
                count: 1,
                data: [{
                    client: "",
                    ct: Lg_('auth-city-id'),
                    id: 'new',
                    responsible: Lg_('auth-id'),
                }],
                suborders: [],
            }
            await new Promise(resolve => setTimeout(resolve, 200))
            setIsPage('order')
        }else if(strId[0] === 'p'){
            response = await Loadit.getSubOrder(strId.slice(1))
            setIsPage('pa_order')
        }else{
            response = await Loadit.getOneOrder(strId)
            setIsPage('order')
        }

        // check: load order or not
        if(response.data.length > 0){
            // set "Saved prices" before set "Order"
            if(response.data[0].pt){
                if(Number.isInteger(response.data[0].pt*1)){
                    if(0 < response.data[0].pt < 4){
                        // check: can or not change price to "saved"
                        let tmpPr = [1,2,3]
                        let tmpP_ = Lg_('auth-role-prices')
                        if(tmpP_ && tmpP_ !== 'null'){
                            if(tmpP_ == 1){
                                tmpPr = [1]
                            }else if(tmpP_ == 2){
                                tmpPr = [2]
                            }else if(tmpP_ == 3){
                                tmpPr = [3]
                            }else if(tmpP_ == 4){
                                tmpPr = [1,2]
                            }else if(tmpP_ == 5){
                                tmpPr = [1,3]
                            }else if(tmpP_ == 6){
                                tmpPr = [2,3]
                            }
                        }
                        if(tmpPr.indexOf(response.data[0].pt*1) != -1){
                            setIsPrices(response.data[0].pt*1)
                            await new Promise(resolve => setTimeout(resolve, 100))
                        }
                    }
                }
            }

            // set order
            setOrder(response.data[0])

            // set content
            if(response.data[0].content){
                setIsContent(eval(response.data[0].content))
                setTimeout(function () {
                    collectSumm()
                },500)
            }else{
                setIsContent([])
            }

            // set suborders
            setSuborders(response.suborders)
        }else{
            setOrder({error: 1})
        }
    }

    // Save order
    async function saveOrder() {
        Q_('.popup-wait').style.display = 'grid'

        // function for collection sums
        let checker = 1
        async function collectOnePriceSumm(WhereToSave){
            checker = 1
            Qs_('.page-order-content-wrp .nom').forEach((nm) => {
                if(nm.getAttribute('data-cena') === 'false'){
                    checker = 0
                }
            })
            if(Q_('.page-order-result-summ-in').getAttribute('data-price0') === '-'){
                checker = 0
            }
            if(checker === 0){
                await new Promise(resolve => setTimeout(resolve, 100))
                return collectOnePriceSumm(WhereToSave)
            }else{
                let resultOfCalc = Q_('.page-order-result-summ-in').getAttribute('data-price0')
                Q_('.page-order-params-wrp .param-field[name="'+WhereToSave+'"]').value = resultOfCalc
                return true
            }
        }

        // collection sums
        if(Q_('.page-order-result-summ-in')){

            // check loading of prices
            let rootChecker = 1
            Qs_('.page-order-content-wrp .nom').forEach((nm) => {
                if(nm.getAttribute('data-cena') === 'false'){
                    rootChecker = 0
                }
            })
            if(rootChecker === 0){
                await new Promise(resolve => setTimeout(resolve, 100))
                saveOrder()
                return false
            }
            await new Promise(resolve => setTimeout(resolve, 300))

            // save what price we have at start
            let sum001 = false
            let sum002 = false
            let sum003 = false
            if(isPrices == 1){
                sum001 = true
            }else if(isPrices == 2){
                sum002 = true
            }else if(isPrices == 3){
                sum003 = true
            }

            // skip price what we have ready
            if(sum001){
                Q_('.page-order-params-wrp .param-field[name="zakaz_price_zacupo"]').value = sumTotal
            }else if(sum002){
                Q_('.page-order-params-wrp .param-field[name="zakaz_price_partner"]').value = sumTotal
            }else if(sum003){
                Q_('.page-order-params-wrp .param-field[name="zakaz_price_roznica"]').value = sumTotal
            }

            // change prices
            if(!sum001){
                Q_('.page-order-result-summ-in').setAttribute('data-price0', '-')
                setIsPrices(1)
                await collectOnePriceSumm('zakaz_price_zacupo')
            }
            if(!sum002){
                Q_('.page-order-result-summ-in').setAttribute('data-price0', '-')
                setIsPrices(2)
                await collectOnePriceSumm('zakaz_price_partner')
            }
            if(!sum003){
                Q_('.page-order-result-summ-in').setAttribute('data-price0', '-')
                setIsPrices(3)
                await collectOnePriceSumm('zakaz_price_roznica')
            }

            // return price what we had at start
            if(sum001){
                setIsPrices(1)
            }else if(sum002){
                setIsPrices(2)
            }else if(sum003){
                setIsPrices(3)
            }
            await new Promise(resolve => setTimeout(resolve, 100))
        }

        // collect params for saving
        let tmpArrForSend = []
        Qs_('.page-order-params-wrp .param-field').forEach((el) => {
            let tmpObj = {
                'name': el.getAttribute('name'),
                'value': el.value,
            }
            tmpArrForSend.push(tmpObj)
        })
        let tmpObj = {
            'name': 'post_content',
            'value': JSON.stringify(isContent),
        }
        tmpArrForSend.push(tmpObj)

        // send data to save
        if(order.parent_root){
            let response1 = await Loadit.saveSubOrder(order.id, tmpArrForSend)
        }else{
            let response1 = await Loadit.saveOrder(order.id, tmpArrForSend, Lg_('auth-id'))

            if(order.id === 'new'){
                Q_('.popup-wait').style.display = 'none';
                navigate(`/orders/${response1}`);
            }
        }

        // reload this order, list of orders and pa-orders
        reOrderAndPaOrders()

        // hide waiting popup
        setChanges(false)
        setSaving(false)
        Q_('.popup-wait').style.display = 'none';
    }

    // reload orders and pa-orders
    async function reOrderAndPaOrders() {
        // reload this order and suborders
        if(order.id !== 'new'){
            setSuborders([])
            let resp0
            if(order.parent_root){
                resp0 = await Loadit.getSubOrder(order.id)
            }else{
                resp0 = await Loadit.getOneOrder(order.id)
            }
            setOrder(resp0.data[0])
            setSuborders(resp0.suborders)
            setIsContent(eval(resp0.data[0].content))
        }

        // reload list of orders
        let resp1 = await Loadit.getOrders('two', 100, '', filterCity, isHideOldChecked, filterClient, filterPartner, filterWorker)
        setOrders(resp1)
        setOrdersReload(!ordersReload)

        // reload list of pa-orders
        let resp2 = await Loadit.getPaOrders(filterPaType, 999, '', filterPaCity, filterPaStep, filterPaClient, filterPaPartner, filterPaWorker)
        setPaOrders(resp2)
    }

    // Create SubOrder
    async function createSubOrder(papaId, doClone, sbType){
        // save curent condition if it needed
        if(papaId && papaId == order.id || !papaId){
            if(changes || saving){
                Q_('.popup-wait').style.display = 'grid'
                await saveOrder()
            }
        }

        // create new suborder
        Q_('.popup-wait').style.display = 'grid'
        let respo1 = await Loadit.createSubOrder(Lg_('auth-id'), papaId ? papaId : order.id, order.parent_root ? order.parent_root : order.id, doClone, sbType)

        // reload this order, list of orders and pa-orders
        reOrderAndPaOrders()

        Q_('.popup-wait').style.display = 'none'
    }

    // Add nomenclature to order
    function addElement(inNom, inCat, inName, dataTarget, paCat) {
        setChanges(false)

        // collect new nom object
        let tmpCod = '_'+Math.floor(Math.random() * 9999999)
        let tmpObj = {}
        if(inCat === 'wrp'){
            tmpObj = {
                type: inCat,
                id: inCat,
                pos: inCat + tmpCod,
                unit: 1,
                name: inName,
                count: 1,
                in: [],
            }
        }
        if(inCat === 'uni'){
            tmpObj = {
                type: inCat,
                id: inCat,
                pos: inCat + tmpCod,
                unit: 1,
                name: inName,
                price: [0, 0, 0],
                count: 1,
                in: [],
            }
        }
        if(inCat !== 'wrp' && inCat !== 'uni'){
            tmpObj = {
                type: 'nom',
                id: inNom * 1,
                pos: 'nom' + tmpCod,
                count: 1,
                params: {},
                in: [],
            }
        }

        // set nomenclature to content
        let tmpOrder = []
        if(isContent.length > 0){
            tmpOrder = JSON.parse(JSON.stringify(isContent))
        }
        if(paCat === 'wrp'){
            let parentPos = dataTarget.closest('.element-inside').getAttribute('data-pos')
            let wrpIndex = isContent.findIndex(el => el.pos === parentPos)
            tmpOrder[wrpIndex].in.push(tmpObj)
        }else if(paCat === 'nom' || paCat === 'ins' || paCat === 'uni'){

            // wrp is root?
            let wrpRootPos = ''
            if(dataTarget.closest('.element-inside-wrp')){
                wrpRootPos = dataTarget.closest('.element-inside-wrp').getAttribute('data-pos')
            }

            // create rout of insided noms
            let insRout = pushRout(dataTarget)

            // way in order to element for saving
            let finalPos = insRout[insRout.length - 1].replace('__', '')
            let contentWay
            if(wrpRootPos){
                let tmpIndex1 = isContent.findIndex(el => el.pos === wrpRootPos)
                let tmpIndex2 = tmpOrder[tmpIndex1].in.findIndex(ell => ell.pos === finalPos)
                contentWay = tmpOrder[tmpIndex1].in[tmpIndex2]
            }else{
                contentWay = tmpOrder[isContent.findIndex(el => el.pos === finalPos)]
            }

            // add IN to element for saving
            if(insRout.length < 2){
                if(contentWay['in']){
                    contentWay['in'].push(tmpObj)
                }else{
                    contentWay['in'] = [tmpObj]
                }

            // add MOD to element for saving
            }else{
                if(!contentWay['mod']){
                    contentWay['mod'] = {}
                }
                let tmpWay = contentWay.mod
                insRout.reverse().forEach((el, z) => {
                    // not for first step
                    if(z !== 0){
                        if(!tmpWay[el]){
                            tmpWay[el] = {}
                        }
                        tmpWay = tmpWay[el]
                    }

                    // for last step
                    if(z === insRout.length - 1){
                        if(tmpWay['in']){
                            tmpWay['in'].push(tmpObj)
                        }else{
                            tmpWay['in'] = [tmpObj]
                        }
                    }
                })
            }

            setIsContent(tmpOrder)
        }else{
            tmpOrder.push(tmpObj)
        }
        setIsContent(tmpOrder)

        setTimeout(function () {
            setChanges(true)
        }, 50)
    }
    useEffect(()=>{
        console.log('----------------------CONTENT-OF-ORDER:')
        console.log(isContent)
    }, [isContent])

    // Save params from pop to nomenclature
    function saveNomenclature() {
        setChanges(false)
        let tmpContent = JSON.parse(JSON.stringify(isContent))
        let tmpObj = {...paramsVars}
        tmpObj['kol'] = Q_('#id-popup-nom-kolvo').value
        tmpObj['kolvo'] = nomPopParams.kolvo

        let tmWay

        // root is WRP?
        let paIsWrp = false
        if(nomPopParams.pindex || nomPopParams.pindex === 0 || nomPopParams.pindex === "0"){
            paIsWrp = true
        }

        if(nomPopParams.type === 'wrp'){
            tmpContent[nomPopParams.index].name = Q_('#id-popup-nom-name').value
            tmpContent[nomPopParams.index].unit = Q_('#id-popup-nom-unit').value
            tmpContent[nomPopParams.index].count = tmpObj['kol']

        }else

        if(nomPopParams.type === 'nom' || nomPopParams.type === 'uni'){
            // collect params for saving
            let tmpPrice = []
            if(Q_('#id-popup-nom-price1')){
                tmpPrice = [
                    Q_('#id-popup-nom-price1').value.replace(/,/, '.'),
                    Q_('#id-popup-nom-price2').value.replace(/,/, '.'),
                    Q_('#id-popup-nom-price3').value.replace(/,/, '.')
                ]
            }
            let tmpParams = {}
            if(Q_('.popup-nom-params .popup-nom-params-one')){
                Qs_('.popup-nom-params .popup-nom-params-one').forEach((elm) => {
                    tmpParams[elm.getAttribute('data-var')] = elm.value
                    tmpObj[elm.getAttribute('data-var')] = elm.value*1
                })
            }

            // create way for saving
            if(nomPopParams.rout){
                // POS of element for where we save data
                let tmSavePos = nomPopParams.rout[nomPopParams.rout.length - 1].replace('__','')

                // link "tmWay" with element in "tmpContent"
                if(paIsWrp){
                    tmWay = tmpContent[nomPopParams.pindex].in.filter(el => el.pos === tmSavePos)[0]
                }else{
                    tmWay = tmpContent.filter(el => el.pos === tmSavePos)[0]
                }

                // is MOD key for saving
                if(nomPopParams.rout.length > 2){
                    tmWay = tmWay.mod

                    let tmRout = JSON.parse(JSON.stringify(nomPopParams.rout))
                    tmRout.pop()
                    tmRout = tmRout.reverse()

                    tmRout.forEach((ro, i)=>{
                        if(i !== tmRout.length - 1){
                            tmWay = tmWay[ro]
                        }else{
                            tmWay = tmWay.in[nomPopParams.index]
                        }
                    })

                // is IN key for saving
                }else{
                    tmWay = tmWay.in[nomPopParams.index]
                }
            }else{
                // root is WRP ?
                if(paIsWrp){ // link "tmWay" with element in "tmpContent"
                    tmWay = tmpContent[nomPopParams.pindex].in[nomPopParams.index]
                }else{
                    tmWay = tmpContent[nomPopParams.index]
                }
            }

            // saving
            if(nomPopParams.type === 'nom'){
                tmWay.count = tmpObj['kol']
                tmWay.params = tmpParams
            }else if(nomPopParams.type === 'uni'){
                tmWay.name = Q_('#id-popup-nom-name').value
                tmWay.unit = Q_('#id-popup-nom-unit').value
                tmWay.count = tmpObj['kol']
                tmWay.price = tmpPrice
            }
        }

        setNomPopFinal(tmpObj)
        setIsContent(tmpContent)
        setTimeout(function () {
            setChanges(true)
        }, 50)
    }

    // edit price of nom
    function editNomPrice(inTarget, inType, inElm, inParams, inSum, inNewPrices) {
        Q_('.popup-newprice').style.display = 'grid'

        // is root WRP?
        let wrpPos = ''
        if(inTarget.closest('.element-inside-wrp')){
            wrpPos = inTarget.closest('.element-inside-wrp').getAttribute('data-pos')
        }

        // Do we have a new price?
        let newsumm = -1
        if(inType === 'nom'){
            if(inElm.fixcost && inElm.fixcost[0] >= 0){
                if(inElm.fixcost[1] === 'one'){
                    newsumm = inParams.kolvo * inElm.fixcost[0]
                }
            }
        }else{
            if(inNewPrices.fixcost){
                if(inNewPrices.fixcost[1] === 'one'){
                    newsumm = inParams.kolvo * inNewPrices.fixcost[0]
                }
            }
        }

        // create rout of insided noms
        let insRout = pushRout(inTarget)
        let tmpRout = JSON.parse(JSON.stringify(insRout))
        tmpRout.pop()

        setPricePopData({
            cena : inSum[0],
            summ : inSum[1],
            newsumm : newsumm,
            count : inParams.kolvo,
            self : insRout[insRout.length - 1],
            papa : wrpPos,
            mod : tmpRout,
        })
    }
    
    // Delete nomenclature from order
    function deleteElement(inPos){
        setChanges(false)
        let tmpContent = JSON.parse(JSON.stringify(isContent))

        // Two loop functions for searching and deleting in content of Order
        function searchInObj(inObj){
            let have = false
            Object.keys(inObj).forEach(key => {
                if(key === '__'+inPos){
                    have = true
                }
            })
            if(have){
                delete inObj['__'+inPos];
            }

            if(inObj.in){
                searchInArr(inObj.in)
            }
            Object.keys(inObj).forEach(key => {
                if(inObj[key] !== null && typeof inObj[key] === 'object'){
                    searchInObj(inObj[key])
                }
            })
        }
        function searchInArr(inArr){
            let tmpInd = ''
            inArr.forEach((arr, i)=>{
                if(arr.pos === inPos){
                    tmpInd = i
                    return
                }else{
                    if(arr.in){
                        searchInArr(arr.in)
                    }
                    if(arr.mod){
                        searchInObj(arr.mod)
                    }
                }
            })

            if(tmpInd || tmpInd === 0){
                inArr.splice(tmpInd, 1);
            }
        }
        searchInArr(tmpContent)

        setIsContent(tmpContent)
        setTimeout(function () {
            setChanges(true)
        }, 50)
    }

    // clone element
    function cloneElement(clonePos, inType) {
        setChanges(false)
        let tmpContent = JSON.parse(JSON.stringify(isContent))
        let changedPos = ''

        function searchPosInArr(inArr){
            let clonedObj = ''
            inArr.forEach((ob)=>{
                if(searchPos(ob)){
                    let answer = newPosElement(ob, 'nom', changedPos)
                    clonedObj = answer[0]
                    changedPos = answer[1]
                }
            })
            if(clonedObj){
                inArr.push(clonedObj)
            }
        }
        function searchPos(inObj){
            // check in IN
            if(inObj.in){
                searchPosInArr(inObj.in)
            }

            // check in KEYs
            Object.keys(inObj).forEach(key => {
                if(inObj[key] !== null && typeof inObj[key] === 'object'){
                    searchPos(inObj[key])
                }
            })

            // return bool result
            if(inObj.pos && inObj.type){
                if(inObj.pos === clonePos){
                    return true
                }else{
                    return false
                }
            }else if(inObj['__' + clonePos]){
                let answer = newPosElement(inObj['__' + clonePos], 'nom', changedPos)
                let clnObj = answer[0]
                changedPos = answer[1]
                if(clnObj){
                    let newKeyPos = changedPos.filter(el => el[0] === clonePos)[0]
                    inObj['__' + newKeyPos[1]] = clnObj
                }
                return false
            }else{
                return false
            }
        }

        // start circle
        searchPosInArr(tmpContent)

        // return tmpContent
        setIsContent(tmpContent)
        setTimeout(function () {
            setChanges(true)
        }, 50)
    }
    // Change all POS to new in NOM
    function newPosElement(inElm, inType, usedPos){
        let tmpContent = JSON.parse(JSON.stringify(inElm))
        let posArr = []
        if(usedPos){
            posArr = usedPos
        }

        function newPos(inObj){
            // change POS if we find it
            if(inObj.pos && inObj.type){
                if(!posArr.some(ts => ts[0] === inObj.pos)){
                    if(!posArr.some(ts => ts[1] === inObj.pos)){
                        let tmPos = inObj.type + '_' + Math.floor(Math.random() * 9999999)
                        posArr.push([inObj.pos, tmPos])
                        inObj.pos = tmPos
                    }
                }else{
                    inObj.pos = posArr[posArr.findIndex(ts => ts[0] === inObj.pos)][1]
                }
            }

            // check in IN
            if(inObj.in){
                inObj.in.forEach((ob)=>{
                    newPos(ob)
                })
            }

            // check in KEYs
            Object.keys(inObj).forEach(key => {
                if(inObj[key] !== null && typeof inObj[key] === 'object'){
                    newPos(inObj[key])
                }
            })
        }
        function newKey(inObj){
            // check in IN
            if(inObj.in){
                inObj.in.forEach((ob)=>{
                    newKey(ob)
                })
            }

            // check in KEYs
            Object.keys(inObj).forEach(key => {
                if(inObj[key] !== null && typeof inObj[key] === 'object'){
                    newKey(inObj[key])
                    if(posArr.some(ts => ts[0] === key.replace('__',''))){
                        let tmInd = posArr.findIndex(ts => ts[0] === key.replace('__',''))
                        let tmObj = JSON.parse(JSON.stringify(inObj[key]))
                        inObj['__' + posArr[tmInd][1]] = tmObj
                        delete inObj[key]
                    }
                }
            })
        }

        newPos(tmpContent)
        newKey(tmpContent)
        return [tmpContent, posArr]
    }

    // Echo breadcrumbs
    function echoBreads(num){
        let tmpSub = {}
        let tmpPr

        // find suborder and save his parent
        suborders.forEach((sub)=>{
            if(sub.id == num){
                tmpPr = sub.pr
            }
        })

        // find parent suborder
        if(tmpPr){
            suborders.forEach((sub)=>{
                if(sub.id == tmpPr && tmpPr != order.parent_root){
                    tmpSub = sub
                }
            })
        }


        if(tmpSub.id){
            return (
                <span className="bc-short">
                    {echoBreads(tmpSub.id)}
                    <span>/</span>
                    <Link className="bc-list" to={`/orders/p${tmpSub.id}`} onClick={() => {
                        loadThisOrder('p'+tmpSub.id)
                        setOrder({})
                        setSuborders([])
                        setIsContent([])

                        setCurClient('')
                        setCurIspo('')
                        setCurStep('')
                        setCurDtPlan('')

                        setSaving(false)
                        setChanges(false)
                    }}>
                        {isLandscape ? 'Подзаказ ' : ''}№{tmpSub.id}
                    </Link>
                </span>
            )
        }else{
            return
        }
    }

    // Collect rezult summ
    function collectSumm() {
        if(Q_('.page-order-result-summ-in')){
            Q_('.page-order-result-summ-in').innerText = '---'
            let checker = 1
            Qs_('.page-order-content-wrp .nom').forEach((nm) => {
                if(nm.getAttribute('data-cena') === 'false'){
                    checker = 0
                }
            })
            if(checker === 0){
                setTimeout(function () {
                    collectSumm()
                },100)
                return
            }

            let priceX = 0
            Qs_('.page-order-content-wrp > .element-root > .element-summ .element-summ-in').forEach((el) => {
                if(el.getAttribute('data-price0')){
                    if(el.getAttribute('data-price0') !== 'Infinity'){
                        priceX += el.getAttribute('data-price0') * 1
                    }
                }
            })
            Q_('.page-order-result-summ-in').innerText = parseFloat(priceX.toFixed(2)).toLocaleString('ru-RU')
            Q_('.page-order-result-summ-in').setAttribute('data-price0', priceX)
            setSumTotal(priceX)
            setSumReady(true)
        }else{
            setSumTotal(0)
            setSumReady(true)
            return
        }
    }
    useEffect(() => {
        setSumReady(false)
        setTimeout(function () {
            collectSumm()
        },300)
    },[changes, isPrices, isSysDat])

    // Change saved type of prices
    useEffect(()=>{
        let tmpEl = Q_('.page-order-params-wrp .param-field[name="prices_type"]')
        if(tmpEl){
            tmpEl.value = isPrices
        }
    },[isPrices])

    // Check FINAL params for popup
    useEffect(() => {
        let tmpObj = {...paramsVars}
        tmpObj['kol'] = nomPopParams.count
        tmpObj['kolvo'] = nomPopParams.kolvo
        if(nomPopParams.type === 'nom'){
            Qs_('.popup-nom-params .popup-nom-params-one').forEach((elm) => {
                tmpObj[elm.getAttribute('data-var')] = elm.value*1
            })
        }
        setNomPopFinal(tmpObj)

        // create listener of NOM popup size
        setTimeout(function () {
            if(Q_('.popup-nom .popup-wrp')){
                if(!popFullNom){
                    setPopFullNom(true)
                    NomResizeObserver.observe(Q_(".popup-nom .popup-wrp"))
                }
            }
        }, 500)
    },[nomPopParams, nomPopChange])
    // Set popup parameters logic
    useEffect(() => {
        if(nomPopParams.id){
            if(nomPopParams.type === 'nom'){
                let tmpObj = {...paramsVars}
                if(isSysDat.nomenclature[nomPopParams.id].paramslog){
                    Object.entries(isSysDat.nomenclature[nomPopParams.id].paramslog).forEach((lol) => {
                        tmpObj[lol[0]] = evaluate(lol[1], nomPopFinal)
                    })
                }
                setNomPopLogic(tmpObj)
            }
        }
    }, [nomPopFinal])

    // Listener of browser path changes - for deсision of problem when you go TO ORDER page FROM ORDER page also
    useEffect(() => {
        if(order){
            if(order.id){
                if(params.id != order.id){
                    loadThisOrder('')
                    setOrder({})
                    setSuborders([])
                    setIsContent([])
                    setSaving(false)
                    setChanges(false)
                }
            }
        }
    }, [params.id])
    
    // Open printer popup
    async function openPrinter() {
        if(!printPopKP.txt){
            Q_('.popup-wait').style.display = 'grid'

            let response = await Loadit.getKP_texts()
            setPrintPopKP(response)

            Q_('.popup-wait').style.display = 'none'
        }

        Q_('.popup-printer').style.display = 'grid'

        // create listener of popup size
        if(!popFullPrinter){
            setPopFullPrinter(true)
            setTimeout(function () {
                PrinterResizeObserver.observe(Q_(".popup-printer .popup-wrp"))
            },500)
        }
    }
    // KP constructor
    function kpLineConstructor(elem, inID, where) {
        let tmpEl = Q_(inID)
        let tmpLine = tmpEl.value.replace(/\s+/g, '')
        let tmpVal = elem.getAttribute('data-val')
        if(elem.matches('.active')){
            elem.classList.remove('active')
            tmpLine = tmpLine.split(tmpVal+',').join('')
            tmpLine = tmpLine.split(tmpVal).join('')
            tmpLine = tmpLine.split(',,,').join(',')
            tmpLine = tmpLine.split(',,').join(',')
            if(tmpLine[0] === ','){tmpLine = tmpLine.slice(1);}
            tmpEl.value = tmpLine
        }else{
            elem.classList.add('active')
            tmpLine += ','
            tmpLine = tmpLine.split(',,').join(',')
            if(tmpLine[0] === ','){tmpLine = tmpLine.slice(1);}
            tmpLine += tmpVal
            tmpEl.value = tmpLine
        }
        kpLineToState(tmpEl, where)
    }
    function kpLineToState(elem, where) {
        let tmpKP_pre = JSON.parse(JSON.stringify(printPopKP_pre))
        tmpKP_pre[where] = elem.value.split(',')
        setPrintPopKP_pre(tmpKP_pre)
    }
    async function kpTxtEdit(str) {
        let tmpSize = Q_('.popup-kp-text-wrp [name="kp_size"]').value
        let tmpArr = [
            Q_('.popup-kp-text-wrp [name="title"]').value,
            Q_('.popup-kp-text-wrp [name="kp_txt"]').value,
            tmpSize ? tmpSize : 10,
            Q_('.popup-kp-text-wrp [name="kp_bold"]').value,
            Q_('.popup-kp-text-wrp [name="kp_col"]').value,
            Q_('.popup-kp-text-wrp [name="kp_align"]').value,
        ]
        let respo = await Loadit.saveKP_text(str, tmpArr)

        let tmpKP = JSON.parse(JSON.stringify(printPopKP))
        tmpKP.txt[respo] = tmpArr
        setPrintPopKP(tmpKP)

        Q_('.popup-wait').style.display = 'none'
        if(printPopKP_txt[0] === 'new'){
            openMessage('Готово!', 'Новый текстовый блок для КП создан')
        }
        setPrintPopKP_txt(['',['','','','','','','','','','','']])
    }
    async function kpPicEdit(str) {
        const tmpArrForSend = new FormData()
        let tmp_title = Q_('.popup-kp-pic-wrp [name="title"]').value
        let tmp_kp_width = Q_('.popup-kp-pic-wrp [name="kp_width"]').value
        let tmp_kp_height = Q_('.popup-kp-pic-wrp [name="kp_height"]').value
        let tmp_kp_wide = Q_('.popup-kp-pic-wrp [name="kp_wide"]').value
        let tmp_kp_pic = Q_('.popup-kp-pic-wrp [name="kp_pic"]')
        let tmp_kp_pic_src = Q_('#kp_pic_edit').src

        tmpArrForSend.append('title', tmp_title)
        tmpArrForSend.append('kp_width', tmp_kp_width)
        tmpArrForSend.append('kp_height', tmp_kp_height)
        tmpArrForSend.append('kp_wide', tmp_kp_wide)
        if(tmp_kp_pic.files){
            tmpArrForSend.append('kp_pic', tmp_kp_pic.files[0])
        }

        let respo = await Loadit.saveKP_pic(str, tmpArrForSend)

        let tmpArr = [
            tmp_title,
            tmp_kp_pic_src,
            tmp_kp_pic_src,
            tmp_kp_width,
            tmp_kp_height,
            tmp_kp_wide,
        ]
        let tmpKP = JSON.parse(JSON.stringify(printPopKP))
        tmpKP.pic[respo] = tmpArr
        setPrintPopKP(tmpKP)

        Q_('.popup-wait').style.display = 'none'

        if(printPopKP_pic[0] === 'new'){
            openMessage('Готово!', 'Новое изображение для КП создано')
        }
        setPrintPopKP_pic(['',['','','','','','','','','','','']])
    }
    async function kpSetSave() {
        Q_('.popup-printer-kp-save').style.display = 'none'

        const tmpArrForSend = new FormData()
        let tmp_title = Q_('#kp_set_name').value
        let tmp_kp_before = Q_('#print-data9-1').value
        let tmp_kp_after = Q_('#print-data10-1').value
        let tmp_kp_nds = 0
        if(Q_('#nds-need').checked){
            tmp_kp_nds = 1
        }
        let tmp_kp_light = 0
        if(Q_('#light-need').checked){
            tmp_kp_light = 1
        }
        let tmp_kp_params = 0
        if(Q_('#params-need').checked){
            tmp_kp_params = 1
        }

        tmpArrForSend.append('title', tmp_title)
        tmpArrForSend.append('kp_before', tmp_kp_before)
        tmpArrForSend.append('kp_after', tmp_kp_after)
        tmpArrForSend.append('kp_nds', tmp_kp_nds)
        tmpArrForSend.append('kp_light', tmp_kp_light)
        tmpArrForSend.append('kp_params', tmp_kp_params)

        let respo = await Loadit.saveKP_set(tmpArrForSend)

        let tmpArr = [
            tmp_title,
            tmp_kp_before,
            tmp_kp_after,
            tmp_kp_nds,
            tmp_kp_light,
            tmp_kp_params,
        ]
        let tmpKP = JSON.parse(JSON.stringify(printPopKP))
        tmpKP.set[respo] = tmpArr
        setPrintPopKP(tmpKP)

        Q_('.popup-wait').style.display = 'none'
        openMessage('Готово!', 'Новый набор КП создан. Он отобразится в списке наборов, который можно увидеть нажав на шестерёнку...')
    }
    function deleteKPpic(str) {
        Q_('.popup-sure').style.display = 'grid'
        Q_('.popup-sure .popup-sure-btns-delete').dataset.type = 'kp_pics';
        Q_('.popup-sure .popup-sure-btns-delete').dataset.num = str;
    }
    function deleteKPtxt(str) {
        Q_('.popup-sure').style.display = 'grid'
        Q_('.popup-sure .popup-sure-btns-delete').dataset.type = 'kp_texts';
        Q_('.popup-sure .popup-sure-btns-delete').dataset.num = str;
    }
    function deleteKPset(str) {
        Q_('.popup-sure').style.display = 'grid'
        Q_('.popup-sure .popup-sure-btns-delete').dataset.type = 'kp_sets';
        Q_('.popup-sure .popup-sure-btns-delete').dataset.num = str;
    }
    useEffect(()=>{
        if(popKP_picDel){
            let tmpKP = JSON.parse(JSON.stringify(printPopKP))
            delete tmpKP.pic[popKP_picDel]
            setPrintPopKP(tmpKP)
            setPopKP_picDel('')
        }
    },[popKP_picDel])
    useEffect(()=>{
        if(popKP_txtDel){
            let tmpKP = JSON.parse(JSON.stringify(printPopKP))
            delete tmpKP.txt[popKP_txtDel]
            setPrintPopKP(tmpKP)
            setPopKP_txtDel('')
        }
    },[popKP_txtDel])
    useEffect(()=>{
        if(popKP_setDel){
            let tmpKP = JSON.parse(JSON.stringify(printPopKP))
            delete tmpKP.set[popKP_setDel]
            setPrintPopKP(tmpKP)
            setPopKP_setDel('')
            setPrintPopKP_set('1')
        }
    },[popKP_setDel])
    // Get Company by user ID
    function getCompanyByUserID(comId){
        if(comId){
            let resultCom = isSysDat.companies[comId]
            return resultCom
        }else{
            let tmpUser = isSysDat.users.find(el => el.value == Lg_('auth-id'))
            let resultCom = isSysDat.companies[tmpUser.com]
            return resultCom
        }
    }
    // Create PDF
    const blobToBase64 = blob => {
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        return new Promise(resolve => {
            reader.onloadend = () => {
                resolve(reader.result)
            }
        })
    } // pics blober
    function nomTableForDocs(inParams){
        let isNoms = []
        let nmPo = 0
        Qs_('.page-order-content-wrp .element-root').forEach((nm) => {
            nmPo++

            // find element in JSON content of order
            let tmpTxt = {
                text: [
                    {text: nm.querySelector('.element-name-span').innerText, bold: true,},
                ],
            }
            let tmpEl = isContent.find(el => el.pos === nm.getAttribute('data-pos'))

            // collect params
            let isTmpKolvo = nm.getAttribute('data-kolvo')*1
            if(inParams){
                if(nm.classList.contains('element-nom')){
                    let returnedParams = {...paramsVars}
                    returnedParams['kol'] = nm.getAttribute('data-kol')*1
                    returnedParams['kolvo'] = isTmpKolvo
                    returnedParams['allnom'] = 0
                    let tmpElID = nm.getAttribute('data-id')

                    // in begin we upload params from standard DB nomenclature
                    let tmpElParams = Object.entries(isSysDat.nomenclature[tmpElID].params)
                    tmpElParams.forEach((pa) => {
                        if(pa[1].type === 'dig'){
                            returnedParams[pa[0]] = 0
                            let tmpDigLine = pa[1].data.split('__')
                            tmpDigLine.forEach((di) => {
                                let diName = di.split(':')
                                if(diName[0].replace(/\s+/g, '') === 'val'){
                                    returnedParams[pa[0]] = diName[1].replace(/\s+/g, '')*1
                                }
                            })
                        }else{
                            returnedParams[pa[0]] = 1
                        }
                    })

                    // and check params what saved in order for this nomenclature
                    if(Object.keys(tmpEl.params).length){
                        Object.entries(tmpEl.params).forEach((pms)=>{
                            returnedParams[pms[0]] = pms[1]*1
                        })
                    }

                    // logic of params
                    let tmpObj = {...paramsVars}
                    if(isSysDat.nomenclature[tmpElID].paramslog){
                        Object.entries(isSysDat.nomenclature[tmpElID].paramslog).forEach((lol) => {
                            tmpObj[lol[0]] = evaluate(lol[1], returnedParams)
                        })
                    }

                    // print params as list
                    Object.entries(tmpObj).forEach((pam) => {
                        if(pam[1] != 0){
                            if(isSysDat.nomenclature[tmpElID].params[pam[0]]){
                                let tmpType = isSysDat.nomenclature[tmpElID].params[pam[0]].type
                                if(tmpType === 'dig'){
                                    tmpTxt.text.push({
                                        text: '\n - '+isSysDat.nomenclature[tmpElID].params[pam[0]].name + ': ' + returnedParams[pam[0]],
                                        style: 'greyText',
                                    })
                                }else if(tmpType === 'sel' || tmpType === 'per'){
                                    let tmpList = []
                                    if(tmpType === 'sel'){
                                        tmpList = isSysDat.nomenclature[tmpElID].params[pam[0]].data.split("__")
                                    }else{
                                        tmpList = isSysDat.variables[isSysDat.nomenclature[tmpElID].params[pam[0]].data.replace(/\s+/g, '')].split("__")
                                    }
                                    tmpTxt.text.push({
                                        text: '\n - '+isSysDat.nomenclature[tmpElID].params[pam[0]].name + ': ' + tmpList[returnedParams[pam[0]] - 1],
                                        style: 'greyText',
                                    })
                                }
                            }
                        }
                    })
                }
            }

            let isTmpSumm = parseFloat((nm.querySelector('.element-summ-in').getAttribute('data-price0')*1).toFixed(2))
            let isTmpPrice = parseFloat((isTmpSumm / isTmpKolvo).toFixed(2))
            let tmpArr = [
                {text: nmPo, bold: true,},
                tmpTxt,
                {text: isTmpKolvo,},
                {text: nm.querySelector('.element-count').getAttribute('data-pcs'),},
                {text: isTmpPrice.toLocaleString('ru-RU'),},
                {text: isTmpSumm.toLocaleString('ru-RU'),},
            ]
            isNoms.push(tmpArr)
        })
        return isNoms
    }
    function nomItogForDocs(nds, isTotal){
        let ndsLine = [
            [
                {text: 'Итого:', colSpan: 5, alignment: 'right', bold: true,},
                '',
                '',
                '',
                '',
                {text: parseFloat((isTotal * 1).toFixed(2)).toLocaleString('ru-RU'), bold: true,},
            ]
        ]
        if(nds){
            ndsLine = [
                [
                    {text: 'Итого:', colSpan: 5, alignment: 'right', bold: true,},
                    '',
                    '',
                    '',
                    '',
                    {text: parseFloat((isTotal / 1.2).toFixed(2)).toLocaleString('ru-RU'), bold: true,},
                ],
                [
                    {text: 'НДС:', colSpan: 5, alignment: 'right', bold: true,},
                    '',
                    '',
                    '',
                    '',
                    {text: parseFloat((isTotal / 6).toFixed(2)).toLocaleString('ru-RU'), bold: true,},
                ],
                [
                    {text: 'Итого с НДС:', colSpan: 5, alignment: 'right', bold: true,},
                    '',
                    '',
                    '',
                    '',
                    {text: parseFloat((isTotal * 1).toFixed(2)).toLocaleString('ru-RU'), bold: true,},
                ],
            ]
        }
        return ndsLine
    }
    async function PDF_bill(comId, nds, sign, lico){
        Q_('.popup-wait').style.display = 'grid'

        // check summ for ready
        let checker = 1
        Qs_('.page-order-content-wrp .nom').forEach((nm) => {
            if(nm.getAttribute('data-cena') === 'false'){
                checker = 0
            }
        })
        if(checker === 0){
            setTimeout(function () {
                PDF_bill(comId, nds, sign, lico)
            },1000)
            return
        }

        // company and other data
        let isCom = getCompanyByUserID(comId)
        let isTotal = sumTotal;

        // noms table lines
        let isNoms = nomTableForDocs(true)

        // NDS is need?
        let ndsLine = nomItogForDocs(nds, isTotal)

        // witch lico we use
        // let licoId = Q_('#print-client-id').value
        let licoId = Q_('.page-order-params-wrp .param select[name="client"]').value
        let licoObj = isSysDat.companies[licoId]
        let isLico = {text: licoObj.nm, border: [false, false, false, false],}
        if(lico === 'fiz' && licoObj.fiz[0]){
            let tmpPasN = ''
            if(licoObj.fiz[2]){tmpPasN = ', паспорт: ' + licoObj.fiz[2] + '-' + licoObj.fiz[3]}

            let tmpPasKem = ''
            if(licoObj.fiz[4]){tmpPasKem = ', выдан: ' + licoObj.fiz[4]}

            let tmpPasDate = ''
            if(licoObj.fiz[6]){tmpPasDate = ' ' + licoObj.fiz[6]}

            let tmpPasKod = ''
            if(licoObj.fiz[5]){tmpPasKod = ', подразделением: ' + licoObj.fiz[5]}

            let tmpPasDR = ''
            if(licoObj.fiz[7]){tmpPasDR = '. Дата рождения: ' + licoObj.fiz[7]}

            isLico = {
                text: licoObj.fiz[1] + tmpPasN + tmpPasKem + tmpPasDate + tmpPasKod + tmpPasDR,
                border: [false, false, false, false],
            }
        }else if(lico === 'ur' && licoObj.ur[0]){
            isLico = {
                text: licoObj.ur[1] + ' "'+licoObj.ur[2]+'", ИНН '+licoObj.ur[4]+', КПП '+licoObj.ur[5]+', '+licoObj.ur[3]+', тел.: '+licoObj.osn[3],
                border: [false, false, false, false],
            }
        }

        // Get signatures and stamp
        let logoPrint = {
            text: 'Заборы и Ворота',
            rowSpan: 5,
            border: [false, false, false, false],
            width: 80,
            margin: [0, 20, 20, 0],
            fontSize: 15,
            bold: true,
        }
        let PicSignManager = {
            text: '',
            border: [false, true, false, true],
            margin: [0, 20, 0, 0],
        }
        let PicSignDirector = {
            text: '',
            border: [false, false, false, true],
        }
        let PicSignBuhgalter = {
            text: '',
            border: [false, false, false, true],
        }
        let PicStamp = [
            {text: '', border: [false, false, false, false],},
            {text: '', border: [false, false, false, false],},
            {text: 'МП', style: 'simpleText', alignment: 'right', margin: [0, 20, 0, 0], border: [false, false, false, false],},
            {text: '', border: [false, false, false, false],},
            {text: '', border: [false, false, false, false],},
            {text: '', border: [false, false, false, false],},
        ]
        let marginDirector = 0
        let marginBuhgalter = 0
        if(Lg_('auth-signature') && Lg_('auth-signature') !== 'null' && sign){
            await fetch(Lg_('auth-signature'))
                .then(res => res.blob())
                .then(blobToBase64)
                .then(finalResult => {
                    PicSignManager = {
                        image: finalResult,
                        fit: [100, 50],
                        border: [false, true, false, true],
                        alignment: 'center',
                        margin: [0, 0, 0, -10],
                    }
                })
        }
        if(isCom.ur[13] && sign){
            await fetch(isCom.ur[13])
                .then(res => res.blob())
                .then(blobToBase64)
                .then(finalResult => {
                    PicSignDirector = {
                        image: finalResult,
                        fit: [100, 50],
                        border: [false, false, false, true],
                        alignment: 'center',
                        margin: [0, 0, 0, -10],
                    }
                    marginDirector = 20
                })
        }
        if(isCom.ur[14] && sign){
            await fetch(isCom.ur[14])
                .then(res => res.blob())
                .then(blobToBase64)
                .then(finalResult => {
                    PicSignBuhgalter = {
                        image: finalResult,
                        fit: [100, 50],
                        border: [false, false, false, true],
                        alignment: 'center',
                        margin: [0, 0, 0, -10],
                    }
                    marginBuhgalter = 20
                })
        }
        if(isCom.ur[15] && sign){
            await fetch(isCom.ur[15])
                .then(res => res.blob())
                .then(blobToBase64)
                .then(finalResult => {
                    PicStamp = [
                        {text: '', border: [false, false, false, false],},
                        {text: '', border: [false, false, false, false],},
                        {image: finalResult, fit: [100, 100], alignment: 'right', margin: [0, 0, -20, 0], border: [false, false, false, false],},
                        {text: '', border: [false, false, false, false],},
                        {text: 'МП', style: 'simpleText', margin: [-40, 20, 0, 0], border: [false, false, false, false],},
                        {text: '', border: [false, false, false, false],},
                    ]
                })
        }
        if(isCom.ur[16]){
            await fetch(isCom.ur[16])
                .then(res => res.blob())
                .then(blobToBase64)
                .then(finalResult => {
                    logoPrint = {
                        image: finalResult,
                        rowSpan: 5,
                        border: [false, false, false, false],
                        width: 80,
                        alignment: 'center',
                        margin: [16, 28, 10, 0],
                    }
                })
        }

        // Generate QR
        let QRbox = {text: '', rowSpan: 5,}
        if(isCom.ur[10]){
            QRbox = {qr: isCom.ur[10], rowSpan: 5, fit: 100,}
        }

        let tmpPDF = {
            content: [
                {
                    style: 'tableHead',
                    margin: [0, 0, 0, 20],
                    table: {
                        widths: ['auto', '*', 'auto', '*', 'auto'],
                        body: [
                            [
                                logoPrint,
                                {
                                    text: [
                                        isCom.ur[6] + '\n',
                                        {text: 'банк получателя', style: 'greyText',},
                                    ],
                                    rowSpan: 2,
                                },
                                {text: 'БИК:', border: [true, true, false, true], noWrap: true,},
                                {text: isCom.ur[7], border: [false, true, true, true],},
                                QRbox,
                            ],
                            [
                                '',
                                '',
                                {text: 'К/С:', border: [true, true, false, true], noWrap: true,},
                                {text: isCom.ur[8], border: [false, true, true, true],},
                                '',
                            ],
                            [
                                '',
                                {
                                    text: [
                                        isCom.ur[1] + ' "'+isCom.ur[2]+'"\n',
                                        {text: 'получатель', style: 'greyText',},
                                    ],
                                    rowSpan: 3,
                                },
                                {text: 'ИНН:', border: [true, true, false, true], noWrap: true,},
                                {text: isCom.ur[4], border: [false, true, true, true],},
                                '',
                            ],
                            [
                                '',
                                '',
                                {text: 'КПП:', border: [true, true, false, true], noWrap: true,},
                                {text: isCom.ur[5], border: [false, true, true, true],},
                                ''
                            ],
                            [
                                '',
                                '',
                                {text: 'Р/С:', border: [true, true, false, true], noWrap: true,},
                                {text: isCom.ur[9], border: [false, true, true, true],},
                                '',
                            ],
                        ],
                    },
                },
                {
                    style: 'tableHead',
                    margin: [0, 0, 0, 20],
                    table: {
                        widths: ['auto', '*'],
                        body: [
                            [
                                {
                                    text: 'Счет на оплату № '+Q_('#print-data3').value+' от '+Q_('#print-data4').value+' г.',
                                    colSpan: 2,
                                    border: [false, false, false, true],
                                    style: 'title',},
                                '',
                            ],
                            [
                                {
                                    text: [
                                        'Поставщик:\n',
                                        {text: 'исполнитель', style: 'greyText',},
                                    ],
                                    border: [false, false, false, false],
                                    margin: [0, 6, 0, 0],
                                },
                                {
                                    text: isCom.ur[1] + ' "'+isCom.ur[2]+'", ИНН '+isCom.ur[4]+', КПП '+isCom.ur[5]+', '+isCom.ur[3]+', тел.: '+isCom.osn[3],
                                    border: [false, false, false, false],
                                    margin: [0, 6, 0, 0],
                                },
                            ],
                            [
                                {
                                    text: [
                                        'Покупатель:\n',
                                        {text: 'заказчик', style: 'greyText',},
                                    ],
                                    border: [false, false, false, false],
                                },
                                isLico,
                            ],
                            [
                                {text: 'Основание:', border: [false, false, false, false],},
                                {text: 'Договор с покупателем', border: [false, false, false, false],},
                            ],
                        ],
                    },
                },
                {
                    style: 'tableHead',
                    margin: [0, 0, 0, 20],
                    table: {
                        headerRows: 1,
                        widths: ['auto', '*', 'auto', 'auto', 'auto', 'auto'],
                        body: [
                            [
                                {text: '№', bold: true, fillColor: '#eee',},
                                {text: 'Товар / Услуга', bold: true, fillColor: '#eee',},
                                {text: 'Кол-во', bold: true, fillColor: '#eee',},
                                {text: 'Ед.', bold: true, fillColor: '#eee',},
                                {text: 'Цена', bold: true, fillColor: '#eee',},
                                {text: 'Сумма', bold: true, fillColor: '#eee',},
                            ],
                            ...isNoms,
                            ...ndsLine,
                        ],
                    },
                },  // table with nomenclature
                {
                    text: 'Оплата данного счета означает согласие с условиями поставки товара.\n' +
                        'Уведомление об оплате обязательно, в противном случае не гарантируется наличие товара на складе.\n' +
                        'Товар отпускается по факту прихода денег на р/с Поставщика, самовывозом, при наличии доверенности и паспорта.',
                    style: 'simpleText',
                    color: '#777',
                    margin: [0, 0, 0, 10],
                },
                {
                    style: 'tableHead',
                    table: {
                        widths: ['auto', 10, 100, 10, '*', 10],
                        body: [
                            [
                                {text: 'Менеджер:', border: [false, true, false, false], margin: [0, 20, 0, 0], bold: true,},
                                {text: '', border: [false, true, false, false], margin: [0, 20, 0, 0],},
                                PicSignManager,
                                {text: '', border: [false, true, false, false], margin: [0, 20, 0, 0],},
                                {text: Lg_('auth-surname') + ' ' + Lg_('auth-name'), border: [false, true, false, true], margin: [0, 20, 0, 0],},
                                {text: '', border: [false, true, false, false], margin: [0, 20, 0, 0],},
                            ],
                            [
                                {text: '', border: [false, false, false, false],},
                                {text: '', border: [false, false, false, false],},
                                {text: 'подпись', border: [false, false, false, false], style: 'greyText', alignment: 'center',},
                                {text: '', border: [false, false, false, false],},
                                {text: 'расшифровка подписи', border: [false, false, false, false], style: 'greyText', alignment: 'center',},
                                {text: '', border: [false, false, false, false],},
                            ],
                            [
                                {text: 'Руководитель:', border: [false, false, false, false], bold: true, margin: [0, marginDirector, 0, 0],},
                                {text: '', border: [false, false, false, false],},
                                PicSignDirector,
                                {text: '', border: [false, false, false, false],},
                                {text: isCom.ur[11], border: [false, false, false, true], margin: [0, marginDirector, 0, 0],},
                                {text: '', border: [false, false, false, false],},
                            ],
                            [
                                {text: '', border: [false, false, false, false],},
                                {text: '', border: [false, false, false, false],},
                                {text: 'подпись', border: [false, false, false, false], style: 'greyText', alignment: 'center',},
                                {text: '', border: [false, false, false, false],},
                                {text: 'расшифровка подписи', border: [false, false, false, false], style: 'greyText', alignment: 'center',},
                                {text: '', border: [false, false, false, false],},
                            ],
                            [
                                {text: 'Бухгалтер:', border: [false, false, false, false], bold: true, margin: [0, marginBuhgalter, 0, 0],},
                                {text: '', border: [false, false, false, false],},
                                PicSignBuhgalter,
                                {text: '', border: [false, false, false, false],},
                                {text: isCom.ur[12], border: [false, false, false, true], margin: [0, marginBuhgalter, 0, 0],},
                                {text: '', border: [false, false, false, false],},
                            ],
                            [
                                {text: '', border: [false, false, false, false],},
                                {text: '', border: [false, false, false, false],},
                                {text: 'подпись', style: 'greyText', alignment: 'center', border: [false, false, false, false],},
                                {text: '', border: [false, false, false, false],},
                                {text: 'расшифровка подписи', border: [false, false, false, false], style: 'greyText', alignment: 'center',},
                                {text: '', border: [false, false, false, false],},
                            ],
                            PicStamp,
                        ],
                    },
                },
            ],
            styles: {
                tableHead: {
                    fontSize: 9,
                },
                title: {
                    fontSize: 15,
                    bold: true,
                    margin: [0, 0, 0, 5],
                },
                greyText: {
                    fontSize: 8,
                    color: '#777',
                },
                simpleText: {
                    fontSize: 9,
                    color: '#000',
                },
            },
        }

        // create
        pdfMake.createPdf(tmpPDF).open()
        Q_('.popup-wait').style.display = 'none'
    }
    async function PDF_dogovor_1(comId, nds, sign, lico){
        Q_('.popup-wait').style.display = 'grid'

        // check summ for ready
        let checker = 1
        Qs_('.page-order-content-wrp .nom').forEach((nm) => {
            if(nm.getAttribute('data-cena') === 'false'){
                checker = 0
            }
        })
        if(checker === 0){
            setTimeout(function () {
                PDF_dogovor_1(comId, nds, sign, lico)
            },1000)
            return
        }

        // company and other data
        let isCom = getCompanyByUserID(comId)
        let isTotal = sumTotal

        // noms table lines
        let isNoms = nomTableForDocs(true)

        // NDS is need?
        let ndsLine = nomItogForDocs(nds, isTotal)
        let isNDS = ''
        if(nds){
            isNDS = ' с учётом НДС'
        }

        // witch lico we use
        // let licoId = Q_('#print-client-id').value
        let licoId = Q_('.page-order-params-wrp .param select[name="client"]').value
        let licoObj = isSysDat.companies[licoId]
        let isLico = licoObj.nm
        let isLicoShort = licoObj.nm
        if(lico === 'fiz' && licoObj.fiz[0]){
            isLico = licoObj.fiz[1]
            if(licoObj.fiz[9]){
                isLicoShort = licoObj.fiz[9]
            }else{
                isLicoShort = isLico
            }
        }else if(lico === 'ur' && licoObj.ur[0]){
            isLico = licoObj.ur[11]
            if(licoObj.ur[18]){
                isLicoShort = licoObj.ur[18]
            }else{
                isLicoShort = isLico
            }
        }

        // logo
        let logoPrint = {
            text: 'Заборы и Ворота',
            border: [false, true, false, true],
            width: 80,
            margin: [0, 10, 20, 10],
            fontSize: 15,
            bold: true,
        }
        if(isCom.ur[16]){
            await fetch(isCom.ur[16])
                .then(res => res.blob())
                .then(blobToBase64)
                .then(finalResult => {
                    logoPrint = {
                        image: finalResult,
                        border: [false, true, false, true],
                        width: 80,
                        margin: [0, 25, 20, 10],
                    }
                })
        }
        // sign of client
        let PicSignСustomer = {text: '', border: [false, false, false, true],}
        let PicSignСustomer2 = {text: '', border: [false, false, false, true],}
        let PicSignСustomer3 = {text: '', border: [false, false, false, true],}
        if(licoObj.ur[13] && sign && lico === 'ur'){
            await fetch(licoObj.ur[13])
                .then(res => res.blob())
                .then(blobToBase64)
                .then(finalResult => {
                    PicSignСustomer = {
                        image: finalResult,
                        fit: [100, 60],
                        border: [false, false, false, true],
                        alignment: 'center',
                        margin: [0, -25, 0, -15],
                    }
                    PicSignСustomer2 = {
                        image: finalResult,
                        fit: [100, 60],
                        border: [false, false, false, true],
                        alignment: 'center',
                        margin: [0, -30, 0, -20],
                    }
                    PicSignСustomer3 = {
                        image: finalResult,
                        fit: [100, 60],
                        border: [false, false, false, true],
                        alignment: 'center',
                        margin: [0, -30, 0, -20],
                    }
                })
        }
        if(licoObj.fiz[8] && sign && lico === 'fiz'){
            await fetch(licoObj.fiz[8])
                .then(res => res.blob())
                .then(blobToBase64)
                .then(finalResult => {
                    PicSignСustomer = {
                        image: finalResult,
                        fit: [100, 60],
                        border: [false, false, false, true],
                        alignment: 'center',
                        margin: [0, -25, 0, -15],
                    }
                    PicSignСustomer2 = {
                        image: finalResult,
                        fit: [100, 60],
                        border: [false, false, false, true],
                        alignment: 'center',
                        margin: [0, -30, 0, -20],
                    }
                    PicSignСustomer3 = {
                        image: finalResult,
                        fit: [100, 60],
                        border: [false, false, false, true],
                        alignment: 'center',
                        margin: [0, -30, 0, -20],
                    }
                })
        }
        // sign of director
        let PicSignDirector = {text: '', border: [false, false, false, true],}
        let PicSignDirector2 = {text: '', border: [false, false, false, true],}
        let PicSignDirector3 = {text: '', border: [false, false, false, true],}
        if(isCom.ur[13] && sign){
            await fetch(isCom.ur[13])
                .then(res => res.blob())
                .then(blobToBase64)
                .then(finalResult => {
                    PicSignDirector = {
                        image: finalResult,
                        fit: [100, 60],
                        border: [false, false, false, true],
                        alignment: 'center',
                        margin: [0, -25, 0, -15],
                    }
                    PicSignDirector2 = {
                        image: finalResult,
                        fit: [100, 60],
                        border: [false, false, false, true],
                        alignment: 'center',
                        margin: [0, -30, 0, -20],
                    }
                    PicSignDirector3 = {
                        image: finalResult,
                        fit: [100, 60],
                        border: [false, false, false, true],
                        alignment: 'center',
                        margin: [0, -30, 0, -20],
                    }
                })
        }
        // stamp
        let PicStamp = {text: '', border: [false, false, false, false],}
        let PicStamp2 = {text: '', border: [false, false, false, false],}
        let PicClientStamp = {text: '', border: [false, false, false, false],}
        let PicClientStamp2 = {text: '', border: [false, false, false, false],}
        if(isCom.ur[15] && sign){
            await fetch(isCom.ur[15])
                .then(res => res.blob())
                .then(blobToBase64)
                .then(finalResult => {
                    PicStamp = {image: finalResult, fit: [100, 100], alignment: 'left', border: [false, false, false, false],}
                    PicStamp2 = {image: finalResult, fit: [100, 100], alignment: 'left', border: [false, false, false, false],}
                })
        }
        if(licoObj.ur[15] && sign && lico === 'ur'){
            await fetch(licoObj.ur[15])
                .then(res => res.blob())
                .then(blobToBase64)
                .then(finalResult => {
                    PicClientStamp = {image: finalResult, fit: [100, 100], alignment: 'left', border: [false, false, false, false],}
                    PicClientStamp2 = {image: finalResult, fit: [100, 100], alignment: 'left', border: [false, false, false, false],}
                })
        }

        // UR or FIZ text blocks
        let block1 = {
            text: isLico + ', паспорт серия '+licoObj.fiz[2]+' номер '+licoObj.fiz[3]+' выдан '+licoObj.fiz[4]+' '+licoObj.fiz[6]+', именуемый в дальнейшем «Заказчик» с одной стороны и '+isCom.ur[1]+' «'+isCom.ur[2]+'», именуемое в дальнейшем «Подрядчик», в лице Директора '+isCom.ur[11]+', действующего на основании Устава, с другой стороны, совместно именуемые «Стороны», а по отдельности – «Сторона» заключили настоящий Договор (далее – «Договор») о нижеследующем:',
            style: 'simpleText',
            margin: [0, 0, 0, 15],
        }
        if(lico === 'ur'){
            block1 = {
                text: licoObj.ur[1]+' «'+licoObj.ur[2]+'», в лице Директора '+licoObj.ur[11]+', действующего на основании Устава, именуемый в дальнейшем «Заказчик» с одной стороны и '+isCom.ur[1]+' «'+isCom.ur[2]+'», именуемое в дальнейшем «Подрядчик», в лице Директора '+isCom.ur[11]+', действующего на основании Устава, с другой стороны, совместно именуемые «Стороны», а по отдельности – «Сторона» заключили настоящий Договор (далее – «Договор») о нижеследующем:',
                style: 'simpleText',
                margin: [0, 0, 0, 15],
            }
        }
        let block2 = [
            {text: '2.1. ', bold: true,},
            'Согласование технических и количественных требований к Изделиям и выполнению Работ осуществляется Сторонами в следующем порядке:\n',
            {text: '2.1.1. ', bold: true,},
            'Заказчик предоставляет Подрядчику расчеты, в которых содержатся технические и количественные требования к результатам работ.\n',
            {text: '2.1.2. ', bold: true,},
            'В течение 1 (одного) рабочего дня с даты согласования расчетов Заказчик и Подрядчик подписывают Спецификацию, содержащую номер счета, дату и сумму по счету.\n'+
            'Срок изготовления Изделий составляет '+Q_('#print-data1').value+' рабочих дней с момента осуществления Заказчиком предоплаты в соответствии с п. 5.3. настоящего Договора. Срок доставки Изделий и выполнения Работ составляет по согласованию, но не позднее '+Q_('#print-data2').value+' дней с даты готовности Изделий, при условии готовности Объекта к выполнению Работ Подрядчиком. Под готовностью Объекта к выполнению работ Стороны понимают обеспечение Заказчиком беспрепятственного доступа Подрядчика к Объекту и непосредственному месту монтажа Изделий. При этом Заказчиком должны быть выполнены все предварительные (в том числе демонтажные) работы, которые Заказчик обязан выполнить своими силами, если иное не согласовано Сторонами дополнительно.\n',
            {text: '2.2. ', bold: true,},
            'При нарушении Заказчиком условий Договора, препятствующих своевременному исполнению обязательств Подрядчиком, Подрядчик имеет право в одностороннем порядке внести изменения в Договор в части сроков выполнения работ. Нарушением обязательств признается любое нарушение сроков оплаты, отсутствие надлежащего доступа на Объект в согласованные дату и время, неготовности Объекта для проведения замеров, доставки Изделий, выполнения Работ, а также при любых других обстоятельствах, требующих содействия Заказчика. Новый срок выполнения работ определяется в соответствии с производственной нагрузкой Подрядчика и доводится до сведения Заказчика путем направления Уведомления.\n',
            {text: '2.3. ', bold: true,},
            'В случае если работы по настоящему Договору были приостановлены согласно п.п. 3.1.4, 3.3.1, 3.3.5, 3.4.4 или 3.4.5, и Стороны не достигли соглашения по устранению обстоятельств, препятствующих продолжению работ в течение 3 (трех) рабочих дней с момента приостановки работ, Сторона, являющаяся инициатором приостановки работ вправе отказаться от исполнения Договора. При этом Заказчик обязан оплатить фактически выполненные Подрядчиком работы и возместить фактически понесенные Подрядчиком расходы не позднее 2 (двух) рабочих дней с момента отказа любой из Сторон от исполнения Договора. Фактически понесенные Подрядчиком расходы должны быть подтверждены документально.\n',
        ]
        if(lico === 'ur'){
            block2 = [
                {text: '2.1. ', bold: true,},
                'Согласование технических и количественных требований к Изделиям и выполнению Работ осуществляется Сторонами в следующем порядке:\n',
                {text: '2.1.1. ', bold: true,},
                'Заказчик предоставляет Подрядчику расчеты, в которых содержатся технические и количественные требования к результатам работ.\n',
                {text: '2.1.2. ', bold: true,},
                'В течение 2 (двух) рабочих дней с момента предоставления Подрядчик согласовывает представленные в соответствии с пунктом 2.1.1. техническое задание / расчеты путем направления Заказчику уведомления либо вносит в него изменения, которые в течение 2 (двух рабочих дней) согласовываются Заказчиком путем направления Подрядчику уведомления.\n',
                {text: '2.1.3. ', bold: true,},
                'В течение 1 (одного) рабочего дня с даты согласования технического задания / расчетов Заказчик и Подрядчик подписывают Спецификацию, содержащую наименование, количество и цену Изделий. Срок изготовления Изделий составляет '+Q_('#print-data1').value+' рабочих дней с момента осуществления Заказчиком предоплаты в соответствии с п. 5.3. настоящего Договора.\n',
                {text: '2.1.4. ', bold: true,},
                'Срок доставки Изделий и выполнения Работ составляет '+Q_('#print-data2').value+' рабочих дней с даты готовности Изделий, при условии готовности Объекта к выполнению Работ Подрядчиком. Под готовностью Объекта к выполнению работ Стороны понимают обеспечение Заказчиком беспрепятственного доступа Подрядчика к Объекту и непосредственному месту монтажа Изделий. При этом Заказчиком должны быть выполнены все предварительные (в том числе демонтажные) работы, которые Заказчик обязан выполнить своими силами, если иное не согласовано Сторонами дополнительно.\n',
                {text: '2.2. ', bold: true,},
                'При нарушении Заказчиком условий Договора, препятствующих своевременному исполнению обязательств Подрядчиком, Подрядчик имеет право в одностороннем порядке внести изменения в Договор в части сроков выполнения работ. Нарушением обязательств признается любое нарушение сроков оплаты, отсутствие надлежащего доступа на Объект в согласованные дату и время, неготовности Объекта для проведения замеров, доставки Изделий, выполнения Работ, а также при любых других обстоятельствах, требующих содействия Заказчика. Новый срок выполнения работ определяется в соответствии с производственной нагрузкой Подрядчика и доводится до сведения Заказчика путем направления Уведомления.\n',
                {text: '2.3. ', bold: true,},
                'В случае если работы по настоящему Договору были приостановлены согласно п.п. 3.1.4, 3.3.1, 3.3.5, 3.4.4 или 3.4.5, и Стороны не достигли соглашения по устранению обстоятельств, препятствующих продолжению работ в течение 3 (трех) рабочих дней с момента приостановки работ, Сторона, являющаяся инициатором приостановки работ вправе отказаться от исполнения Договора. При этом Заказчик обязан оплатить фактически выполненные Подрядчиком работы и возместить фактически понесенные Подрядчиком расходы не позднее 2 (двух) рабочих дней с момента отказа любой из Сторон от исполнения Договора. Фактически понесенные Подрядчиком расходы должны быть подтверждены документально.\n',
            ]
        }
        let block3 = 'Передать результат выполненных работ Заказчику посредством принятия монтажных работ у Мастера.\n'
        if(lico === 'ur'){
            block3 = 'Передать результат выполненных работ Заказчику по Расходной накладной.\n'
        }
        let block4 = 'В день окончания совместно с Мастером осмотреть и принять результат выполненных работ.\n'
        if(lico === 'ur'){
            block4 = 'В день окончания совместно с Подрядчиком осмотреть и принять результат выполненных работ по УПД (Универсальный передаточный документ).\n'
        }
        let block5 = 'После доставки Изделий на Объект Подрядчик передает их по УПД (Универсальный передаточный документ) Изделий. С момента передачи Изделий Заказчику последний несет риск гибели (утраты) / порчи Изделий. Повторное изготовление Изделий и/или устранение возникших недостатков в Изделиях после передачи их Заказчику осуществляется Подрядчиком только при условии дополнительной оплаты. Стоимость такой оплаты согласовывается Сторонами дополнительно.\n'
        if(lico === 'ur'){
            block5 = 'После доставки Изделий на Объект Подрядчик передает их по акту приема-передачи Изделий. С момента передачи Изделий Заказчику последний несет риск гибели (утраты)/порчи Изделий. Повторное изготовление Изделий и/или устранение возникших недостатков в Изделиях после передачи их Заказчику, осуществляется Подрядчиком только при условии дополнительной оплаты. Стоимость такой оплаты согласовывается Сторонами дополнительно.\n'
        }
        let block6 = 'Заказчик обязан подписать Акт выполненных работ в день окончания работ или направить Подрядчику мотивированный отказ от приемки Работ в письменном виде на адрес электронной почты Подрядчика.\n' +
            'Основание для мотивированного отказа от подписания Акт об оказанных услугах может быть следующее:\n' +
            '- несоответствие характеристик материалов, указанных в спецификации;\n' +
            '- несоответствие выполненных работ, указанных в спецификации.\n'
        if(lico === 'ur'){
            block6 = 'Сдача результатов работ Подрядчиком и приемка их Заказчиком оформляются Расходной накладной, подписанным Сторонами в день окончания работ по Договору. В случае немотивированного уклонения Заказчика от подписания Расходной накладной, работы считаются принятыми Заказчиком в полном объеме без замечаний по качеству и срокам. Расходная накладная в этом случае подписывается Подрядчиком в одностороннем порядке с проставлением отметки об отказе Заказчика от подписания, имеет юридическую силу и может использоваться в качестве доказательств в суде.\n'
        }
        let block7 = 'Сдача результатов работ Подрядчиком и приемка их Заказчиком оформляются Расходной накладной, подписанной Сторонами в день окончания работ по Договору. В случае немотивированного уклонения Заказчика от подписания Расходной накладной работы считаются принятыми Заказчиком в полном объеме без замечаний по качеству и срокам. Расходная накладная в этом случае подписывается Подрядчиком в одностороннем порядке с проставлением отметки об отказе Заказчика от подписания, имеет юридическую силу и может использоваться в качестве доказательств в суде.\n'
        if(lico === 'ur'){
            block7 = 'В соответствии с п. 2 ст. 720 ГК РФ Заказчик, обнаруживший недостатки в работе при ее приемке, вправе ссылаться на них в случаях, если в Акте либо ином документе, удостоверяющем приемку, были оговорены эти недостатки либо возможность последующего предъявления требования об их устранении.\n'
        }
        let block8_1 = {text: '\n'}
        let block8_2 = {text: '\n'}
        if(lico === 'ur'){
            block8_1 = {text: 'Директор '+licoObj.ur[1]+' «'+licoObj.ur[2]+'»\n', bold:true,}
            block8_2 = {text: 'Директор '+licoObj.ur[1]+' «'+licoObj.ur[2]+'»\n', bold:true,}
        }
        let block9_1 = [
            {text: 'Заказчик:\n', style: 'title2',},
            {text: isLico+'\n', bold:true,},
            {text: 'Паспорт: '+licoObj.fiz[2]+'-'+licoObj.fiz[3]+'\n',},
            {text: 'Выдан: '+licoObj.fiz[4]+' '+licoObj.fiz[6]+'\n',},
            {text: 'Тел.: '+licoObj.osn[3]+'\n',},
            {text: 'Email: '+licoObj.osn[4]+'\n',},
        ]
        let block9_2 = [
            {text: 'Заказчик:\n', style: 'title2',},
            {text: isLico+'\n', bold:true,},
            {text: 'Паспорт: '+licoObj.fiz[2]+'-'+licoObj.fiz[3]+'\n',},
            {text: 'Выдан: '+licoObj.fiz[4]+' '+licoObj.fiz[6]+'\n',},
            {text: 'Тел.: '+licoObj.osn[3]+'\n',},
            {text: 'Email: '+licoObj.osn[4]+'\n',},
        ]
        if(lico === 'ur'){
            block9_1 = [
                {text: 'Заказчик:\n', style: 'title2',},
                {text: licoObj.ur[1]+' «'+licoObj.ur[2]+'»\n', bold:true,},
                {text: 'ИНН: '+licoObj.ur[4]+'\nКПП: '+licoObj.ur[5]+'\nОГРН: '+licoObj.ur[17] + '\n',},
                {text: 'Адрес: '+licoObj.ur[3]+'\nТел.: '+licoObj.osn[3]+'\nEmail: '+licoObj.osn[4] + '\n',},
                {text: 'Банк: '+licoObj.ur[6]+'\nБИК: '+licoObj.ur[7]+'\nК/С: '+licoObj.ur[8]+'\nР/С: '+licoObj.ur[9] + '\n',},
            ]
            block9_2 = [
                {text: 'Заказчик:\n', style: 'title2',},
                {text: licoObj.ur[1]+' «'+licoObj.ur[2]+'»\n', bold:true,},
                {text: 'ИНН: '+licoObj.ur[4]+'\nКПП: '+licoObj.ur[5]+'\nОГРН: '+licoObj.ur[17] + '\n',},
                {text: 'Адрес: '+licoObj.ur[3]+'\nТел.: '+licoObj.osn[3]+'\nEmail: '+licoObj.osn[4] + '\n',},
                {text: 'Банк: '+licoObj.ur[6]+'\nБИК: '+licoObj.ur[7]+'\nК/С: '+licoObj.ur[8]+'\nР/С: '+licoObj.ur[9] + '\n',},
            ]
        }
        let block10 = {
            text: isLico + ', паспорт серия '+licoObj.fiz[2]+' номер '+licoObj.fiz[3]+' выдан '+licoObj.fiz[4]+' '+licoObj.fiz[6]+', именуемый в дальнейшем «Заказчик» с одной стороны и '+isCom.ur[1]+' «'+isCom.ur[2]+'», именуемое в дальнейшем «Подрядчик», в лице Директора '+isCom.ur[11]+', действующего на основании Устава, с другой стороны, совместно именуемые «Стороны», а по отдельности – «Сторона», утвердили счет на изготовление и монтаж следующих изделий:',
            style: 'simpleText',
            margin: [0, 0, 0, 15],
        }
        if(lico === 'ur'){
            block10 = {
                text: licoObj.ur[1]+' «'+licoObj.ur[2]+'», в лице Директора '+licoObj.ur[11]+', действующего на основании Устава, именуемый в дальнейшем «Заказчик» с одной стороны и '+isCom.ur[1]+' «'+isCom.ur[2]+'», именуемое в дальнейшем «Подрядчик», в лице Директора '+isCom.ur[11]+', действующего на основании Устава, с другой стороны, совместно именуемые «Стороны», а по отдельности – «Сторона», утвердили счет на изготовление и монтаж следующих изделий:',
                style: 'simpleText',
                margin: [0, 0, 0, 15],
            }
        }
        let block11 = [
            {text: '1 платёж ', bold: true,},
            'составляет '+Q_('#print-data5').value+' руб., производится Заказчиком в течение 3 (трех) банковских дней после подписания Сторонами Спецификации.\n\n',
            {text: '2 платёж ', bold: true,},
            'составляет '+Q_('#print-data6').value+' руб., производится Заказчиком в день принятия монтажных работ у Подрядчика.\n',
        ]
        if(printPop2Pay === '3'){
            block11 = [
                {text: '1 платёж ', bold: true,},
                'составляет '+Q_('#print-data5').value+' руб., производится Заказчиком в течение 3 (трех) банковских дней после подписания Сторонами Спецификации.\n\n',
                {text: '2 платёж ', bold: true,},
                'составляет '+Q_('#print-data6').value+' руб., производится Заказчиком за 1 (один) день до доставки Изделий на объект.\n\n',
                {text: '3 платёж ', bold: true,},
                'составляет '+Q_('#print-data7').value+' руб., производится Заказчиком в день принятия монтажных работ у Подрядчика.\n',
            ]
        }else if(printPop2Pay === '1'){
            block11 = [
                {text: 'Платёж составляет '+parseFloat((isTotal * 1).toFixed(2)).toLocaleString('ru-RU')+' руб.', bold: true,},
                ' и производится Заказчиком в течение 3 (трех) банковских дней после подписания Сторонами Спецификации.\n\n',
            ]
        }

        let tmpPDF = {
            content: [
                {
                    style: 'tableHead',
                    margin: [0, 0, 0, 20],
                    table: {
                        widths: ['auto', '*'],
                        body: [
                            [
                                logoPrint,
                                {
                                    text: [
                                        {text: isCom.ur[1] + ' "' + isCom.ur[2] + '"' + '\n', bold: true,},
                                        'Адрес: '+isCom.ur[3]+', Тел.: '+isCom.osn[3]+', Email: '+isCom.osn[4] + '\n',
                                        'ИНН: '+isCom.ur[4]+', КПП: '+isCom.ur[5]+', ОГРН: '+isCom.ur[17] + '\n',
                                        'Банк: '+isCom.ur[6]+', БИК: '+isCom.ur[7]+', К/С: '+isCom.ur[8]+', Р/С: '+isCom.ur[9] + '\n',
                                    ],
                                    border: [false, true, false, true],
                                    margin: [0, 10, 0, 10],
                                },
                            ],
                        ],
                    },
                },
                {
                    text: 'ДОГОВОР ПОДРЯДА № ' + Q_('#print-data3').value,
                    style: 'title',
                },
                {
                    text: 'на изготовление и установку металлоконструкций',
                    bold: true,
                    alignment: 'center',
                    fontSize: 9,
                    margin: [0, 0, 0, 10],
                },
                {
                    style: 'tableHead',
                    margin: [0, 0, 0, 10],
                    table: {
                        widths: ['auto', '*'],
                        body: [
                            [
                                {
                                    text: 'Город: ' + isSysDat.cities[Q_('.page-order-params-wrp .param-field[name="city"]').value],
                                    border: [false, false, false, false],
                                },
                                {
                                    text: 'Дата: ' + Q_('#print-data4').value + 'г.',
                                    border: [false, false, false, false],
                                    alignment: 'right',
                                },
                            ],
                        ],
                    },
                },
                block1,
                {
                    text: '1. ПРЕДМЕТ ДОГОВОРА',
                    style: 'title',
                    margin: [0, 0, 0, 15],
                },
                {
                    text: [
                        {text: '1.1. ', bold: true,},
                        'По настоящему Договору, Подрядчик обязуется изготовить металлоконструкции (далее - Изделия) указанные в Спецификации, являющейся неотъемлемой частью Договора (Приложение № 1), и осуществить установку Изделий (далее - Работы), а затем передать изделия и результаты работ Заказчику, а Заказчик обязуется принять Изделия и результат Работ и оплатить Изделия и выполненные Работы в порядке и на условиях, установленных настоящим Договором.\n',
                        {text: '1.2. ', bold: true,},
                        'Технические и количественные требования к результату работ по настоящему Договору содержатся в расчетах (в Приложении №1), представляемых Заказчиком Подрядчику.\n',
                        {text: '1.3. ', bold: true,},
                        'Доставка Изделий и Работы производятся по адресу: '+Q_('.page-order-params-wrp .param-field[name="address"]').value+'.\n',
                        {text: '1.4. ', bold: true,},
                        'Изделия и Работы изготовление и выполнение, которых является обязанностью Подрядчика по настоящему Договору, изготовляются и выполняются из его материалов, его силами и средствами.\n',
                        {text: '1.5. ', bold: true,},
                        'Право собственности на Изделия переходит к Заказчику после подписания Сторонами УПД (Универсальный передаточный документ) и полной оплаты Заказчиком за Изделия по Договору. Оплата Заказчиком за работы по установке металлоконструкций производится согласно Разделу 5 настоящего Договора.\n',
                    ],
                    style: 'simpleText',
                    margin: [0, 0, 0, 15],
                },
                {
                    text: '2. ПОРЯДОК СОГЛАСОВАНИЯ ЗАКАЗА, СРОКИ ВЫПОЛНЕНИЯ РАБОТ',
                    style: 'title',
                    margin: [0, 0, 0, 15],
                },
                {
                    text: block2,
                    style: 'simpleText',
                    margin: [0, 0, 0, 15],
                },
                {
                    text: '3. ПРАВА И ОБЯЗАННОСТИ СТОРОН',
                    style: 'title',
                    margin: [0, 0, 0, 15],
                },
                {
                    text: [
                        {text: '3.1. Заказчик вправе:\n', bold: true,},
                        {text: '3.1.1. ', bold: true,},
                        'Требовать от Подрядчика надлежащего исполнения его обязанностей, предусмотренных настоящим Договором.\n',
                        {text: '3.1.2. ', bold: true,},
                        'Проверять ход и качество работы, выполняемой Подрядчиком, не вмешиваясь при этом в его деятельность.\n',
                        {text: '3.1.3. ', bold: true,},
                        'Направлять письменные претензии в адрес Подрядчика при обнаружении недостатков или отклонений в работе.\n',
                        {text: '3.1.4. ', bold: true,},
                        'Приостановить выполнение Работ на Объекте в случае несанкционированных отступлений Подрядчика от согласованных ранее Подрядчиком расчетов. О приостановке работ Заказчик незамедлительно сообщает Подрядчику по номеру телефона либо по адресу электронной почты, указанным в разделе 10 настоящего договора.\n',
                        '\n',
                        {text: '3.2. Заказчик обязан:\n', bold: true,},
                        {text: '3.2.1. ', bold: true,},
                        'Обеспечить Подрядчику свободный доступ на Объект в целях передачи готовых Изделий и выполнению Работ на Объекте в согласованные Сторонами дату и время.\n',
                        {text: '3.2.2. ', bold: true,},
                        'Перед началом выполнения Работ на Объекте обеспечить доступ Подрядчика к получению электроэнергии 220 В и источнику воды.\n',
                        {text: '3.2.3. ', bold: true,},
                        'Содействовать Подрядчику, в том числе предоставлять необходимую для работы документацию (информацию).\n',
                        {text: '3.2.4. ', bold: true,},
                        'После изготовления Изделий принять их по УПД (Универсальный передаточный документ) на Изделия.\n',
                        {text: '3.2.5. ', bold: true,},
                        block4,
                        {text: '3.2.6. ', bold: true,},
                        'Оплатить стоимость Изделий и работ Подрядчика в порядке и сроки, указанные в Разделе 5 настоящего Договора.\n',
                        {text: '3.2.7. ', bold: true,},
                        'Не находиться в непосредственной близости (5 метров и ближе) проведения работ.\n',
                        '\n',
                        {text: '3.3. Подрядчик вправе:\n', bold: true,},
                        {text: '3.3.1. ', bold: true,},
                        'Самостоятельно определять способы (методы) выполнения работ по настоящему Договору. Если Заказчик не согласен с выбранным Подрядчиком методом выполнения Работ, и выбранный Заказчиком метод выполнения Работ влечет дополнительный выезд Подрядчика на Объект, то такой выезд оплачивается дополнительно в размере, установленном в п. 6.3. настоящего Договора. До момента исполнения Заказчиком обязательств по оплате повторного выезда Подрядчика вправе приостановить выполнение работы.\n',
                        {text: '3.3.2. ', bold: true,},
                        'Требовать от Заказчика надлежащего исполнения его обязанностей по настоящему Договору.\n',
                        {text: '3.3.3. ', bold: true,},
                        'Привлекать к исполнению своих обязательств по настоящему Договору других лиц (субПодрядчиков).\n',
                        {text: '3.3.4. ', bold: true,},
                        'Приостановить Выполнение работ на Объекте в любом из следующих случаев: при возникновении обстоятельств, указанных в п. 6.3. настоящего Договора, а также при выявлении в грунте техногенных остатков, твердых пород, слоев плотных, крупнообломочных, щебенистых грунтов, работы приостанавливаются до согласования Сторонами стоимости дополнительных работ.\n',
                        {text: '3.3.5. ', bold: true,},
                        'При неисполнении Заказчиком обязанности уплатить установленную цену по Договору, Подрядчик имеет право на удержание результата работ до полной уплаты Заказчиком соответствующих сумм.\n',
                        '\n',
                        {text: '3.4. Подрядчик обязан:\n', bold: true,},
                        {text: '3.4.1. ', bold: true,},
                        'Надлежащим образом изготовить Изделия и выполнить Работы по настоящему Договору.\n',
                        {text: '3.4.2. ', bold: true,},
                        'Передать Заказчику Изделия по УПД (Универсальный передаточный документ) на Изделия.\n',
                        {text: '3.4.3. ', bold: true,},
                        block3,
                        {text: '3.4.4. ', bold: true,},
                        'Предупредить Заказчика о появлении обстоятельств, которые грозят годности или прочности результатов выполненных работ и не зависят от Подрядчика, и до получения от Заказчика указаний приостановить выполнение работ.\n',
                        {text: '3.4.5. ', bold: true,},
                        'Приостановить выполнение Работ на Объекте в случае, если Заказчик настаивает на внесении изменений в согласованные ранее расчеты. В этом случае, Стороны, при наличии технической возможности, согласовывают перечень изменений, виды, стоимость и сроки дополнительных работ.\n',
                        '\n',
                        {text: '3.5. ', bold: true,},
                        'Стороны несут иные права и обязанности, предусмотренные законом и настоящим Договором.',
                    ],
                    style: 'simpleText',
                    margin: [0, 0, 0, 15],
                },
                {
                    text: '4. ПОРЯДОК ПРИЕМА-ПЕРЕДАЧИ ИЗДЕЛИЯ И РЕЗУЛЬТАТА ВЫПОЛНЕННЫХ РАБОТ',
                    style: 'title',
                    margin: [0, 0, 0, 15],
                },
                {
                    text: [
                        {text: '4.1. ', bold: true,},
                        'После готовности Изделий Подрядчик уведомляет об этом Заказчика по средствам связи, указанных в п. 10, и Стороны согласовывают дату и время доставки Изделий на Объект.\n',
                        {text: '4.2. ', bold: true,},
                        block5,
                        {text: '4.3. ', bold: true,},
                        'После выполнения Работ Подрядчик передает, а Заказчик принимает результат выполненной работы в соответствии с п.п. 4.4. – 4.6. настоящего Договора.\n',
                        {text: '4.4. ', bold: true,},
                        'Сдача результатов работ Подрядчика и приемка их Заказчиком осуществляется в день окончания работ путем визуального осмотра и оформления Акта об оказанных услугах, подписанной Сторонами. В день окончания работ Заказчик должен в присутствии Мастера или Подрядчика проверить внешний вид и комплектность установленных Изделий, их соответствие Спецификации, функциональные и габаритные размеры Изделий, качество изготовления и установки.\n',
                        {text: '4.5. ', bold: true,},
                        block6,
                        {text: '4.6. ', bold: true,},
                        'В случае выявления Заказчиком недостатков в монтажных работах и/или материалах Заказчик в произвольной форме должен составить Акт недостатков и передать его Подрядчику для согласования их Сторонами и установки сроков устранения выявленных недостатков в Акте.\n',
                        {text: '4.7. ', bold: true,},
                        block7,
                    ],
                    style: 'simpleText',
                    margin: [0, 0, 0, 15],
                },
                {
                    text: '5. ЦЕНА ДОГОВОРА И ПОРЯДОК ОПЛАТЫ',
                    style: 'title',
                    margin: [0, 0, 0, 15],
                },
                {
                    text: [
                        {
                            text: '5.1. Цена Договора складывается из стоимости изготавливаемых Изделий и выполненных работ и оплачивается на основании Счетов, выставляемых Подрядчиком Заказчику при заключении настоящего Договора, в порядке, установленном п. 5.3. настоящего Договора:\n',
                            bold: true,
                        },
                        {text: '5.1.1. ', bold: true,},
                        'Общая стоимость Изделий, доставки Изделий на Объект и выполнения работ на Объекте Заказчика указывается и согласовывается в Спецификации (Приложение №1).\n',
                        {text: '5.2. ', bold: true,},
                        'Если в ходе выполнения Подрядчиком работы возникла необходимость в проведении дополнительных работ, не согласованных Сторонами при заключении настоящего Договора, Подрядчик предупреждает об этом Заказчика. Заказчик, не согласившийся на превышение цены работы, согласованной при подписании настоящего Договора, вправе отказаться от Договора. В этом случае Заказчик уплачивает Подрядчику цену за фактически выполненную часть работы и оплачивает фактически понесенные Подрядчиком расходы.\n',
                        {
                            text: '5.3. Стороны согласовали поэтапную оплату цены Договора, указанную в Спецификации (Приложение №1).\n',
                            bold: true,
                        },
                        {text: '5.4. ', bold: true,},
                        'Оплата по настоящему Договору производится путем перечисления денежных средств на расчетный счет Подрядчика, указанный в разделе 10 настоящего Договора или путем внесения наличных средств в кассу компании / по банковским реквизитам карты.\n',
                    ],
                    style: 'simpleText',
                    margin: [0, 0, 0, 15],
                },
                {
                    text: '6. ГАРАНТИИ И ОТВЕТСТВЕННОСТЬ СТОРОН',
                    style: 'title',
                    margin: [0, 0, 0, 15],
                },
                {
                    text: [
                        {text: '6.1. ', bold: true,},
                        'За неисполнение или ненадлежащее исполнение обязательств по Договору Стороны несут ответственность в соответствии с действующим законодательством Российской Федерации и условиями настоящего Договора.\n',
                        {text: '6.2. ', bold: true,},
                        'При нарушении сроков оплаты, предусмотренных Разделом 5 настоящего Договора, Заказчик по требованию Подрядчика выплачивает неустойку (пеня) в размере 0,2 % от суммы подлежащей оплате, за каждый день просрочки.\n',
                        {text: '6.3. ', bold: true,},
                        'При неготовности Объекта для доставки изделий и/или выполнения Работ, отсутствии Заказчика либо при нарушении Заказчиком п.п. 3.2.1. - 3.2.2 повторный выезд Подрядчика на Объект оплачивается Заказчиком дополнительно по текущим расценкам, действующим у Подрядчика.\n',
                        {text: '6.4. ', bold: true,},
                        'В случае выявления Заказчиком недостатков в результате выполненных работ, возникших по вине Подрядчика в срок, установленный в п. 6.5 настоящего Договора, Подрядчик устраняет их самостоятельно и за свой счет в технически возможные сроки, согласованные Сторонами.\n',
                        {text: '6.5. ', bold: true,},
                        'Гарантийный срок на выполненные Работы составляет 1 (один) год.\n',
                        {text: '6.6. ', bold: true,},
                        'При обнаружении в течение гарантийного срока недостатков, указанных в п.6.4. Договора, Заказчик должен заявить о них Подрядчику в разумный срок по их обнаружении.\n',
                        {text: '6.7. ', bold: true,},
                        'При появлении ржавчины на отдельных участках изделия с полимерным покрытием Подрядчик вправе устранить дефекты на месте установки изделий согласно ГОСТ 9.410-88 п.1.8.3, п.1.8.8.\n',
                        {text: '6.8. ', bold: true,},
                        'Подрядчик не несет ответственности за недостатки, обнаруженные в пределах гарантийного срока, если они произошли вследствие нормального износа Изделия, неправильной его эксплуатации, неправильности инструкций по их эксплуатации, разработанных самим Заказчиком или привлеченными им третьими лицами, ненадлежащего ремонта объекта, произведенного самим заказчиком или привлеченными им третьими лицами.\n',
                        {text: '6.9. ', bold: true,},
                        'В случае выезда Подрядчика на Объект для устранения недостатков, возникших по причинам, перечисленным в пункте 6.8. такой выезд и устранение недостатков Изделия и/или работ по установке Изделия оплачиваются Заказчиком дополнительно по текущим расценкам, действующим у Подрядчика.\n',
                        {text: '6.10. ', bold: true,},
                        'За нарушение Подрядчиком сроков выполнения работ, предусмотренных настоящим Договором, по вине Подрядчика, Подрядчик выплачивает неустойку (пеня) в размере 0,2 % от стоимости работ, указанной в п.п. 5.1.2. настоящего Договора, за каждый рабочий день просрочки.\n',
                    ],
                    style: 'simpleText',
                    margin: [0, 0, 0, 15],
                },
                {
                    text: '7. ФОРС-МАЖОРНЫЕ ОБСТОЯТЕЛЬСТВА',
                    style: 'title',
                    margin: [0, 0, 0, 15],
                },
                {
                    text: [
                        {text: '7.1. ', bold: true,},
                        'Стороны освобождаются от ответственности за полное или частичное неисполнение обязательств по настоящему Договору, если неисполнение явилось следствием обстоятельств непреодолимой силы, а именно: наводнений, землетрясений, иных стихийных бедствий, плохих погодных условий (п. 7.3), военных действий, политических и государственных столкновений и переворотов, и других обстоятельств, если они повлияли на не исполнение Сторонами обязательств, принятых на себя по настоящему Договору. При этом срок исполнения обязательств по Договору отодвигается соразмерно времени, в течение которого действовали такие обстоятельства.\n',
                        {text: '7.2. ', bold: true,},
                        'Сторона, для которой создались обстоятельства невозможности исполнения обязательств по настоящему Договору, должна известить об этом другую Сторону не позднее, чем через 5 (пять) дней после наступления событий, указанных в пункте 7.1 настоящего Договора.\n',
                        {text: '7.3. ', bold: true,},
                        'Стороны согласовали, что при плохих погодных условиях Подрядчик вправе не начинать (приостановить) выполнение Работ на Объекте. К плохим погодным условиям относятся: дневная температура окружающей среды минус 18 (восемнадцать) градусов по Цельсию и ниже, ветер более 10 м/с, дождь, снег, град, туман. В этом случае срок выполнения работ по настоящему Договору отодвигается соразмерно сроку продолжительности плохих погодных условий без возникновения какой-либо ответственности у Подрядчика. О приостановке проведения работ Подрядчик уведомляет Заказчика.\n',
                    ],
                    style: 'simpleText',
                    margin: [0, 0, 0, 15],
                },
                {
                    text: '8. ПОРЯДОК РАЗРЕШЕНИЯ СПОРОВ',
                    style: 'title',
                    margin: [0, 0, 0, 15],
                },
                {
                    text: [
                        {text: '8.1. ', bold: true,},
                        'Все споры и разногласия, которые могут возникнуть при исполнении Сторонами настоящего Договора и в связи с ним, Стороны будут пытаться решить посредством переговоров.\n',
                        {text: '8.2. ', bold: true,},
                        'При невозможности разрешения спора по обоюдному согласию путем переговоров, он подлежит передаче в суд по месту нахождения Подрядчика.\n',
                    ],
                    style: 'simpleText',
                    margin: [0, 0, 0, 15],
                },
                {
                    text: '9. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ',
                    style: 'title',
                    margin: [0, 0, 0, 15],
                },
                {
                    text: [
                        {text: '9.1. ', bold: true,},
                        'Настоящий Договор вступает в силу с даты его подписания Сторонами и действует до полного исполнения Сторонами своих обязательств по нему.\n',
                        {text: '9.2. ', bold: true,},
                        'Изменения и дополнения к настоящему Договору будут действительны, если они будут совершены в письменной форме и подписаны обеими Сторонами.\n',
                        {text: '9.3. ', bold: true,},
                        'Стороны вправе по обоюдному соглашению расторгнуть настоящий Договор, определив в таком соглашении дату, порядок и условия расторжения настоящего Договора.\n',
                        {text: '9.4. ', bold: true,},
                        'В случае одностороннего отказа от исполнения Договора (ст. 450.1. ГК РФ), когда такой отказ допускается законом или настоящим Договором, Сторона, отказавшаяся от исполнения Договора, направляет другой Стороне Уведомление об отказе от исполнения Договора в одностороннем порядке путем направления заказным письмом с уведомлением.\n',
                        {text: '9.5. ', bold: true,},
                        'Договор считается расторгнутым по истечении 2 рабочих дней с момента получения Стороной, отказывающейся от исполнения Договора, подтверждения получения другой Стороной Уведомления об одностороннем отказе от исполнения Договора.\n',
                        {text: '9.6. ', bold: true,},
                        'Уведомление Заказчика во всех случаях, предусмотренных Договором, за исключением случаев, предусмотренных п. 9.4. настоящего Договора, производится по телефону или электронной почте, указанным в разделе 10 настоящего Договора. При этом Заказчик:\n' +
                        '- подтверждает, что указанный номер и электронная почта являются достоверными, надежными и незамедлительными способами связи с ним;\n' +
                        '- признает, что надлежащим уведомлением Заказчика по телефону будет считаться передача информации любому лицу, принявшему звонок по указанному номеру телефона.\n' +
                        'В случае если указанный номер телефона и/или электронная почта являются ошибочными или в течение 2 (двух) дней Подрядчик не сможет дозвониться до Заказчика или отправить электронное письмо, то Подрядчик направляет в адрес заказчика уведомление в порядке, указанном в п. 9.4., 9.5. настоящего Договора.\n',
                        {text: '9.7. ', bold: true,},
                        'Стороны обязуются извещать друг друга обо всех изменениях номеров телефонов, почтовых и других адресов, платежных реквизитов, а также обо всех иных изменениях, отсутствие которых может препятствовать Сторонам надлежащим образом исполнять принятые на себя обязательства и осуществлять права по настоящему Договору. При неисполнении указанной обязанности все негативные последствия ложатся на Сторону, не исполнившую обязательство.\n',
                        {text: '9.8. ', bold: true,},
                        'Договор составлен в 2 (двух) экземплярах, имеющих одинаковую юридическую силу, по одному экземпляру для каждой из Сторон.\n',
                        {text: '9.9. ', bold: true,},
                        'Все Приложения к настоящему Договору являются его неотъемлемой частью, если они подписаны Сторонами.\n\n',
                        {text: 'Приложения к настоящему Договору:\n', bold: true,},
                        {text: '1) ', bold: true,},
                        'Приложение № 1 (Спецификация)\n',
                    ],
                    style: 'simpleText',
                    margin: [0, 0, 0, 15],
                },
                {
                    text: '10. ЮРИДИЧЕСКИЕ АДРЕСА, РЕКВИЗИТЫ И ПОДПИСИ СТОРОН',
                    style: 'title',
                    margin: [0, 0, 0, 15],
                },
                {
                    style: 'simpleText',
                    margin: [0, 0, 0, 10],
                    table: {
                        widths: [200, 50, '*'],
                        body: [
                            [
                                {
                                    text: block9_1,
                                    border: [false, false, false, false],
                                },
                                {
                                    text: '',
                                    border: [false, false, false, false],
                                },
                                {
                                    text: [
                                        {text: 'Подрядчик:\n', style: 'title2',},
                                        {text: isCom.ur[1]+' «'+isCom.ur[2]+'»\n', bold:true,},
                                        {text: 'ИНН: '+isCom.ur[4]+'\nКПП: '+isCom.ur[5]+'\nОГРН: '+isCom.ur[17] + '\n',},
                                        {text: 'Адрес: '+isCom.ur[3]+'\nТел.: '+isCom.osn[3]+'\nEmail: '+isCom.osn[4] + '\n',},
                                        {text: 'Банк: '+isCom.ur[6]+'\nБИК: '+isCom.ur[7]+'\nК/С: '+isCom.ur[8]+'\nР/С: '+isCom.ur[9] + '\n',},
                                    ],
                                    border: [false, false, false, false],
                                },
                            ],
                        ],
                    },
                },
                {
                    style: 'simpleText',
                    margin: [0, 0, 0, 10],
                    table: {
                        widths: [200, 50, '*'],
                        body: [
                            [
                                {
                                    text: [
                                        {text: 'Заказчик:\n', style: 'title2',},
                                        block8_1,
                                    ],
                                    border: [false, false, false, false],
                                },
                                {
                                    text: '',
                                    border: [false, false, false, false],
                                },
                                {
                                    text: [
                                        {text: 'Подрядчик:\n', style: 'title2',},
                                        {text: 'Директор '+isCom.ur[1]+' «'+isCom.ur[2]+'»\n', bold:true,},
                                    ],
                                    border: [false, false, false, false],
                                },
                            ],
                        ],
                    },
                },
                {
                    style: 'simpleText',
                    margin: [0, 20, 0, 10],
                    table: {
                        widths: [200, 50, '*'],
                        body: [
                            [
                                {
                                    table: {
                                        widths: [70, 'auto'],
                                        body: [
                                            [
                                                PicSignСustomer2,
                                                {text: '/ '+isLicoShort, border: [false, false, false, false],},
                                            ],
                                        ],
                                    },
                                    border: [false, false, false, false],
                                },
                                {
                                    text: '',
                                    border: [false, false, false, false],
                                },
                                {
                                    table: {
                                        widths: [70, 'auto'],
                                        body: [
                                            [
                                                PicSignDirector2,
                                                {text: '/ '+isCom.ur[18], border: [false, false, false, false],},
                                            ],
                                        ],
                                    },
                                    border: [false, false, false, false],
                                },
                            ],
                        ],
                    },
                },  // signs
                {
                    table: {
                        widths: [250, '*'],
                        body: [
                            [
                                PicClientStamp,
                                PicStamp,
                            ],
                        ],
                    },
                    pageBreak: 'after',
                },  // stamps

                {
                    text: [
                        'ПРИЛОЖЕНИЕ №1 К ДОГОВОРУ №'+Q_('#print-data3').value+'\n',
                        'на изготовление и установку металлоконструкций от '+Q_('#print-data4').value+'г.\n\n',
                        'Спецификация 1 от '+Q_('#print-data4').value+'г.',
                    ],
                    bold: true,
                    alignment: 'right',
                    margin: [0, 0, 0, 15],
                    fontSize: 12,
                },
                block10,
                {
                    style: 'tableHead',
                    margin: [0, 0, 0, 20],
                    table: {
                        headerRows: 1,
                        widths: ['auto', '*', 'auto', 'auto', 'auto', 'auto'],
                        body: [
                            [
                                {text: '№', bold: true, fillColor: '#eee',},
                                {text: 'Товар / Услуга', bold: true, fillColor: '#eee',},
                                {text: 'Кол-во', bold: true, fillColor: '#eee',},
                                {text: 'Ед.', bold: true, fillColor: '#eee',},
                                {text: 'Цена', bold: true, fillColor: '#eee',},
                                {text: 'Сумма', bold: true, fillColor: '#eee',},
                            ],
                            ...isNoms,
                            ...ndsLine,
                        ],
                    },
                },  // table with nomenclature
                {
                    text: 'Общая стоимость товара и работ'+isNDS+' составляет '+Math.floor(isTotal * 1).toLocaleString('ru-RU')+' рубля '+(isTotal * 1).toFixed(2).toString().split('.')[1]+' копеек',
                    style: 'simpleText',
                    margin: [0, 0, 0, 15],
                },
                {
                    text: block11,
                    style: 'simpleText',

                    pageBreak: 'after',
                },

                {
                    style: 'simpleText',
                    margin: [0, 0, 0, 10],
                    table: {
                        widths: [200, 50, '*'],
                        body: [
                            [
                                {
                                    text: block9_2,
                                    border: [false, false, false, false],
                                },
                                {
                                    text: '',
                                    border: [false, false, false, false],
                                },
                                {
                                    text: [
                                        {text: 'Подрядчик:\n', style: 'title2',},
                                        {text: isCom.ur[1]+' «'+isCom.ur[2]+'»\n', bold:true,},
                                        {text: 'ИНН: '+isCom.ur[4]+'\nКПП: '+isCom.ur[5]+'\nОГРН: '+isCom.ur[17] + '\n',},
                                        {text: 'Адрес: '+isCom.ur[3]+'\nТел.: '+isCom.osn[3]+'\nEmail: '+isCom.osn[4] + '\n',},
                                        {text: 'Банк: '+isCom.ur[6]+'\nБИК: '+isCom.ur[7]+'\nК/С: '+isCom.ur[8]+'\nР/С: '+isCom.ur[9] + '\n',},
                                    ],
                                    border: [false, false, false, false],
                                },
                            ],
                        ],
                    },
                },
                {
                    style: 'simpleText',
                    margin: [0, 0, 0, 10],
                    table: {
                        widths: [200, 50, '*'],
                        body: [
                            [
                                {
                                    text: [
                                        {text: 'Заказчик:\n', style: 'title2',},
                                        block8_2,
                                    ],
                                    border: [false, false, false, false],
                                },
                                {
                                    text: '',
                                    border: [false, false, false, false],
                                },
                                {
                                    text: [
                                        {text: 'Подрядчик:\n', style: 'title2',},
                                        {text: 'Директор '+isCom.ur[1]+' «'+isCom.ur[2]+'»\n', bold:true,},
                                    ],
                                    border: [false, false, false, false],
                                },
                            ],
                        ],
                    },
                },
                {
                    style: 'simpleText',
                    margin: [0, 20, 0, 10],
                    table: {
                        widths: [200, 50, '*'],
                        body: [
                            [
                                {
                                    table: {
                                        widths: [70, 'auto'],
                                        body: [
                                            [
                                                PicSignСustomer3,
                                                {text: '/ '+isLicoShort, border: [false, false, false, false],},
                                            ],
                                        ],
                                    },
                                    border: [false, false, false, false],
                                },
                                {
                                    text: '',
                                    border: [false, false, false, false],
                                },
                                {
                                    table: {
                                        widths: [70, 'auto'],
                                        body: [
                                            [
                                                PicSignDirector3,
                                                {text: '/ '+isCom.ur[18], border: [false, false, false, false],},
                                            ],
                                        ],
                                    },
                                    border: [false, false, false, false],
                                },
                            ],
                        ],
                    },
                },  // signs
                {
                    table: {
                        widths: [250, '*'],
                        body: [
                            [
                                PicClientStamp2,
                                PicStamp2,
                            ],
                        ],
                    },
                },                          // stamps

            ],
            footer: function(currentPage, pageCount) {
                return {
                    style: 'footerText',
                    margin: [35, 20, 35, 0],
                    table: {
                        widths: ['auto', 70, 'auto', '*', 'auto', 70, 'auto'],
                        body: [
                            [
                                {text: 'Заказчик', border: [false, false, false, false],},
                                PicSignСustomer,
                                {text: '/ '+isLicoShort, border: [false, false, false, false],},
                                {text: '', border: [false, false, false, false],},
                                {text: 'Подрядчик', border: [false, false, false, false],},
                                PicSignDirector,
                                {text: '/ '+isCom.ur[18], border: [false, false, false, false],},
                            ],
                            [
                                {text: 'стр. ' + currentPage + ' из ' + pageCount, border: [false, false, false, false], colSpan: 7, alignment: 'right',},
                                '',
                                '',
                                '',
                                '',
                                '',
                                '',
                            ],
                        ],
                    },
                    layout: 'lightHorizontalLines',
                }
            },
            styles: {
                tableHead: {
                    fontSize: 9,
                    color: '#000',
                },
                title: {
                    fontSize: 12,
                    bold: true,
                    color: '#000',
                    alignment: 'center',
                },
                title2: {
                    fontSize: 12,
                    bold: true,
                    color: '#000',
                    margin: [0, 0, 0, 10],
                },
                greyText: {
                    fontSize: 8,
                    color: '#777',
                },
                simpleText: {
                    fontSize: 10,
                    color: '#000',
                    lineHeight: 1.2,
                },
                footerText: {
                    fontSize: 8,
                    color: '#000',
                    lineHeight: 1.2,
                },
            },
            pageMargins: [35, 35, 35, 70],
        }

        // create
        pdfMake.createPdf(tmpPDF).open()
        Q_('.popup-wait').style.display = 'none'
    }
    async function PDF_dogovor_2(comId, nds, sign, lico){
        Q_('.popup-wait').style.display = 'grid'

        // check summ for ready
        let checker = 1
        Qs_('.page-order-content-wrp .nom').forEach((nm) => {
            if(nm.getAttribute('data-cena') === 'false'){
                checker = 0
            }
        })
        if(checker === 0){
            setTimeout(function () {
                PDF_dogovor_2(comId, nds, sign, lico)
            },1000)
            return
        }

        // company and other data
        let isCom = getCompanyByUserID(comId)
        let isTotal = sumTotal

        // noms table lines
        let isNoms = nomTableForDocs(true)

        // NDS is need?
        let ndsLine = nomItogForDocs(nds, isTotal)
        let isNDS = ''
        if(nds){
            isNDS = ' с учётом НДС'
        }

        // witch lico we use
        // let licoId = Q_('#print-client-id').value
        let licoId = Q_('.page-order-params-wrp .param select[name="client"]').value
        let licoObj = isSysDat.companies[licoId]
        let isLico = licoObj.nm
        let isLicoShort = licoObj.nm
        if(lico === 'fiz' && licoObj.fiz[0]){
            isLico = licoObj.fiz[1]
            if(licoObj.fiz[9]){
                isLicoShort = licoObj.fiz[9]
            }else{
                isLicoShort = isLico
            }
        }else if(lico === 'ur' && licoObj.ur[0]){
            isLico = licoObj.ur[11]
            if(licoObj.ur[18]){
                isLicoShort = licoObj.ur[18]
            }else{
                isLicoShort = isLico
            }
        }

        // logo
        let logoPrint = {
            text: 'Заборы и Ворота',
            border: [false, true, false, true],
            width: 80,
            margin: [0, 10, 20, 10],
            fontSize: 15,
            bold: true,
        }
        if(isCom.ur[16]){
            await fetch(isCom.ur[16])
                .then(res => res.blob())
                .then(blobToBase64)
                .then(finalResult => {
                    logoPrint = {
                        image: finalResult,
                        border: [false, true, false, true],
                        width: 80,
                        margin: [0, 25, 20, 10],
                    }
                })
        }
        // sign of client
        let PicSignСustomer = {text: '', border: [false, false, false, true],}
        let PicSignСustomer2 = {text: '', border: [false, false, false, true],}
        let PicSignСustomer3 = {text: '', border: [false, false, false, true],}
        if(licoObj.ur[13] && sign && lico === 'ur'){
            await fetch(licoObj.ur[13])
                .then(res => res.blob())
                .then(blobToBase64)
                .then(finalResult => {
                    PicSignСustomer = {
                        image: finalResult,
                        fit: [100, 60],
                        border: [false, false, false, true],
                        alignment: 'center',
                        margin: [0, -25, 0, -15],
                    }
                    PicSignСustomer2 = {
                        image: finalResult,
                        fit: [100, 60],
                        border: [false, false, false, true],
                        alignment: 'center',
                        margin: [0, -30, 0, -20],
                    }
                    PicSignСustomer3 = {
                        image: finalResult,
                        fit: [100, 60],
                        border: [false, false, false, true],
                        alignment: 'center',
                        margin: [0, -30, 0, -20],
                    }
                })
        }
        if(licoObj.fiz[8] && sign && lico === 'fiz'){
            await fetch(licoObj.fiz[8])
                .then(res => res.blob())
                .then(blobToBase64)
                .then(finalResult => {
                    PicSignСustomer = {
                        image: finalResult,
                        fit: [100, 60],
                        border: [false, false, false, true],
                        alignment: 'center',
                        margin: [0, -25, 0, -15],
                    }
                    PicSignСustomer2 = {
                        image: finalResult,
                        fit: [100, 60],
                        border: [false, false, false, true],
                        alignment: 'center',
                        margin: [0, -30, 0, -20],
                    }
                    PicSignСustomer3 = {
                        image: finalResult,
                        fit: [100, 60],
                        border: [false, false, false, true],
                        alignment: 'center',
                        margin: [0, -30, 0, -20],
                    }
                })
        }
        // sign of director
        let PicSignDirector = {text: '', border: [false, false, false, true],}
        let PicSignDirector2 = {text: '', border: [false, false, false, true],}
        let PicSignDirector3 = {text: '', border: [false, false, false, true],}
        if(isCom.ur[13] && sign){
            await fetch(isCom.ur[13])
                .then(res => res.blob())
                .then(blobToBase64)
                .then(finalResult => {
                    PicSignDirector = {
                        image: finalResult,
                        fit: [100, 60],
                        border: [false, false, false, true],
                        alignment: 'center',
                        margin: [0, -25, 0, -15],
                    }
                    PicSignDirector2 = {
                        image: finalResult,
                        fit: [100, 60],
                        border: [false, false, false, true],
                        alignment: 'center',
                        margin: [0, -30, 0, -20],
                    }
                    PicSignDirector3 = {
                        image: finalResult,
                        fit: [100, 60],
                        border: [false, false, false, true],
                        alignment: 'center',
                        margin: [0, -30, 0, -20],
                    }
                })
        }
        // stamp
        let PicStamp = {text: '', border: [false, false, false, false],}
        let PicStamp2 = {text: '', border: [false, false, false, false],}
        let PicClientStamp = {text: '', border: [false, false, false, false],}
        let PicClientStamp2 = {text: '', border: [false, false, false, false],}
        if(isCom.ur[15] && sign){
            await fetch(isCom.ur[15])
                .then(res => res.blob())
                .then(blobToBase64)
                .then(finalResult => {
                    PicStamp = {image: finalResult, fit: [100, 100], alignment: 'left', border: [false, false, false, false],}
                    PicStamp2 = {image: finalResult, fit: [100, 100], alignment: 'left', border: [false, false, false, false],}
                })
        }
        if(licoObj.ur[15] && sign && lico === 'ur'){
            await fetch(licoObj.ur[15])
                .then(res => res.blob())
                .then(blobToBase64)
                .then(finalResult => {
                    PicClientStamp = {image: finalResult, fit: [100, 100], alignment: 'left', border: [false, false, false, false],}
                    PicClientStamp2 = {image: finalResult, fit: [100, 100], alignment: 'left', border: [false, false, false, false],}
                })
        }

        // UR or FIZ text blocks
        let block1 = {
            text: isLico + ', паспорт серия '+licoObj.fiz[2]+' номер '+licoObj.fiz[3]+' выдан '+licoObj.fiz[4]+' '+licoObj.fiz[6]+', именуемый в дальнейшем «Заказчик» с одной стороны и '+isCom.ur[1]+' «'+isCom.ur[2]+'», именуемое в дальнейшем «Подрядчик», в лице Директора '+isCom.ur[11]+', действующего на основании Устава, с другой стороны, совместно именуемые «Стороны», а по отдельности – «Сторона» заключили настоящий Договор (далее – «Договор») о нижеследующем:',
            style: 'simpleText',
            margin: [0, 0, 0, 15],
        }
        if(lico === 'ur'){
            block1 = {
                text: licoObj.ur[1]+' «'+licoObj.ur[2]+'», в лице Директора '+licoObj.ur[11]+', действующего на основании Устава, именуемый в дальнейшем «Заказчик» с одной стороны и '+isCom.ur[1]+' «'+isCom.ur[2]+'», именуемое в дальнейшем «Подрядчик», в лице Директора '+isCom.ur[11]+', действующего на основании Устава, с другой стороны, совместно именуемые «Стороны», а по отдельности – «Сторона» заключили настоящий Договор (далее – «Договор») о нижеследующем:',
                style: 'simpleText',
                margin: [0, 0, 0, 15],
            }
        }
        let block8_1 = {text: '\n'}
        let block8_2 = {text: '\n'}
        if(lico === 'ur'){
            block8_1 = {text: 'Директор '+licoObj.ur[1]+' «'+licoObj.ur[2]+'»\n', bold:true,}
            block8_2 = {text: 'Директор '+licoObj.ur[1]+' «'+licoObj.ur[2]+'»\n', bold:true,}
        }
        let block9_1 = [
            {text: 'Заказчик:\n', style: 'title2',},
            {text: isLico+'\n', bold:true,},
            {text: 'Паспорт: '+licoObj.fiz[2]+'-'+licoObj.fiz[3]+'\n',},
            {text: 'Выдан: '+licoObj.fiz[4]+' '+licoObj.fiz[6]+'\n',},
            {text: 'Тел.: '+licoObj.osn[3]+'\n',},
            {text: 'Email: '+licoObj.osn[4]+'\n',},
        ]
        let block9_2 = [
            {text: 'Заказчик:\n', style: 'title2',},
            {text: isLico+'\n', bold:true,},
            {text: 'Паспорт: '+licoObj.fiz[2]+'-'+licoObj.fiz[3]+'\n',},
            {text: 'Выдан: '+licoObj.fiz[4]+' '+licoObj.fiz[6]+'\n',},
            {text: 'Тел.: '+licoObj.osn[3]+'\n',},
            {text: 'Email: '+licoObj.osn[4]+'\n',},
        ]
        if(lico === 'ur'){
            block9_1 = [
                {text: 'Заказчик:\n', style: 'title2',},
                {text: licoObj.ur[1]+' «'+licoObj.ur[2]+'»\n', bold:true,},
                {text: 'ИНН: '+licoObj.ur[4]+'\nКПП: '+licoObj.ur[5]+'\nОГРН: '+licoObj.ur[17] + '\n',},
                {text: 'Адрес: '+licoObj.ur[3]+'\nТел.: '+licoObj.osn[3]+'\nEmail: '+licoObj.osn[4] + '\n',},
                {text: 'Банк: '+licoObj.ur[6]+'\nБИК: '+licoObj.ur[7]+'\nК/С: '+licoObj.ur[8]+'\nР/С: '+licoObj.ur[9] + '\n',},
            ]
            block9_2 = [
                {text: 'Заказчик:\n', style: 'title2',},
                {text: licoObj.ur[1]+' «'+licoObj.ur[2]+'»\n', bold:true,},
                {text: 'ИНН: '+licoObj.ur[4]+'\nКПП: '+licoObj.ur[5]+'\nОГРН: '+licoObj.ur[17] + '\n',},
                {text: 'Адрес: '+licoObj.ur[3]+'\nТел.: '+licoObj.osn[3]+'\nEmail: '+licoObj.osn[4] + '\n',},
                {text: 'Банк: '+licoObj.ur[6]+'\nБИК: '+licoObj.ur[7]+'\nК/С: '+licoObj.ur[8]+'\nР/С: '+licoObj.ur[9] + '\n',},
            ]
        }
        let block10 = {
            text: isLico + ', паспорт серия '+licoObj.fiz[2]+' номер '+licoObj.fiz[3]+' выдан '+licoObj.fiz[4]+' '+licoObj.fiz[6]+', именуемый в дальнейшем «Заказчик» с одной стороны и '+isCom.ur[1]+' «'+isCom.ur[2]+'», именуемое в дальнейшем «Подрядчик», в лице Директора '+isCom.ur[11]+', действующего на основании Устава, с другой стороны, совместно именуемые «Стороны», а по отдельности – «Сторона», утвердили счет на изготовление следующих изделий:',
            style: 'simpleText',
            margin: [0, 0, 0, 15],
        }
        if(lico === 'ur'){
            block10 = {
                text: licoObj.ur[1]+' «'+licoObj.ur[2]+'», в лице Директора '+licoObj.ur[11]+', действующего на основании Устава, именуемый в дальнейшем «Заказчик» с одной стороны и '+isCom.ur[1]+' «'+isCom.ur[2]+'», именуемое в дальнейшем «Подрядчик», в лице Директора '+isCom.ur[11]+', действующего на основании Устава, с другой стороны, совместно именуемые «Стороны», а по отдельности – «Сторона», утвердили счет на изготовление следующих изделий:',
                style: 'simpleText',
                margin: [0, 0, 0, 15],
            }
        }
        let block11 = [
            {text: '1 платёж ', bold: true,},
            'составляет '+Q_('#print-data5').value+' руб., производится Заказчиком в течение 3 (трех) банковских дней после подписания Сторонами Спецификации.\n\n',
            {text: '2 платёж ', bold: true,},
            'составляет '+Q_('#print-data6').value+' руб., производится Заказчиком за 1 (один) день до доставки Изделий на объект.\n',
        ]
        if(printPop2Pay === '3'){
            block11 = [
                {text: '1 платёж ', bold: true,},
                'составляет '+Q_('#print-data5').value+' руб., производится Заказчиком в течение 3 (трех) банковских дней после подписания Сторонами Спецификации.\n\n',
                {text: '2 платёж ', bold: true,},
                'составляет '+Q_('#print-data6').value+' руб., производится Заказчиком за 1 (один) день до доставки Изделий на объект.\n\n',
                {text: '3 платёж ', bold: true,},
                'составляет '+Q_('#print-data7').value+' руб., производится Заказчиком при подписанием УПД (Универсальный передаточный документ) при получении Изделий на Объекте.\n',
            ]
        }else if(printPop2Pay === '1'){
            block11 = [
                {text: 'Платёж составляет '+parseFloat((isTotal * 1).toFixed(2)).toLocaleString('ru-RU')+' руб.', bold: true,},
                ' и производится Заказчиком в течение 3 (трех) банковских дней после подписания Сторонами Спецификации.\n\n',
            ]
        }

        let tmpPDF = {
            content: [
                {
                    style: 'tableHead',
                    margin: [0, 0, 0, 20],
                    table: {
                        widths: ['auto', '*'],
                        body: [
                            [
                                logoPrint,
                                {
                                    text: [
                                        {text: isCom.ur[1] + ' "' + isCom.ur[2] + '"' + '\n', bold: true,},
                                        'Адрес: '+isCom.ur[3]+', Тел.: '+isCom.osn[3]+', Email: '+isCom.osn[4] + '\n',
                                        'ИНН: '+isCom.ur[4]+', КПП: '+isCom.ur[5]+', ОГРН: '+isCom.ur[17] + '\n',
                                        'Банк: '+isCom.ur[6]+', БИК: '+isCom.ur[7]+', К/С: '+isCom.ur[8]+', Р/С: '+isCom.ur[9] + '\n',
                                    ],
                                    border: [false, true, false, true],
                                    margin: [0, 10, 0, 10],
                                },
                            ],
                        ],
                    },
                },
                {
                    text: 'ДОГОВОР ПОДРЯДА № ' + Q_('#print-data3').value,
                    style: 'title',
                },
                {
                    text: 'на изготовление и доставку металлоконструкций',
                    bold: true,
                    alignment: 'center',
                    fontSize: 9,
                    margin: [0, 0, 0, 10],
                },
                {
                    style: 'tableHead',
                    margin: [0, 0, 0, 10],
                    table: {
                        widths: ['auto', '*'],
                        body: [
                            [
                                {
                                    text: 'Город: ' + isSysDat.cities[Q_('.page-order-params-wrp .param-field[name="city"]').value],
                                    border: [false, false, false, false],
                                },
                                {
                                    text: 'Дата: ' + Q_('#print-data4').value + 'г.',
                                    border: [false, false, false, false],
                                    alignment: 'right',
                                },
                            ],
                        ],
                    },
                },
                block1,
                {
                    text: '1. ПРЕДМЕТ ДОГОВОРА',
                    style: 'title',
                    margin: [0, 0, 0, 15],
                },
                {
                    text: [
                        {text: '1.1. ', bold: true,},
                        'По настоящему Договору, Подрядчик обязуется изготовить металлоконструкции (далее - Изделия) указанные в Спецификации, являющейся неотъемлемой частью Договора (Приложение № 1) и передать изделия Заказчику, а Заказчик обязуется принять Изделия и оплатить в порядке и на условиях, установленных настоящим Договором.\n',
                        {text: '1.2. ', bold: true,},
                        'Технические и количественные требования к результату работ по настоящему Договору содержатся в расчетах (в Приложении №1), представляемых Заказчиком Подрядчику.\n',
                        {text: '1.3. ', bold: true,},
                        'Доставка Изделий производятся по адресу: '+Q_('.page-order-params-wrp .param-field[name="address"]').value+'.\n',
                        {text: '1.4. ', bold: true,},
                        'Изделия изготовление которых является обязанностью Подрядчика по настоящему Договору, изготовляются из его материалов, его силами и средствами.\n',
                        {text: '1.5. ', bold: true,},
                        'Право собственности на Изделия переходит к Заказчику после подписания Сторонами УПД (Универсальный передаточный документ) и полной оплаты Заказчиком за Изделия согласно Разделу 5 настоящего Договора.\n',
                    ],
                    style: 'simpleText',
                    margin: [0, 0, 0, 15],
                },
                {
                    text: '2. ПОРЯДОК СОГЛАСОВАНИЯ ЗАКАЗА, СРОКИ ВЫПОЛНЕНИЯ РАБОТ',
                    style: 'title',
                    margin: [0, 0, 0, 15],
                },
                {
                    text: [
                        {text: '2.1. ', bold: true,},
                        'Согласование технических и количественных требований к Изделиям осуществляется Сторонами в следующем порядке:\n',
                        {text: '2.1.1. ', bold: true,},
                        'Заказчик предоставляет Подрядчику расчеты, в которых содержатся технические и количественные требования к изделиям.\n',
                        {text: '2.1.2. ', bold: true,},
                        'В течение 1 (одного) рабочего дня с даты согласования расчетов Заказчик и Подрядчик подписывают Спецификацию, содержащую номер счета, дату и сумму по счету.\n',
                        'Срок изготовления Изделий составляет '+Q_('#print-data1').value+' рабочих дней с момента осуществления Заказчиком предоплаты в соответствии с п. 5.3. настоящего Договора.\n',
                        'Срок доставки Изделий составляется по согласованию, но не позднее '+Q_('#print-data2').value+' рабочих дней с даты готовности Изделий.\n',
                        {text: '2.2. ', bold: true,},
                        'При нарушении Заказчиком условий Договора, препятствующих своевременному исполнению обязательств Подрядчиком, Подрядчик имеет право в одностороннем порядке внести изменения в Договор в части сроков выполнения доставки на объект. Нарушением обязательств признается любое нарушение сроков оплаты, отсутствие надлежащего доступа на Объект в согласованные дату и время, неготовности Объекта для проведения замеров, доставки Изделий, а также при любых других обстоятельствах, требующих содействия Заказчика.\n',
                    ],
                    style: 'simpleText',
                    margin: [0, 0, 0, 15],
                },
                {
                    text: '3. ПРАВА И ОБЯЗАННОСТИ СТОРОН',
                    style: 'title',
                    margin: [0, 0, 0, 15],
                },
                {
                    text: [
                        {text: '3.1. Заказчик вправе:\n', bold: true,},
                        {text: '3.1.1. ', bold: true,},
                        'Требовать от Подрядчика надлежащего исполнения его обязанностей, предусмотренных настоящим Договором.\n',
                        '\n',
                        {text: '3.2. Заказчик обязан:\n', bold: true,},
                        {text: '3.2.1. ', bold: true,},
                        'Обеспечить Подрядчику свободный доступ на Объект в целях передачи готовых Изделий на Объект в согласованные Сторонами дату и время.\n',
                        {text: '3.2.2. ', bold: true,},
                        'После изготовления Изделий принять их по УПД (Универсальный передаточный документ) на Изделия.\n',
                        {text: '3.2.3. ', bold: true,},
                        'Оплатить стоимость Изделий Подрядчика в порядке и сроки, указанные в Разделе 5 настоящего Договора.\n',
                        '\n',
                        {text: '3.3. Подрядчик вправе:\n', bold: true,},
                        {text: '3.3.1. ', bold: true,},
                        'Требовать от Заказчика надлежащего исполнения его обязанностей по настоящему Договору.\n',
                        {text: '3.3.2. ', bold: true,},
                        'Привлекать к исполнению своих обязательств по настоящему Договору других лиц (субПодрядчиков).\n',
                        '\n',
                        {text: '3.4. Подрядчик обязан:\n', bold: true,},
                        {text: '3.4.1. ', bold: true,},
                        'Надлежащим образом изготовить Изделия по настоящему Договору.\n',
                        {text: '3.4.2. ', bold: true,},
                        'Передать Заказчику Изделия по УПД (Универсальный передаточный документ) на Изделия.\n',
                        '\n',
                        {text: '3.5. ', bold: true,},
                        'Стороны несут иные права и обязанности, предусмотренные законом и настоящим Договором.',
                    ],
                    style: 'simpleText',
                    margin: [0, 0, 0, 15],
                },
                {
                    text: '4. ПОРЯДОК ПРИЕМА-ПЕРЕДАЧИ ИЗДЕЛИЯ И РЕЗУЛЬТАТА ВЫПОЛНЕННЫХ РАБОТ',
                    style: 'title',
                    margin: [0, 0, 0, 15],
                },
                {
                    text: [
                        {text: '4.1. ', bold: true,},
                        'После готовности Изделий Подрядчик уведомляет об этом Заказчика по средствам связи, указанных в п. 10, и Стороны согласовывают дату и время доставки Изделий на Объект.\n',
                        {text: '4.2. ', bold: true,},
                        'После доставки Изделий на Объект Подрядчик передает их по УПД (Универсальный передаточный документ) Изделий. С момента передачи Изделий Заказчику последний несет риск гибели (утраты) / порчи Изделий. Повторное изготовление Изделий и/или устранение возникших недостатков в Изделиях после передачи их Заказчику осуществляется Подрядчиком только при условии дополнительной оплаты. Стоимость такой оплаты согласовывается Сторонами дополнительно.\n',
                    ],
                    style: 'simpleText',
                    margin: [0, 0, 0, 15],
                },
                {
                    text: '5. ЦЕНА ДОГОВОРА И ПОРЯДОК ОПЛАТЫ',
                    style: 'title',
                    margin: [0, 0, 0, 15],
                },
                {
                    text: [
                        {
                            text: '5.1. Цена Договора складывается из стоимости изготавливаемых Изделий и оплачивается на основании Счетов, выставляемых Подрядчиком Заказчику при заключении настоящего Договора, в порядке, установленном п. 5.3. настоящего Договора:\n',
                            bold: true,
                        },
                        {text: '5.1.1. ', bold: true,},
                        'Общая стоимость Изделий, доставки Изделий на Объект Заказчика указывается и согласовывается в Спецификации (Приложение №1).\n',
                        {
                            text: '5.3. Стороны согласовали поэтапную оплату цены Договора, указанную в Спецификации (Приложение №1).\n',
                            bold: true,
                        },
                        {text: '5.3. ', bold: true,},
                        'Оплата по настоящему Договору производится путем перечисления денежных средств на расчетный счет Подрядчика, указанный в разделе 10 настоящего Договора или путем внесения наличных средств в кассу компании / по банковским реквизитам карты.\n',
                    ],
                    style: 'simpleText',
                    margin: [0, 0, 0, 15],
                },
                {
                    text: '6. ГАРАНТИИ И ОТВЕТСТВЕННОСТЬ СТОРОН',
                    style: 'title',
                    margin: [0, 0, 0, 15],
                },
                {
                    text: [
                        {text: '6.1. ', bold: true,},
                        'За неисполнение или ненадлежащее исполнение обязательств по Договору Стороны несут ответственность в соответствии с действующим законодательством Российской Федерации и условиями настоящего Договора.\n',
                        {text: '6.2. ', bold: true,},
                        'При нарушении сроков оплаты, предусмотренных Разделом 5 настоящего Договора, Заказчик по требованию Подрядчика выплачивает неустойку (пеня) в размере 0,2 % от суммы подлежащей оплате, за каждый день просрочки.\n',
                        {text: '6.3. ', bold: true,},
                        'Гарантийный срок на материалы составляет 1 (один) год.\n',
                        {text: '6.4. ', bold: true,},
                        'При появлении ржавчины на отдельных участках изделия с полимерным покрытием Подрядчик вправе устранить дефекты на месте установки изделий согласно ГОСТ 9.410-88 п.1.8.3, п.1.8.8.\n',
                        {text: '6.5. ', bold: true,},
                        'При обнаружении в течение гарантийного срока недостатков, Заказчик должен заявить о них Подрядчику в разумный срок по их обнаружении.\n',
                        {text: '6.6. ', bold: true,},
                        'Подрядчик не несет ответственности за недостатки, обнаруженные в пределах гарантийного срока, если они произошли вследствие нормального износа Изделия, неправильной его эксплуатации, неправильности инструкций по их эксплуатации, разработанных самим Заказчиком или привлеченными им третьими лицами, ненадлежащего ремонта объекта, произведенного самим заказчиком или привлеченными им третьими лицами.\n',
                        {text: '6.7. ', bold: true,},
                        'В случае выезда Подрядчика на Объект для устранения недостатков, возникших по причинам, перечисленным в пункте 6.6. такой выезд и устранение недостатков Изделия и/или работ по установке Изделия оплачиваются Заказчиком дополнительно по текущим расценкам, действующим у Подрядчика.\n',
                    ],
                    style: 'simpleText',
                    margin: [0, 0, 0, 15],
                },
                {
                    text: '7. ФОРС-МАЖОРНЫЕ ОБСТОЯТЕЛЬСТВА',
                    style: 'title',
                    margin: [0, 0, 0, 15],
                },
                {
                    text: [
                        {text: '7.1. ', bold: true,},
                        'Стороны освобождаются от ответственности за полное или частичное неисполнение обязательств по настоящему Договору, если неисполнение явилось следствием обстоятельств непреодолимой силы, а именно: наводнений, землетрясений, иных стихийных бедствий, плохих погодных условий (п. 7.3), военных действий, политических и государственных столкновений и переворотов, и других обстоятельств, если они повлияли на не исполнение Сторонами обязательств, принятых на себя по настоящему Договору. При этом срок исполнения обязательств по Договору отодвигается соразмерно времени, в течение которого действовали такие обстоятельства.\n',
                        {text: '7.2. ', bold: true,},
                        'Сторона, для которой создались обстоятельства невозможности исполнения обязательств по настоящему Договору, должна известить об этом другую Сторону не позднее, чем через 5 (пять) дней после наступления событий, указанных в пункте 7.1 настоящего Договора.\n',
                        {text: '7.3. ', bold: true,},
                        'Стороны согласовали, что при плохих погодных условиях Подрядчик вправе отложить доставку Изделий. К плохим погодным условиям относятся: дневная температура окружающей среды минус 18 (восемнадцать) градусов по Цельсию и ниже, ветер более 10 м/с, дождь, снег, град, туман. В этом случае срок доставки Изделий по настоящему Договору отодвигается соразмерно сроку продолжительности плохих погодных условий без возникновения какой-либо ответственности у Подрядчика. О смещении сроков доставки Подрядчик уведомляет Заказчика.\n',
                    ],
                    style: 'simpleText',
                    margin: [0, 0, 0, 15],
                },
                {
                    text: '8. ПОРЯДОК РАЗРЕШЕНИЯ СПОРОВ',
                    style: 'title',
                    margin: [0, 0, 0, 15],
                },
                {
                    text: [
                        {text: '8.1. ', bold: true,},
                        'Все споры и разногласия, которые могут возникнуть при исполнении Сторонами настоящего Договора и в связи с ним, Стороны будут пытаться решить посредством переговоров.\n',
                        {text: '8.2. ', bold: true,},
                        'При невозможности разрешения спора по обоюдному согласию путем переговоров, он подлежит передаче в суд по месту нахождения Подрядчика.\n',
                    ],
                    style: 'simpleText',
                    margin: [0, 0, 0, 15],
                },
                {
                    text: '9. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ',
                    style: 'title',
                    margin: [0, 0, 0, 15],
                },
                {
                    text: [
                        {text: '9.1. ', bold: true,},
                        'Настоящий Договор вступает в силу с даты его подписания Сторонами и действует до полного исполнения Сторонами своих обязательств по нему.\n',
                        {text: '9.2. ', bold: true,},
                        'Изменения и дополнения к настоящему Договору будут действительны, если они будут совершены в письменной форме и подписаны обеими Сторонами.\n',
                        {text: '9.3. ', bold: true,},
                        'Стороны вправе по обоюдному соглашению расторгнуть настоящий Договор, определив в таком соглашении дату, порядок и условия расторжения настоящего Договора.\n',
                        {text: '9.4. ', bold: true,},
                        'В случае одностороннего отказа от исполнения Договора (ст. 450.1. ГК РФ), когда такой отказ допускается законом или настоящим Договором, Сторона, отказавшаяся от исполнения Договора, направляет другой Стороне Уведомление об отказе от исполнения Договора в одностороннем порядке путем направления заказным письмом с уведомлением.\n',
                        {text: '9.5. ', bold: true,},
                        'Договор считается расторгнутым по истечении 2 рабочих дней с момента получения Стороной, отказывающейся от исполнения Договора, подтверждения получения другой Стороной Уведомления об одностороннем отказе от исполнения Договора.\n',
                        {text: '9.6. ', bold: true,},
                        'Уведомление Заказчика во всех случаях, предусмотренных Договором, за исключением случаев, предусмотренных п. 9.4. настоящего Договора, производится по телефону или электронной почте, указанным в разделе 10 настоящего Договора. При этом Заказчик:\n' +
                        '- подтверждает, что указанный номер и электронная почта являются достоверными, надежными и незамедлительными способами связи с ним;\n' +
                        '- признает, что надлежащим уведомлением Заказчика по телефону будет считаться передача информации любому лицу, принявшему звонок по указанному номеру телефона.\n' +
                        'В случае если указанный номер телефона и/или электронная почта являются ошибочными или в течение 2 (двух) дней Подрядчик не сможет дозвониться до Заказчика или отправить электронное письмо, то Подрядчик направляет в адрес заказчика уведомление в порядке, указанном в п. 9.4., 9.5. настоящего Договора.\n',
                        {text: '9.7. ', bold: true,},
                        'Стороны обязуются извещать друг друга обо всех изменениях номеров телефонов, почтовых и других адресов, платежных реквизитов, а также обо всех иных изменениях, отсутствие которых может препятствовать Сторонам надлежащим образом исполнять принятые на себя обязательства и осуществлять права по настоящему Договору. При неисполнении указанной обязанности все негативные последствия ложатся на Сторону, не исполнившую обязательство.\n',
                        {text: '9.8. ', bold: true,},
                        'Договор составлен в 2 (двух) экземплярах, имеющих одинаковую юридическую силу, по одному экземпляру для каждой из Сторон.\n',
                        {text: '9.9. ', bold: true,},
                        'Все Приложения к настоящему Договору являются его неотъемлемой частью, если они подписаны Сторонами.\n\n',
                        {text: 'Приложения к настоящему Договору:\n', bold: true,},
                        {text: '1) ', bold: true,},
                        'Приложение № 1 (Спецификация)\n',
                    ],
                    style: 'simpleText',
                    margin: [0, 0, 0, 15],
                },
                {
                    text: '10. ЮРИДИЧЕСКИЕ АДРЕСА, РЕКВИЗИТЫ И ПОДПИСИ СТОРОН',
                    style: 'title',
                    margin: [0, 0, 0, 15],
                },
                {
                    style: 'simpleText',
                    margin: [0, 0, 0, 10],
                    table: {
                        widths: [200, 50, '*'],
                        body: [
                            [
                                {
                                    text: block9_1,
                                    border: [false, false, false, false],
                                },
                                {
                                    text: '',
                                    border: [false, false, false, false],
                                },
                                {
                                    text: [
                                        {text: 'Подрядчик:\n', style: 'title2',},
                                        {text: isCom.ur[1]+' «'+isCom.ur[2]+'»\n', bold:true,},
                                        {text: 'ИНН: '+isCom.ur[4]+'\nКПП: '+isCom.ur[5]+'\nОГРН: '+isCom.ur[17] + '\n',},
                                        {text: 'Адрес: '+isCom.ur[3]+'\nТел.: '+isCom.osn[3]+'\nEmail: '+isCom.osn[4] + '\n',},
                                        {text: 'Банк: '+isCom.ur[6]+'\nБИК: '+isCom.ur[7]+'\nК/С: '+isCom.ur[8]+'\nР/С: '+isCom.ur[9] + '\n',},
                                    ],
                                    border: [false, false, false, false],
                                },
                            ],
                        ],
                    },
                },
                {
                    style: 'simpleText',
                    margin: [0, 0, 0, 10],
                    table: {
                        widths: [200, 50, '*'],
                        body: [
                            [
                                {
                                    text: [
                                        {text: 'Заказчик:\n', style: 'title2',},
                                        block8_1,
                                    ],
                                    border: [false, false, false, false],
                                },
                                {
                                    text: '',
                                    border: [false, false, false, false],
                                },
                                {
                                    text: [
                                        {text: 'Подрядчик:\n', style: 'title2',},
                                        {text: 'Директор '+isCom.ur[1]+' «'+isCom.ur[2]+'»\n', bold:true,},
                                    ],
                                    border: [false, false, false, false],
                                },
                            ],
                        ],
                    },
                },
                {
                    style: 'simpleText',
                    margin: [0, 20, 0, 10],
                    table: {
                        widths: [200, 50, '*'],
                        body: [
                            [
                                {
                                    table: {
                                        widths: [70, 'auto'],
                                        body: [
                                            [
                                                PicSignСustomer2,
                                                {text: '/ '+isLicoShort, border: [false, false, false, false],},
                                            ],
                                        ],
                                    },
                                    border: [false, false, false, false],
                                },
                                {
                                    text: '',
                                    border: [false, false, false, false],
                                },
                                {
                                    table: {
                                        widths: [70, 'auto'],
                                        body: [
                                            [
                                                PicSignDirector2,
                                                {text: '/ '+isCom.ur[18], border: [false, false, false, false],},
                                            ],
                                        ],
                                    },
                                    border: [false, false, false, false],
                                },
                            ],
                        ],
                    },
                },  // signs
                {
                    table: {
                        widths: [250, '*'],
                        body: [
                            [
                                PicClientStamp,
                                PicStamp,
                            ],
                        ],
                    },
                    pageBreak: 'after',
                },  // stamps

                {
                    text: [
                        'ПРИЛОЖЕНИЕ №1 К ДОГОВОРУ №'+Q_('#print-data3').value+'\n',
                        'на изготовление и установку металлоконструкций от '+Q_('#print-data4').value+'г.\n\n',
                        'Спецификация 1 от '+Q_('#print-data4').value+'г.',
                    ],
                    bold: true,
                    alignment: 'right',
                    margin: [0, 0, 0, 15],
                    fontSize: 12,
                },
                block10,
                {
                    style: 'tableHead',
                    margin: [0, 0, 0, 20],
                    table: {
                        headerRows: 1,
                        widths: ['auto', '*', 'auto', 'auto', 'auto', 'auto'],
                        body: [
                            [
                                {text: '№', bold: true, fillColor: '#eee',},
                                {text: 'Товар / Услуга', bold: true, fillColor: '#eee',},
                                {text: 'Кол-во', bold: true, fillColor: '#eee',},
                                {text: 'Ед.', bold: true, fillColor: '#eee',},
                                {text: 'Цена', bold: true, fillColor: '#eee',},
                                {text: 'Сумма', bold: true, fillColor: '#eee',},
                            ],
                            ...isNoms,
                            ...ndsLine,
                        ],
                    },
                },  // table with nomenclature
                {
                    text: 'Общая стоимость товара '+isNDS+' составляет '+Math.floor(isTotal * 1).toLocaleString('ru-RU')+' рубля '+(isTotal * 1).toFixed(2).toString().split('.')[1]+' копеек',
                    style: 'simpleText',
                    margin: [0, 0, 0, 15],
                },
                {
                    text: block11,
                    style: 'simpleText',

                    pageBreak: 'after',
                },

                {
                    style: 'simpleText',
                    margin: [0, 0, 0, 10],
                    table: {
                        widths: [200, 50, '*'],
                        body: [
                            [
                                {
                                    text: block9_2,
                                    border: [false, false, false, false],
                                },
                                {
                                    text: '',
                                    border: [false, false, false, false],
                                },
                                {
                                    text: [
                                        {text: 'Подрядчик:\n', style: 'title2',},
                                        {text: isCom.ur[1]+' «'+isCom.ur[2]+'»\n', bold:true,},
                                        {text: 'ИНН: '+isCom.ur[4]+'\nКПП: '+isCom.ur[5]+'\nОГРН: '+isCom.ur[17] + '\n',},
                                        {text: 'Адрес: '+isCom.ur[3]+'\nТел.: '+isCom.osn[3]+'\nEmail: '+isCom.osn[4] + '\n',},
                                        {text: 'Банк: '+isCom.ur[6]+'\nБИК: '+isCom.ur[7]+'\nК/С: '+isCom.ur[8]+'\nР/С: '+isCom.ur[9] + '\n',},
                                    ],
                                    border: [false, false, false, false],
                                },
                            ],
                        ],
                    },
                },
                {
                    style: 'simpleText',
                    margin: [0, 0, 0, 10],
                    table: {
                        widths: [200, 50, '*'],
                        body: [
                            [
                                {
                                    text: [
                                        {text: 'Заказчик:\n', style: 'title2',},
                                        block8_2,
                                    ],
                                    border: [false, false, false, false],
                                },
                                {
                                    text: '',
                                    border: [false, false, false, false],
                                },
                                {
                                    text: [
                                        {text: 'Подрядчик:\n', style: 'title2',},
                                        {text: 'Директор '+isCom.ur[1]+' «'+isCom.ur[2]+'»\n', bold:true,},
                                    ],
                                    border: [false, false, false, false],
                                },
                            ],
                        ],
                    },
                },
                {
                    style: 'simpleText',
                    margin: [0, 20, 0, 10],
                    table: {
                        widths: [200, 50, '*'],
                        body: [
                            [
                                {
                                    table: {
                                        widths: [70, 'auto'],
                                        body: [
                                            [
                                                PicSignСustomer3,
                                                {text: '/ '+isLicoShort, border: [false, false, false, false],},
                                            ],
                                        ],
                                    },
                                    border: [false, false, false, false],
                                },
                                {
                                    text: '',
                                    border: [false, false, false, false],
                                },
                                {
                                    table: {
                                        widths: [70, 'auto'],
                                        body: [
                                            [
                                                PicSignDirector3,
                                                {text: '/ '+isCom.ur[18], border: [false, false, false, false],},
                                            ],
                                        ],
                                    },
                                    border: [false, false, false, false],
                                },
                            ],
                        ],
                    },
                },  // signs
                {
                    table: {
                        widths: [250, '*'],
                        body: [
                            [
                                PicClientStamp2,
                                PicStamp2,
                            ],
                        ],
                    },
                },                          // stamps

            ],
            footer: function(currentPage, pageCount) {
                return {
                    style: 'footerText',
                    margin: [35, 20, 35, 0],
                    table: {
                        widths: ['auto', 70, 'auto', '*', 'auto', 70, 'auto'],
                        body: [
                            [
                                {text: 'Заказчик', border: [false, false, false, false],},
                                PicSignСustomer,
                                {text: '/ '+isLicoShort, border: [false, false, false, false],},
                                {text: '', border: [false, false, false, false],},
                                {text: 'Подрядчик', border: [false, false, false, false],},
                                PicSignDirector,
                                {text: '/ '+isCom.ur[18], border: [false, false, false, false],},
                            ],
                            [
                                {text: 'стр. ' + currentPage + ' из ' + pageCount, border: [false, false, false, false], colSpan: 7, alignment: 'right',},
                                '',
                                '',
                                '',
                                '',
                                '',
                                '',
                            ],
                        ],
                    },
                    layout: 'lightHorizontalLines',
                }
            },
            styles: {
                tableHead: {
                    fontSize: 9,
                    color: '#000',
                },
                title: {
                    fontSize: 12,
                    bold: true,
                    color: '#000',
                    alignment: 'center',
                },
                title2: {
                    fontSize: 12,
                    bold: true,
                    color: '#000',
                    margin: [0, 0, 0, 10],
                },
                greyText: {
                    fontSize: 8,
                    color: '#777',
                },
                simpleText: {
                    fontSize: 10,
                    color: '#000',
                    lineHeight: 1.2,
                },
                footerText: {
                    fontSize: 8,
                    color: '#000',
                    lineHeight: 1.2,
                },
            },
            pageMargins: [35, 35, 35, 70],
        }

        // create
        pdfMake.createPdf(tmpPDF).open()
        Q_('.popup-wait').style.display = 'none'
    }
    async function PDF_kp(nds, inParams, inBefore, inAfter, comId, inLight){
        Q_('.popup-wait').style.display = 'grid'

        // check summ for ready
        let checker = 1
        Qs_('.page-order-content-wrp .nom').forEach((nm) => {
            if(nm.getAttribute('data-cena') === 'false'){
                checker = 0
            }
        })
        if(checker === 0){
            setTimeout(function () {
                PDF_kp(nds, inParams, inBefore, inAfter, comId, inLight)
            },1000)
            return
        }

        // company and other data
        let isCom = getCompanyByUserID(comId)
        let isTotal = sumTotal

        // light design
        let lightDis = ''
        if(inLight){
            lightDis = 'lightHorizontalLines'
        }

        // noms table lines
        let isNoms = nomTableForDocs(inParams)

        // NDS is need?
        let ndsLine = nomItogForDocs(nds, isTotal)

        // before noms
        let beforeNoms = []
        if(inBefore.replace(/\s+/g, '')){

            // step 1: collect array of needed pics URL`s
            let picsURLs = []
            inBefore.replace(/\s+/g, '').split(',').forEach((block)=>{
                if(printPopKP.pic[block]){
                    picsURLs.push([block, printPopKP.pic[block][1]])
                }
            })

            // step 2: do request for files
            let picsFiles = {}
            let picsWait = await Promise.all(picsURLs.map(async (oneURL) => {
                await fetch(oneURL[1])
                    .then(res => res.blob())
                    .then(blobToBase64)
                    .then(finalResult => {
                        picsFiles[oneURL[0]] = finalResult
                    })
            }))

            // step 3: collect blocks for PDF
            inBefore.replace(/\s+/g, '').split(',').forEach((block)=>{
                if(printPopKP.txt[block]){
                    let tmpText = {
                        text: printPopKP.txt[block][1],
                        margin:[35,0,35,15],
                        color: printPopKP.txt[block][4],
                        fontSize: printPopKP.txt[block][2],
                        bold: printPopKP.txt[block][3],
                        alignment: printPopKP.txt[block][5],
                    }
                    beforeNoms.push(tmpText)
                }else if(printPopKP.pic[block]){
                    let tmpText = {
                        image: picsFiles[block],
                        width: printPopKP.pic[block][5] === true || printPopKP.pic[block][5] == 1 ? 595.35 : printPopKP.pic[block][3] ? printPopKP.pic[block][3]*1 : 525.35,
                        height: printPopKP.pic[block][5] === true || printPopKP.pic[block][5] == 1 ? '' : printPopKP.pic[block][4] ? printPopKP.pic[block][4]*1 : '',
                        margin: printPopKP.pic[block][5] === true || printPopKP.pic[block][5] == 1 ? [0,0,0,15] : [35,0,35,15],
                        alignment: printPopKP.pic[block][3] ? 'center' : '',
                    }
                    beforeNoms.push(tmpText)
                }
            })

        }else{
            beforeNoms = ['']
        }
        // after noms
        let afterNoms = []
        if(inAfter.replace(/\s+/g, '')){

            // step 1: collect array of needed pics URL`s
            let picsURLs2 = []
            inAfter.replace(/\s+/g, '').split(',').forEach((block)=>{
                if(printPopKP.pic[block]){
                    picsURLs2.push([block, printPopKP.pic[block][1]])
                }
            })

            // step 2: do request for files
            let picsFiles2 = {}
            let picsWait2 = await Promise.all(picsURLs2.map(async (oneURL) => {
                await fetch(oneURL[1])
                    .then(res => res.blob())
                    .then(blobToBase64)
                    .then(finalResult => {
                        picsFiles2[oneURL[0]] = finalResult
                    })
            }))

            // step 3: collect blocks for PDF
            inAfter.replace(/\s+/g, '').split(',').forEach((block)=>{
                if(printPopKP.txt[block]){
                    let tmpText = {
                        text: printPopKP.txt[block][1],
                        margin:[35,0,35,15],
                        color: printPopKP.txt[block][4],
                        fontSize: printPopKP.txt[block][2],
                        bold: printPopKP.txt[block][3],
                        alignment: printPopKP.txt[block][5],
                    }
                    afterNoms.push(tmpText)
                }else if(printPopKP.pic[block]){
                    let tmpText = {
                        image: picsFiles2[block],
                        width: printPopKP.pic[block][5] === true || printPopKP.pic[block][5] == 1 ? 595.35 : printPopKP.pic[block][3] ? printPopKP.pic[block][3]*1 : 525.35,
                        height: printPopKP.pic[block][5] === true || printPopKP.pic[block][5] == 1 ? '' : printPopKP.pic[block][4] ? printPopKP.pic[block][4]*1 : '',
                        margin: printPopKP.pic[block][5] === true || printPopKP.pic[block][5] == 1 ? [0,0,0,15] : [35,0,35,15],
                        alignment: printPopKP.pic[block][3] ? 'center' : '',
                    }
                    afterNoms.push(tmpText)
                }
            })

        }else{
            afterNoms = ['']
        }

        // top pic
        let picTop = {text: 'Картинка не загрузилась! Попробуйте ещё раз...',}
        await fetch('https://cabinet-control.zabor-knx.ru/wp-content/themes/kenyx/img/kp_tp.jpg')
            .then(res => res.blob())
            .then(blobToBase64)
            .then(finalResult => {
                picTop = [
                    {
                        image: finalResult,
                        width: 595.35,
                    },
                    {
                        text: isCom.ur[19],
                        margin:[47,-42,0,50],
                        color: '#ffffff',
                        fontSize: 10,
                    }
                ]
            })
        // bottom pic
        let picBottom = {text: 'Картинка не загрузилась! Попробуйте ещё раз...',}
        let phoneBottom = isCom.osn[3]
        if(Lg_('auth-phone') && Lg_('auth-phone') !== 'null'){
            phoneBottom = Lg_('auth-phone')
        }else{
            if(isCom.osn[3].indexOf(',') + 1){
                phoneBottom = isCom.osn[3].split(',')[0]
            }else if(isCom.osn[3].indexOf(';') + 1){
                phoneBottom = isCom.osn[3].split(';')[0]
            }else if(isCom.osn[3].indexOf('+', 5) + 1){
                phoneBottom = '+'+isCom.osn[3].split('+')[1]
            }
        }
        let whatsappBottom = isCom.osn[3]
        if(Lg_('auth-whatsapp') && Lg_('auth-whatsapp') !== 'null'){
            whatsappBottom = Lg_('auth-whatsapp')
        }else{
            if(isCom.osn[3].indexOf(',') + 1){
                whatsappBottom = isCom.osn[3].split(',')[1]
            }else if(isCom.osn[3].indexOf(';') + 1){
                whatsappBottom = isCom.osn[3].split(';')[1]
            }else if(isCom.osn[3].indexOf('+', 5) + 1){
                whatsappBottom = '+'+isCom.osn[3].split('+')[2]
            }
        }
        await fetch('https://cabinet-control.zabor-knx.ru/wp-content/themes/kenyx/img/kp_bt.jpg')
            .then(res => res.blob())
            .then(blobToBase64)
            .then(finalResult => {
                picBottom = [
                    {
                        image: finalResult,
                        width: 595.35,
                        margin:[0,20,0,0],
                    },
                    {
                        margin:[0,-40,0,0],
                        color: '#ffffff',
                        table: {
                            widths: ['*', 128, 120],
                            body: [
                                [
                                    {text: '', fontSize: 10, border:[false, false, false, false],},
                                    {text: 'zabor-knx.ru', bold: true, fontSize: 10, border:[false, false, false, false], noWrap: true,},
                                    {text: phoneBottom, bold: true, fontSize: 10, border:[false, false, false, false], noWrap: true,},
                                ],
                                [
                                    {text: '', fontSize: 10, border:[false, false, false, false],},
                                    {text: isCom.osn[4], bold: true, fontSize: 10, border:[false, false, false, false], noWrap: true,},
                                    {text: whatsappBottom, bold: true, fontSize: 10, border:[false, false, false, false], noWrap: true,},
                                ],
                            ],
                        },
                    },
                ]
            })

        let tmpPDF = {
            pageSize: {
                width: 595.35,
                height: 'auto',
            },
            pageMargins: [0,0,0,0],
            content: [
                picTop,
                {
                    text: 'Коммерческое предложение №'+Q_('#print-data3').value+' от '+Q_('#print-data4').value+'г.',
                    bold: true,
                    alignment: 'center',
                    margin: [0, 0, 0, 15],
                },
                ...beforeNoms,
                {
                    style: 'tableHead',
                    margin: [35, 0, 35, 20],
                    table: {
                        headerRows: 1,
                        widths: ['auto', '*', 'auto', 'auto', 'auto', 'auto'],
                        body: [
                            [
                                {text: '№', bold: true, fillColor: '#eee',},
                                {text: 'Товар / Услуга', bold: true, fillColor: '#eee',},
                                {text: 'Кол-во', bold: true, fillColor: '#eee',},
                                {text: 'Ед.', bold: true, fillColor: '#eee',},
                                {text: 'Цена', bold: true, fillColor: '#eee',},
                                {text: 'Сумма', bold: true, fillColor: '#eee',},
                            ],
                            ...isNoms,
                            ...ndsLine,
                        ],
                    },
                    layout: lightDis,
                },  // table with nomenclature
                ...afterNoms,
                ...picBottom,
            ],
            styles: {
                tableHead: {
                    fontSize: 9,
                },
                title: {
                    fontSize: 15,
                    bold: true,
                    margin: [0, 0, 0, 5],
                },
                greyText: {
                    fontSize: 8,
                    color: '#777',
                },
                simpleText: {
                    fontSize: 9,
                    color: '#000',
                },
            },
        }

        // create
        pdfMake.createPdf(tmpPDF).open()
        Q_('.popup-wait').style.display = 'none'
    }

    return (
        <div className={`page page-order ${order ? order.parent_root ? 'page-suborder' : '' : ''}`}>
            {order
                ? order.id
                    ?   isSysDat.st
                        ?   <div className="page-order-wrp" data-id={order.id}>
                                <div className="popups">
                                    <div className="popup popup-nom">
                                        { nomPopParams.id
                                            ?   <div className="popup-wrp-dad">
                                                    <div className={`popup-wrp popup-nom-wrp ${nomPopParams.type === 'inc' ? 'iclud' : ''}`}>
                                                        {nomPopParams.type === 'nom' || nomPopParams.type === 'inc'
                                                        ?   <div className="popup-nom-tit-wrp">
                                                                <p className="popup-nom-tit">
                                                                    {isSysDat.nomenclature[nomPopParams.id].name} {isLandscape ? <span>-</span> : ''} {
                                                                    isSysDat.nomenclature[nomPopParams.id].ca
                                                                        ?   <span>{isSysDat.cats[isSysDat.nomenclature[nomPopParams.id].ca]}</span>
                                                                        :   ''
                                                                }
                                                                    {nomPopParams.type === 'inc'
                                                                        ?   <strong>Данная номенклатура рассчитывается автоматически, и не редактируется в ручную. <br/>При нужде - удалите её, добавьте такую-же туда-же и тогда уже редактируйте.</strong>
                                                                        :   ''
                                                                    }
                                                                </p>
                                                                <div className="param">
                                                                    <p>Количество</p>
                                                                    <input id="id-popup-nom-kolvo"
                                                                           type="number" defaultValue={nomPopParams.count} min="0"
                                                                           disabled={nomPopParams.type === 'inc' ? true : false}
                                                                           onChange={() => setNomPopChange(!nomPopChange)}
                                                                           autoComplete="off"/>
                                                                </div>
                                                            </div>
                                                        :   <div className="popup-nom-tit-wrp">
                                                                <div className="param">
                                                                    <p>Название</p>
                                                                    <input id="id-popup-nom-name"
                                                                           type="text" defaultValue={nomPopParams.params.name}
                                                                           autoComplete="off"/>
                                                                </div>
                                                                <div className="param">
                                                                    <p>Количество</p>
                                                                    <input id="id-popup-nom-kolvo" min="0"
                                                                           onChange={() => setNomPopChange(!nomPopChange)}
                                                                           type="number" defaultValue={nomPopParams.count}
                                                                           autoComplete="off"/>
                                                                </div>
                                                                <div className="param">
                                                                    <p>Единицы измерения</p>
                                                                    <select id="id-popup-nom-unit" autoComplete="off"
                                                                            defaultValue={nomPopParams.params.unit}
                                                                    >
                                                                        { isSysDat.units.map((el) => (
                                                                            <option value={el.id} key={el.id}>{el.name}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        }
                                                        <div>
                                                            { nomPopParams.type !== 'wrp'
                                                                ?   <div className="popup-nom-data">
                                                                        <hr/>
                                                                        { nomPopParams.type === 'uni'
                                                                            ?   <div className="popup-nom-params">
                                                                                    <div className="param">
                                                                                        <p>Закупочная стоимость (руб)</p>
                                                                                        <input id="id-popup-nom-price1"
                                                                                               type="number" autoComplete="off" defaultValue={nomPopParams.params.price[0]}
                                                                                               onKeyPress={(e) => {if(!pregLine.test(e.key)){e.preventDefault()}}}
                                                                                               min="0" step="1"/>
                                                                                    </div>
                                                                                    <div className="param">
                                                                                        <p>Партнёрская стоимость (руб)</p>
                                                                                        <input id="id-popup-nom-price2"
                                                                                               type="number" autoComplete="off" defaultValue={nomPopParams.params.price[1]}
                                                                                               onKeyPress={(e) => {if(!pregLine.test(e.key)){e.preventDefault()}}}
                                                                                               min="0" step="1"/>
                                                                                    </div>
                                                                                    <div className="param">
                                                                                        <p>Розничная стоимость (руб)</p>
                                                                                        <input id="id-popup-nom-price3"
                                                                                               type="number" autoComplete="off" defaultValue={nomPopParams.params.price[2]}
                                                                                               onKeyPress={(e) => {if(!pregLine.test(e.key)){e.preventDefault()}}}
                                                                                               min="0" step="1"/>
                                                                                    </div>
                                                                                </div>
                                                                            :   <div className="popup-nom-params">
                                                                                    {Object.entries(isSysDat.nomenclature[nomPopParams.id].params).map((el, i) => {

                                                                                        if(el[1].type === 'dig'){
                                                                                            let tmpInputParams = {
                                                                                                'val'  : '0',
                                                                                                'max' : '',
                                                                                                'min' : '0',
                                                                                                'step' : '',
                                                                                            }
                                                                                            let tmpData = el[1].data.replace(/\s+/g, '')
                                                                                            tmpData = tmpData.replace(/,/, '.')
                                                                                            let tmpList = tmpData.split("__")
                                                                                            tmpList.forEach((li) => {
                                                                                                let tmpPar = li.split(":")
                                                                                                tmpInputParams[tmpPar[0]] = tmpPar[1]
                                                                                            })
                                                                                            return(
                                                                                                <div key={i} className={`param ${nomPopLogic[el[0]] ? '' : 'param-hidden'}`}>
                                                                                                    <p>{el[1].name}
                                                                                                        {nomPopParams.type === 'inc'
                                                                                                            ?   ''
                                                                                                            :   tmpInputParams.max || tmpInputParams.min > 0
                                                                                                                ?   <span>{tmpInputParams.min > 0 ? 'min: '+tmpInputParams.min : ''}{tmpInputParams.max ? ', max: '+tmpInputParams.max : ''}</span>
                                                                                                                :   ''
                                                                                                        }
                                                                                                    </p>
                                                                                                    <input type="number" autoComplete="off" className="popup-nom-params-one"
                                                                                                           data-var={el[0].replace(/\s+/g, '')}
                                                                                                           defaultValue={
                                                                                                               nomPopParams.params[el[0].replace(/\s+/g, '')]
                                                                                                                   ?   nomPopParams.params[el[0].replace(/\s+/g, '')]
                                                                                                                   :   tmpInputParams.val
                                                                                                           }
                                                                                                           onKeyPress={(e) => {
                                                                                                               if(!pregLine.test(e.key)){e.preventDefault()}
                                                                                                           }}
                                                                                                           onChange={(e) => {
                                                                                                               if(e.target.value){
                                                                                                                   if(e.target.value*1 < tmpInputParams.min*1){e.target.value = tmpInputParams.min}
                                                                                                                   if(tmpInputParams.max){
                                                                                                                       if(e.target.value*1 > tmpInputParams.max*1){e.target.value = tmpInputParams.max}
                                                                                                                   }
                                                                                                               }
                                                                                                               setNomPopChange(!nomPopChange)
                                                                                                           }}
                                                                                                           min={tmpInputParams.min} max={tmpInputParams.max} step={tmpInputParams.step}
                                                                                                           disabled={nomPopParams.type === 'inc' ? true : false}
                                                                                                    />
                                                                                                </div>
                                                                                            )


                                                                                        }else if(el[1].type === 'sel' || el[1].type === 'per'){
                                                                                            let tmpList = []
                                                                                            if(el[1].type === 'sel'){
                                                                                                tmpList = el[1].data.split("__")
                                                                                            }else{
                                                                                                tmpList = isSysDat.variables[el[1].data.replace(/\s+/g, '')].split("__")
                                                                                            }
                                                                                            return(
                                                                                                <div key={i} className={`param ${nomPopLogic[el[0]] ? '' : 'param-hidden'}`}>
                                                                                                    <p>{el[1].name}</p>
                                                                                                    <select autoComplete="off" className="popup-nom-params-one"
                                                                                                            disabled={nomPopParams.type === 'inc' ? true : false}
                                                                                                            data-var={el[0].replace(/\s+/g, '')}
                                                                                                            defaultValue={nomPopParams.params[el[0].replace(/\s+/g, '')]}
                                                                                                            onChange={() => setNomPopChange(!nomPopChange)}
                                                                                                    >
                                                                                                        { tmpList.map((one, y) => (
                                                                                                            <option key={y+'y'} value={y+1}>{one}</option>
                                                                                                        ))}
                                                                                                    </select>
                                                                                                </div>
                                                                                            )


                                                                                        }else if(el[1].type === 'lin'){
                                                                                            return(
                                                                                                <hr key={i} className={nomPopLogic[el[0]] ? '' : 'param-hidden'} />
                                                                                            )


                                                                                        }else if(el[1].type === 'txt'){
                                                                                            if(el[1].data){
                                                                                                return(
                                                                                                    <a key={i} href={el[1].data} target="_blank"
                                                                                                       className={nomPopLogic[el[0]] ? '' : 'param-hidden'}>
                                                                                                        {el[1].name}
                                                                                                    </a>
                                                                                                )
                                                                                            }else{
                                                                                                return(
                                                                                                    <em key={i} className={nomPopLogic[el[0]] ? '' : 'param-hidden'}>
                                                                                                        {el[1].name}
                                                                                                    </em>
                                                                                                )
                                                                                            }


                                                                                        }else if(el[1].type === 'zag'){
                                                                                            return(
                                                                                                <h5 key={i} className={nomPopLogic[el[0]] ? '' : 'param-hidden'}>{el[1].name}</h5>
                                                                                            )


                                                                                        }else if(el[1].type === 'pic'){
                                                                                            return(
                                                                                                <img key={i} className={`${el[1].data === 'wide' ? 'wide' : ''} ${nomPopLogic[el[0]] ? '' : 'param-hidden'}`}
                                                                                                    src={isSysDat.nomenclature[nomPopParams.id].pics
                                                                                                        ?   Number.isInteger(el[1].name*1)
                                                                                                            ?   isSysDat.nomenclature[nomPopParams.id].pics[el[1].name*1-1]
                                                                                                            :   ''
                                                                                                        :   ''
                                                                                                    }
                                                                                                />
                                                                                            )
                                                                                        }

                                                                                    })}
                                                                                </div>
                                                                        }
                                                                    </div>
                                                                :   ''
                                                            }
                                                        </div>
                                                        <div className={`popup-nom-close ${!sumReady ? 'hideWhileCalc' : ''}`} onClick={() => {
                                                            // reset popup resize observe
                                                            NomResizeObserver.disconnect()
                                                            setPopFullNom(false)

                                                            // close, save and reset popup data
                                                            if(nomPopParams.type === 'inc'){
                                                                Q_('.popup-nom').style.display = 'none'
                                                                setNomPopParams({})
                                                            }else{
                                                                saveNomenclature()
                                                                Q_('.popup-nom').style.display = 'none'
                                                                setTimeout(function () {
                                                                    setNomPopParams({})
                                                                },300)
                                                            }
                                                        }}>
                                                            { nomPopParams.type === 'inc'
                                                                ?   'Закрыть'
                                                                :   'Применить'
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="popup-close" onClick={() => {
                                                        // reset popup resize observe
                                                        NomResizeObserver.disconnect()
                                                        setPopFullNom(false)

                                                        // close and reset popup data
                                                        Q_('.popup-nom').style.display = 'none'
                                                        setNomPopParams({})
                                                    }}>×</div>
                                                </div>
                                            :   ''
                                        }
                                    </div>
                                    <div className="popup popup-newprice">
                                        { pricePopData.count
                                            ?   <div className="popup-wrp-dad">
                                                    <div className="popup-wrp popup-newprice-wrp">
                                                        <p className="popup-tit">Указать цену отличную от расчётной</p>
                                                        <span>Чтобы вернуть расчётную цену - сотрите значения и примените.</span>
                                                        <div className="popup-newprice-table">
                                                            <div className="param">
                                                                <p>Цена за единицу</p>
                                                                <input id="newPrice_1" type="number" autoComplete="off"
                                                                       defaultValue={pricePopData.newsumm >= 0
                                                                           ?    parseFloat((pricePopData.newsumm / pricePopData.count).toFixed(2))
                                                                           :    ''
                                                                       }
                                                                       placeholder={parseFloat((pricePopData.cena*1).toFixed(2))}
                                                                       onKeyPress={(e) => {
                                                                           if(!pregLine.test(e.key)){
                                                                               e.preventDefault()
                                                                           }
                                                                       }}
                                                                       onChange={(e) => {
                                                                           if(e.target.value){
                                                                               Q_('#newPrice_2').value = parseFloat((e.target.value * pricePopData.count).toFixed(2))
                                                                           }else{
                                                                               Q_('#newPrice_2').value = ''
                                                                           }
                                                                       }}
                                                                />
                                                                <span>Расчётная: {parseFloat((pricePopData.cena*1).toFixed(2))} р.</span>
                                                            </div>
                                                            <div className="param">
                                                                <p>Сумма {
                                                                    pricePopData.count != 1
                                                                        ? '(за все '+parseFloat((pricePopData.count*1).toFixed(2))+')'
                                                                        : ''
                                                                }</p>
                                                                <input id="newPrice_2" type="number" autoComplete="off"
                                                                       defaultValue={ pricePopData.newsumm >= 0
                                                                           ?    pricePopData.newsumm
                                                                           :    ''
                                                                       }
                                                                       placeholder={parseFloat((pricePopData.summ*1).toFixed(2))}
                                                                       onKeyPress={(e) => {
                                                                           if(!pregLine.test(e.key)){
                                                                               e.preventDefault()
                                                                           }
                                                                       }}
                                                                       onChange={(e) => {
                                                                           if(e.target.value){
                                                                               if(e.target.value == 0){
                                                                                   Q_('#newPrice_1').value = 0
                                                                               }else{
                                                                                   Q_('#newPrice_1').value = parseFloat((e.target.value / pricePopData.count).toFixed(2))
                                                                               }
                                                                           }else{
                                                                               Q_('#newPrice_1').value = ''
                                                                           }
                                                                       }}
                                                                />
                                                                <span>Расчётная: {parseFloat((pricePopData.summ*1).toFixed(2))} р.</span>
                                                            </div>
                                                        </div>
                                                        <div className={`popup-newprice-close ${!sumReady ? 'hideWhileCalc' : ''}`} onClick={() => {
                                                            setChanges(false)
                                                            Q_('.popup-newprice').style.display = 'none'
                                                            let tmpContent = JSON.parse(JSON.stringify(isContent))

                                                            let tmpPrice2 = -1
                                                            if(Q_('#newPrice_1').value){
                                                                tmpPrice2 = Q_('#newPrice_1').value*1
                                                            }

                                                            // way in content to element for saving
                                                            let contentWay
                                                            if(pricePopData.papa){
                                                                let tmpIndex1 = isContent.findIndex(el => el.pos === pricePopData.papa)
                                                                let tmpIndex2 = tmpContent[tmpIndex1].in.findIndex(ell => ell.pos === pricePopData.self.replace('__',''))
                                                                contentWay = tmpContent[tmpIndex1].in[tmpIndex2]
                                                            }else{
                                                                contentWay = tmpContent[isContent.findIndex(el => el.pos === pricePopData.self.replace('__',''))]
                                                            }

                                                            if(pricePopData.mod && pricePopData.mod.length){
                                                                if(!contentWay['mod']){
                                                                    contentWay['mod'] = {}
                                                                }
                                                                let assignedMod = structuredClone(contentWay['mod'])
                                                                let tmpWay
                                                                pricePopData.mod.reverse().forEach((el, z) => {
                                                                    if(z === 0){
                                                                        if(!assignedMod[el]){
                                                                            assignedMod[el] = {}
                                                                        }
                                                                        tmpWay = assignedMod[el]
                                                                    }else{
                                                                        if(!tmpWay[el]){
                                                                            tmpWay[el] = {}
                                                                        }
                                                                        tmpWay = tmpWay[el]
                                                                    }

                                                                    if(z === pricePopData.mod.length - 1){
                                                                        tmpWay['fixcost'] = [tmpPrice2, 'one']
                                                                    }
                                                                })
                                                                contentWay['mod'] = assignedMod
                                                            }else{
                                                                contentWay.fixcost = [tmpPrice2, 'one']
                                                            }

                                                            setIsContent(tmpContent)
                                                            setTimeout(function () {
                                                                setPricePopData({
                                                                    cena : -1,
                                                                    summ : -1,
                                                                    newsumm : -1,
                                                                    count : 0,
                                                                    self : '',
                                                                    papa : '',
                                                                })
                                                                setChanges(true)
                                                            },50)
                                                        }}>Применить</div>
                                                    </div>
                                                    <div className="popup-close" onClick={() => {
                                                    Q_('.popup-newprice').style.display = 'none'
                                                    setPricePopData({
                                                        cena : -1,
                                                        summ : -1,
                                                        newsumm : -1,
                                                        count : 0,
                                                        self : '',
                                                        papa : '',
                                                    })
                                                }}>×</div>
                                                </div>
                                            :   ''
                                        }
                                    </div>
                                    <div className={`popup popup-printer ${printPopDoc === '4' ? 'popup-printer-kp' : ''}`}>
                                        <div className="popup-wrp-dad">
                                            <div className="popup-wrp popup-printer-wrp">
                                                <p className="popup-tit">Коструктор документов</p>
                                                <div className="popup-printer-wrp-main">
                                                    <div className="param">
                                                        <p>Какой документ вам нужен</p>
                                                        <select autoComplete="off" id="print-doc" defaultValue={printPopDoc}
                                                                onChange={(e)=>{
                                                                    setPrintPopDoc(e.target.value)
                                                                    setPrintPopKP_set('1')
                                                                }}
                                                        >
                                                            {printPopKP.txt
                                                                ?   <option value="4">Коммерческое предложение</option>
                                                                :   ''
                                                            }
                                                            <option value="2">Договор подряда (материалы + монтаж)</option>
                                                            <option value="3">Договор поставки (материалы)</option>
                                                            <option value="1">Счёт на оплату</option>
                                                        </select>
                                                    </div>
                                                    {printPopDoc === '4'
                                                        ?   <div className={`popup-printer-wrp-builder ${!printPopKP_builder ? 'active' : ''}`}
                                                                 onClick={()=>{
                                                                     Q_('.popup-printer-kp-save').style.display = 'block'
                                                                     Q_('.popup-printer-kp-save').classList.remove('active')
                                                                     setPrintPopKP_builder(!printPopKP_builder)
                                                                 }}
                                                                 data-tooltip="Редактор КП"
                                                            ><IcoGear/></div>
                                                        :   ''
                                                    }
                                                </div>
                                                <div className={`popup-printer-checks popup-printer-twocol ${printPopDoc === '4' && printPopKP_builder ? 'hideWhile' : ''}`}>
                                                    <div className="param">
                                                        <input type="checkbox" name="nds-need" id="nds-need" autoComplete="off"/>
                                                        <label htmlFor="nds-need">Расчёты с учётом НДС</label>
                                                    </div>
                                                    {printPopDoc === '2' || printPopDoc === '3' || printPopDoc === '1'
                                                        ?   <div className="param">
                                                                <input type="checkbox" name="sign-need" id="sign-need" autoComplete="off"/>
                                                                <label htmlFor="sign-need">Подписи и печати</label>
                                                            </div>
                                                        :   ''
                                                    }
                                                    {printPopDoc === '4'
                                                        ?   <div className="param">
                                                                <input type="checkbox" name="params-need" id="params-need" autoComplete="off" defaultChecked={true}/>
                                                                <label htmlFor="params-need">Параметры номенклатур</label>
                                                            </div>
                                                        :   ''
                                                    }
                                                    {printPopDoc === '4'
                                                        ?   <div className="param">
                                                                <input type="checkbox" name="light-need" id="light-need" autoComplete="off"/>
                                                                <label htmlFor="light-need">Лёгкий дизайн таблицы</label>
                                                            </div>
                                                        :   ''
                                                    }
                                                </div>
                                                {printPopDoc === '4' // sets of KP
                                                    ?   <div className={`param ${!printPopKP_builder ? 'hideWhile' : ''}`}>
                                                            <p>Набор оформления КП</p>
                                                            <div className="popup-printer-kp-select">
                                                                <select autoComplete="off" onChange={(e)=>{
                                                                    let tmpSet = ['', '', '', true, false, true]
                                                                    if(e.target.value !== '1'){
                                                                        tmpSet = printPopKP.set[e.target.value]
                                                                        tmpSet[1] = tmpSet[1].replace(/\s+/g, '')
                                                                        tmpSet[2] = tmpSet[2].replace(/\s+/g, '')
                                                                    }

                                                                    // set fields from this SET
                                                                    Q_('#print-data9-1').value = tmpSet[1]
                                                                    Q_('#print-data10-1').value = tmpSet[2]
                                                                    Q_('#nds-need').checked = tmpSet[3]
                                                                    Q_('#light-need').checked = tmpSet[4]
                                                                    Q_('#params-need').checked = tmpSet[5]

                                                                    // reset ACTIVE class for all elements
                                                                    Qs_('.popup-printer-wrp .active').forEach((elm)=>{
                                                                        elm.classList.remove('active')
                                                                    })

                                                                    // set ACTIVE class for selected elements
                                                                    Qs_('#print-data9 .kp-txt').forEach((elm)=>{
                                                                        tmpSet[1].split(',').forEach((set)=>{
                                                                            if(elm.getAttribute('data-val') == set){
                                                                                elm.classList.add('active')
                                                                            }
                                                                        })
                                                                    })
                                                                    Qs_('#print-data10 .kp-txt').forEach((elm)=>{
                                                                        tmpSet[2].split(',').forEach((set)=>{
                                                                            if(elm.getAttribute('data-val') == set){
                                                                                elm.classList.add('active')
                                                                            }
                                                                        })
                                                                    })
                                                                    Qs_('#print-data9-2 .kp-pic').forEach((elm)=>{
                                                                        tmpSet[1].split(',').forEach((set)=>{
                                                                            if(elm.getAttribute('data-val') == set){
                                                                                elm.classList.add('active')
                                                                            }
                                                                        })
                                                                    })
                                                                    Qs_('#print-data10-2 .kp-pic').forEach((elm)=>{
                                                                        tmpSet[2].split(',').forEach((set)=>{
                                                                            if(elm.getAttribute('data-val') == set){
                                                                                elm.classList.add('active')
                                                                            }
                                                                        })
                                                                    })

                                                                    // full state for preview
                                                                    let tmpKP_pre = JSON.parse(JSON.stringify(printPopKP_pre))
                                                                    tmpKP_pre.top = tmpSet[1].split(',')
                                                                    tmpKP_pre.bottom = tmpSet[2].split(',')
                                                                    setPrintPopKP_pre(tmpKP_pre)

                                                                    // set state
                                                                    setPrintPopKP_set(e.target.value)
                                                                }}
                                                                        defaultValue={printPopKP_set}
                                                                >
                                                                    <option value="1">КП без текстов и картинок</option>
                                                                    {Object.entries(printPopKP.set).sort(function(a, b){return b[0]-a[0]}).map((el)=>(
                                                                        <option value={el[0]} key={el[0]}>{el[0]}: {el[1][0]}</option>
                                                                    ))}
                                                                </select>
                                                                {printPopKP_set != '1'
                                                                    ?   <div className="popup-printer-kp-delete" data-tooltip="Удалить набор"
                                                                             onClick={()=>{
                                                                                 let tmpVal = Q_('.popup-printer-kp-select > select').value
                                                                                 if(tmpVal != '1'){
                                                                                     deleteKPset(tmpVal)
                                                                                 }
                                                                             }}
                                                                    >×</div>
                                                                    :   ''
                                                                }
                                                            </div>
                                                            <span className="popup-printer-kp-select-sp">Хочешь собрать КП сам? Перейди в редактор нажав шестерёнку.</span>
                                                        </div>
                                                    :   ''
                                                }
                                                {printPopDoc === '4' // top sector of KP builder
                                                    ?   <div className={printPopKP_builder ? 'hideWhile' : ''}>
                                                            <div className="build-tit">{isLandscape ? 'Выбери тексты и картинки' : 'Блоки'} для показа ДО цен</div>
                                                            <div className="build-twocol">
                                                                <div id="print-data9">
                                                                    {Object.entries(printPopKP.txt).sort(function(a, b){return b[0]-a[0]}).map((el)=>(
                                                                        <div className="kp-txt" data-val={el[0]} key={el[0]}
                                                                             onClick={(e)=>kpLineConstructor(e.target, '#print-data9-1', 'top')}
                                                                        >
                                                                            - {el[1][0]}
                                                                            <div className="kp-txt-edit"
                                                                                 onClick={(e)=>{
                                                                                     e.preventDefault()
                                                                                     e.stopPropagation()
                                                                                     setPrintPopKP_txt([el[0], printPopKP.txt[el[0]]])
                                                                                     Q_('.popup-kp-text').style.display = 'grid'
                                                                                 }}
                                                                            ><IcoGear/></div>
                                                                        </div>
                                                                    ))}
                                                                    <div className="build-add build-add-txt"
                                                                         onClick={()=>{
                                                                             setPrintPopKP_txt(['new',['','','','','','','','','','','']])
                                                                             Q_('.popup-kp-text').style.display = 'grid'
                                                                         }}>Добавить текст</div>
                                                                </div>
                                                                <div id="print-data9-2">
                                                                    {Object.entries(printPopKP.pic).sort(function(a, b){return b[0]-a[0]}).map((el)=>(
                                                                        <div className="kp-pic-wrp" key={el[0]}>
                                                                            <img className="kp-pic" data-val={el[0]} src={el[1][2]} title={el[1][0]}
                                                                                 onClick={(e)=>kpLineConstructor(e.target, '#print-data9-1', 'top')}
                                                                            />
                                                                            <div className="kp-pic-edit"
                                                                                 onClick={(e)=>{
                                                                                     e.preventDefault()
                                                                                     e.stopPropagation()
                                                                                     setPrintPopKP_pic([el[0], printPopKP.pic[el[0]]])
                                                                                     Q_('.popup-kp-pic').style.display = 'grid'
                                                                                 }}
                                                                            ><IcoGear/></div>
                                                                        </div>
                                                                    ))}
                                                                    <div className="build-add build-add-pic"
                                                                         onClick={()=>{
                                                                             setPrintPopKP_pic(['new',['','','','','','','','','','','']])
                                                                             Q_('.popup-kp-pic').style.display = 'grid'
                                                                         }}>Добавить картинку</div>
                                                                </div>
                                                            </div>
                                                            <input type="text" autoComplete="off" id="print-data9-1" placeholder="или введи ID-и списком через запятую"
                                                                   onChange={(e)=>kpLineToState(e.target, 'top')}
                                                            />
                                                        </div>
                                                    :   ''
                                                }
                                                {printPopDoc === '4' // bottom sector of KP builder
                                                    ?   <div className={printPopKP_builder ? 'hideWhile' : ''}>
                                                            <div className="build-tit">{isLandscape ? 'Выбери тексты и картинки' : 'Блоки'} для показа ПОСЛЕ цен</div>
                                                            <div className="build-twocol">
                                                                <div id="print-data10">
                                                                    {Object.entries(printPopKP.txt).sort(function(a, b){return b[0]-a[0]}).map((el)=>(
                                                                        <div className="kp-txt" data-val={el[0]} key={el[0]}
                                                                             onClick={(e)=>kpLineConstructor(e.target, '#print-data10-1', 'bottom')}
                                                                        >
                                                                            - {el[1][0]}
                                                                            <div className="kp-txt-edit" data-val={el[0]}
                                                                                 onClick={(e)=>{
                                                                                     e.preventDefault()
                                                                                     e.stopPropagation()
                                                                                     setPrintPopKP_txt([el[0], printPopKP.txt[el[0]]])
                                                                                     Q_('.popup-kp-text').style.display = 'grid'
                                                                                 }}
                                                                            ><IcoGear/></div>
                                                                        </div>
                                                                    ))}
                                                                    <div className="build-add build-add-txt"
                                                                         onClick={()=>{
                                                                             setPrintPopKP_txt(['new',['','','','','','','','','','','']])
                                                                             Q_('.popup-kp-text').style.display = 'grid'
                                                                         }}>Добавить текст</div>
                                                                </div>
                                                                <div id="print-data10-2">
                                                                    {Object.entries(printPopKP.pic).sort(function(a, b){return b[0]-a[0]}).map((el)=>(
                                                                        <div className="kp-pic-wrp" key={el[0]}>
                                                                            <img className="kp-pic" data-val={el[0]} src={el[1][2]} title={el[1][0]}
                                                                                 onClick={(e)=>kpLineConstructor(e.target, '#print-data10-1', 'bottom')}
                                                                            />
                                                                            <div className="kp-pic-edit"
                                                                                 onClick={(e)=>{
                                                                                     e.preventDefault()
                                                                                     e.stopPropagation()
                                                                                     setPrintPopKP_pic([el[0], printPopKP.pic[el[0]]])
                                                                                     Q_('.popup-kp-pic').style.display = 'grid'
                                                                                 }}
                                                                            ><IcoGear/></div>
                                                                        </div>
                                                                    ))}
                                                                    <div className="build-add build-add-pic"
                                                                         onClick={()=>{
                                                                             setPrintPopKP_pic(['new',['','','','','','','','','','','']])
                                                                             Q_('.popup-kp-pic').style.display = 'grid'
                                                                         }}>Добавить картинку</div>
                                                                </div>
                                                            </div>
                                                            <input type="text" autoComplete="off" id="print-data10-1" placeholder="или введи ID-и списком через запятую"
                                                                   onChange={(e)=>kpLineToState(e.target, 'bottom')}
                                                            />
                                                        </div>
                                                    :   ''
                                                }
                                                {printPopDoc === '4' // save set of KP
                                                    ?   <div className={`popup-printer-kp-save ${printPopKP_builder ? 'hideWhile' : ''}`}>
                                                            <strong onClick={()=>{
                                                                Q_('.popup-printer-kp-save').classList.add('active')
                                                            }}>Сохранить созданый вами набор?</strong>
                                                            <div className="popup-printer-kp-save-box">
                                                                <div className="param">
                                                                    <p>Название</p>
                                                                    <input type="text" id="kp_set_name" autoComplete="off" placeholder="Придумайте название"/>
                                                                </div>
                                                                <div className="popup-printer-kp-save-btn" onClick={() => kpSetSave()}>Сохранить</div>
                                                            </div>
                                                        </div>
                                                    :   ''
                                                }
                                                <div  className="param" style={{display:"none"}}>
                                                    <p>Какой формат файла?</p>
                                                    <select autoComplete="off" disabled={true}>
                                                        <option value="pdf">PDF</option>
                                                    </select>
                                                </div>
                                                {printPopDoc === '2' || printPopDoc === '3' || printPopDoc === '1' // client requisites
                                                    ?   <div className="param">
                                                            <p>Лицо со стороны {order.parent_root ? 'заказчика' : 'клиента'}</p>
                                                            <select autoComplete="off" id="print-client">
                                                                <option value="fiz">Физ.лицо</option>
                                                                <option value="ur">Юр.лицо</option>
                                                            </select>
                                                            <span>{isSysDat.companies[curClient ? curClient : order.client ? order.client : 3059].nm === 'Не выбран'
                                                                ?   <span className="red">Не известны реквизиты {order.parent_root ? 'заказчика из этого подзаказа' : 'клиента из этого заказа'}! Выберите кого-то из имеющихся или создайте нового.</span>
                                                                :   `Подсказка: В этом ${order.parent_root ? 'подзаказе, заказчиком' : 'заказе, клиентом'} указан "${isSysDat.companies[curClient ? curClient : order.client ? order.client : 3059].nm}".`
                                                            }</span>
                                                        </div>
                                                    :   ''
                                                }
                                                <div className="param">
                                                    <p>Реквизиты со стороны исполнителя</p>
                                                    <select autoComplete="off" id="print-company" defaultValue={curIspo ? curIspo : order.ispo ? order.ispo : getCompanyByUserID().id}>
                                                        {Object.entries(isSysDat.companies).filter(el => el[1].osn[1] === true).map((co)=>(
                                                            <option value={co[1].id} key={co[1].id}>
                                                                {co[1].nm + ' (' + co[1].ur[1] + ' «' + co[1].ur[2] + '» г.' + isSysDat.cities[co[1].osn[2]] + ')'}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <span>{isSysDat.companies[curIspo ? curIspo : order.ispo ? order.ispo : 3059].nm === 'Не выбран'
                                                        ?   <span className="red">Не известны реквизиты исполнителя из этого {order.parent_root ? 'под' : ''}заказа! Выберите кого-то из имеющихся или создайте нового.</span>
                                                        :   `Подсказка: В этом ${order.parent_root ? 'под' : ''}заказе исполнителем указан "${isSysDat.companies[curIspo ? curIspo : order.ispo ? order.ispo : 3059].nm}".`
                                                    }</span>
                                                    <span>Подсказка: Ваш аккаунт привязан к "{getCompanyByUserID().nm}".</span>
                                                </div>
                                                <div className="popup-printer-twocol">
                                                    <div className="param">
                                                        <p>Номер документа</p>
                                                        <input type="text" autoComplete="off" id="print-data3" defaultValue={order.id}/>
                                                    </div>
                                                    <div className="param">
                                                        <p>Дата для документа</p>
                                                        <input type="text" autoComplete="off" id="print-data4" defaultValue={order.created}/>
                                                    </div>
                                                </div>
                                                {printPopDoc === '2' || printPopDoc === '3'
                                                    ?   <div className="popup-printer-twocol">
                                                        <div className="param">
                                                            <p>Дней на изготовление</p>
                                                            <input type="text" autoComplete="off" id="print-data1" defaultValue="1"/>
                                                        </div>
                                                        <div className="param">
                                                            <p>{`Дней на доставку${printPopDoc === '2' ? ' и монтаж' : ''}`}</p>
                                                            <input type="text" autoComplete="off" id="print-data2" defaultValue="1"/>
                                                        </div>
                                                    </div>
                                                    :   ''
                                                }
                                                {printPopDoc === '2' || printPopDoc === '3'
                                                    ?   <fieldset className="popup-printer-threecol">
                                                        <div className="param">
                                                            <input type="radio" id="print-data8-1" name="print-data8" value="1"
                                                                   defaultChecked={printPop2Pay === '1' ? true : false}
                                                                   onChange={(e)=>{
                                                                       setPrintPop2Pay(e.target.value)
                                                                   }}
                                                            />
                                                            <label htmlFor="print-data8-1">1 платёж</label>
                                                        </div>
                                                        <div className="param">
                                                            <input type="radio" id="print-data8-2" name="print-data8" value="2"
                                                                   defaultChecked={printPop2Pay === '2' ? true : false}
                                                                   onChange={(e)=>{
                                                                       setPrintPop2Pay(e.target.value)
                                                                   }}
                                                            />
                                                            <label htmlFor="print-data8-2">2 платёжа</label>
                                                        </div>
                                                        <div className="param">
                                                            <input type="radio" id="print-data8-3" name="print-data8" value="3"
                                                                   defaultChecked={printPop2Pay === '3' ? true : false}
                                                                   onChange={(e)=>{
                                                                       setPrintPop2Pay(e.target.value)
                                                                   }}
                                                            />
                                                            <label htmlFor="print-data8-3">3 платёжа</label>
                                                        </div>
                                                    </fieldset>
                                                    :   ''
                                                }
                                                {printPopDoc === '2' || printPopDoc === '3'
                                                    ?   <div className={`popup-printer-paycount ${printPop2Pay === '2' ? 'popup-printer-twocol' : 'popup-printer-threecol'}`}
                                                             style={printPop2Pay === '1' ? {display: 'none'} : {}}
                                                        >
                                                            <div className="param">
                                                                <p>1 платеж (руб)</p>
                                                                <input type="number" autoComplete="off" id="print-data5" defaultValue="0"/>
                                                                <span>Итоговая сумма: {parseFloat(sumTotal.toFixed(2)).toLocaleString('ru-RU')} руб.</span>
                                                            </div>
                                                            <div className="param">
                                                                <p>2 платеж (руб)</p>
                                                                <input type="number" autoComplete="off" id="print-data6" defaultValue="0"/>
                                                            </div>
                                                            <div className="param" style={printPop2Pay === '2' ? {display: 'none'} : {}}>
                                                                <p>3 платеж (руб)</p>
                                                                <input type="number" autoComplete="off" id="print-data7" defaultValue="0"/>
                                                            </div>
                                                        </div>
                                                    :   ''
                                                }
                                                <div className="popup-printer-close" onClick={() => {
                                                    Q_('.popup-printer').style.display = 'none'
                                                    let tmpCom, tmpNds, tmpSign, tmpLico, tmpParams, tmpLight

                                                    tmpNds = false
                                                    if(Q_('#nds-need').checked){
                                                        tmpNds = true
                                                    }

                                                    tmpCom = Q_('#print-company').value;

                                                    if(printPopDoc === '1' || printPopDoc === '2' || printPopDoc === '3'){
                                                        tmpSign = false
                                                        if(Q_('#sign-need').checked){
                                                            tmpSign = true
                                                        }

                                                        tmpLico = Q_('#print-client').value
                                                    }else if(printPopDoc === '4'){
                                                        tmpParams = false
                                                        if(Q_('#params-need').checked){
                                                            tmpParams = true
                                                        }

                                                        tmpLight = false
                                                        if(Q_('#light-need').checked){
                                                            tmpLight = true
                                                        }
                                                    }

                                                    if(printPopDoc === '1'){
                                                        PDF_bill(tmpCom, tmpNds, tmpSign, tmpLico)
                                                    }else if(printPopDoc === '2'){
                                                        PDF_dogovor_1(tmpCom, tmpNds, tmpSign, tmpLico)
                                                    }else if(printPopDoc === '3'){
                                                        PDF_dogovor_2(tmpCom, tmpNds, tmpSign, tmpLico)
                                                    }else if(printPopDoc === '4'){
                                                        PDF_kp(
                                                            tmpNds,
                                                            tmpParams,
                                                            Q_('#print-data9-1').value,
                                                            Q_('#print-data10-1').value,
                                                            tmpCom,
                                                            tmpLight,
                                                        )
                                                    }

                                                    // reset popup resize observe
                                                    PrinterResizeObserver.disconnect()
                                                    setPopFullPrinter(false)
                                                }}>Создать документ</div>
                                            </div>
                                            <div className="popup-close" onClick={() => {
                                                setPrintPopKP_set('1')
                                                Q_('.popup-printer').style.display = 'none'

                                                // reset popup resize observe
                                                PrinterResizeObserver.disconnect()
                                                setPopFullPrinter(false)
                                            }}>×</div>
                                        </div>
                                        <div className="popup-printer-preview">
                                            <div className="popup-printer-preview-head">Шапка</div>
                                            <div className="popup-printer-preview-subhead">Коммерческое предложение</div>
                                            <div className="popup-printer-preview-content">
                                                {printPopKP_pre.top.map((blo, i)=>(
                                                    <div key={i}>
                                                        {printPopKP.txt[blo]
                                                            ?   <p style={{
                                                                    color: printPopKP.txt[blo][4],
                                                                    textAlign: printPopKP.txt[blo][5],
                                                                    fontSize: printPopKP.txt[blo][2]/19+'vw',
                                                                }}>{printPopKP.txt[blo][1]}</p>
                                                            :   printPopKP.pic[blo]
                                                                ?   <img src={printPopKP.pic[blo][2]} alt="" style={{
                                                                        margin: printPopKP.pic[blo][5] === true || printPopKP.pic[blo][5] == 1 ? '0 -1.5vw' : '0',
                                                                        width: printPopKP.pic[blo][5] === true || printPopKP.pic[blo][5] == 1 ? '30vw' : printPopKP.pic[blo][3] ? printPopKP.pic[blo][3]/19+'vw' : '100%',
                                                                        maxHeight: printPopKP.pic[blo][5] === true || printPopKP.pic[blo][5] == 1 ? 'unset' : printPopKP.pic[blo][4] ? printPopKP.pic[blo][4]/19+'vw' : 'unset',
                                                                    }}/>
                                                                :   ''
                                                        }
                                                    </div>
                                                ))}
                                            </div>
                                            <div className="popup-printer-preview-table">Таблица</div>
                                            <div className="popup-printer-preview-content">
                                                {printPopKP_pre.bottom.map((blo, i)=>(
                                                    <div key={i}>
                                                        {printPopKP.txt[blo]
                                                            ?   <p style={{
                                                                color: printPopKP.txt[blo][4],
                                                                textAlign: printPopKP.txt[blo][5],
                                                                fontSize: printPopKP.txt[blo][2]/19+'vw',
                                                            }}>{printPopKP.txt[blo][1]}</p>
                                                            :   printPopKP.pic[blo]
                                                                ?   <img src={printPopKP.pic[blo][2]} alt="" style={{
                                                                    margin: printPopKP.pic[blo][5] === true || printPopKP.pic[blo][5] == 1 ? '0 -1.5vw' : '0',
                                                                    width: printPopKP.pic[blo][5] === true || printPopKP.pic[blo][5] == 1 ? '30vw' : printPopKP.pic[blo][3] ? printPopKP.pic[blo][3]/19+'vw' : '100%',
                                                                    maxHeight: printPopKP.pic[blo][5] === true || printPopKP.pic[blo][5] == 1 ? 'unset' : printPopKP.pic[blo][4] ? printPopKP.pic[blo][4]/19+'vw' : '12vw',
                                                                }}/>
                                                                :   ''
                                                        }
                                                    </div>
                                                ))}
                                            </div>
                                            <div className="popup-printer-preview-footer">Подвал</div>
                                        </div>
                                        <div className="popup-printer-preview-tit">Предпросмотр</div>
                                    </div>
                                    <div className="popup popup-kp-text">
                                        {printPopKP_txt[0]
                                            ?   <div className="popup-wrp-dad">
                                                    <div className="popup-wrp popup-kp-text-wrp">
                                                        <div>
                                                            <div className="param">
                                                                <p>Название текстового блока для КП:</p>
                                                                <input type="text" name="title" autoComplete="off" defaultValue={printPopKP_txt[1][0]}/>
                                                            </div>
                                                            <div className="param">
                                                                <p>Содержимое:</p>
                                                                <textarea  name="kp_txt" rows="10" autoComplete="off" defaultValue={printPopKP_txt[1][1]}/>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className="param">
                                                                <p>Размер шрифта:</p>
                                                                <input type="number" name="kp_size" autoComplete="off" placeholder="10"
                                                                       defaultValue={printPopKP_txt[1][2]}/>
                                                            </div>
                                                            <div className="param">
                                                                <p>Жирность шрифта:</p>
                                                                <select autoComplete="off" name="kp_bold" defaultValue={
                                                                    printPopKP_txt[1][3] ? '1' : '0'
                                                                }>
                                                                    <option value="0">Стандартный</option>
                                                                    <option value="1">Жирный</option>
                                                                </select>
                                                            </div>
                                                            <div className="param">
                                                                <p>Цвет текста:</p>
                                                                <input type="color" name="kp_col" autoComplete="off"
                                                                       defaultValue={printPopKP_txt[1][4]}/>
                                                            </div>
                                                            <div className="param">
                                                                <p>Прижать текст:</p>
                                                                <select autoComplete="off" name="kp_align" defaultValue={printPopKP_txt[1][5]}>
                                                                    <option value="left">Влево</option>
                                                                    <option value="center">По центру</option>
                                                                    <option value="right">Вправо</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="popup-kp-text-bottom">
                                                            {printPopKP_txt[0] !== 'new'
                                                                ?   <div className="popup-kp-text-delete" onClick={()=>{
                                                                    if(Lg_('auth-id') == 5){
                                                                        openMessage() // if guest account
                                                                    }else{
                                                                        Q_('.popup-kp-text').style.display = 'none'
                                                                        deleteKPtxt(printPopKP_txt[0])
                                                                        setPrintPopKP_txt(['',['','','','','','','','','','','']])
                                                                    }
                                                                }}>Удалить</div>
                                                                :   ''
                                                            }
                                                            <div className="popup-kp-text-close"
                                                                 onClick={(e) => {
                                                                     if(Lg_('auth-id') == 5){
                                                                         openMessage() // if guest account
                                                                     }else{
                                                                         Q_('.popup-kp-text').style.display = 'none'
                                                                         kpTxtEdit(printPopKP_txt[0])
                                                                     }
                                                                 }}
                                                            >{printPopKP_txt[0] === 'new' ? 'Создать' : 'Сохранить'}</div>
                                                        </div>
                                                    </div>
                                                    <div className="popup-close" onClick={() => {
                                                    setPrintPopKP_txt(['',['','','','','','','','','','','']])
                                                    Q_('.popup-kp-text').style.display = 'none'
                                                }}>×</div>
                                                </div>
                                            :   ''
                                        }
                                    </div>
                                    <div className="popup popup-kp-pic">
                                        {printPopKP_pic[0]
                                            ?   <div className="popup-wrp-dad">
                                                    <div className="popup-wrp popup-kp-pic-wrp">
                                                        <div className="param">
                                                            <p>Название изображения для КП:</p>
                                                            <input type="text" name="title" autoComplete="off" defaultValue={printPopKP_pic[1][0]}/>
                                                        </div>
                                                        <div className="param param-image">
                                                            <p>Изображение:</p>
                                                            <img id="kp_pic_edit" src={printPopKP_pic[1][2]} alt=""/>
                                                            <input name="kp_pic" type="file" autoComplete="off"
                                                                   onChange={(e)=>{
                                                                       let f = e.target.files[0]
                                                                       let fr = new FileReader()
                                                                       fr.onload = function(ev2) {
                                                                           Q_('#kp_pic_edit').setAttribute('src', ev2.target.result)
                                                                       };
                                                                       fr.readAsDataURL(f)
                                                                   }}
                                                            />
                                                        </div>
                                                        <div className="param">
                                                            <p>Растянуть вовсю ширину листа (без полей):</p>
                                                            <select autoComplete="off" name="kp_wide" defaultValue={
                                                                printPopKP_pic[1][5] === true || printPopKP_pic[1][5] == 1 ? '1' : '0'
                                                            }>
                                                                <option value="0">Нет, не растягивать</option>
                                                                <option value="1">Да, растянуть</option>
                                                            </select>
                                                        </div>
                                                        <div className="param">
                                                            <p>Конкретная ширина (не обязательно):</p>
                                                            <input type="number" name="kp_width" autoComplete="off" placeholder="525"
                                                                   defaultValue={printPopKP_pic[1][3]}/>
                                                            <span>Например ширина листа за вычетом боковых полей: 525</span>
                                                        </div>
                                                        <div className="param">
                                                            <p>Конкретная высота (не обязательно):</p>
                                                            <input type="number" name="kp_height" autoComplete="off" placeholder="300"
                                                                   defaultValue={printPopKP_pic[1][4]}/>
                                                            <span>Например высота стандартной строчки текста: 10</span>
                                                        </div>
                                                        <div className="popup-kp-pic-bottom">
                                                            {printPopKP_pic[0] !== 'new'
                                                                ?   <div className="popup-kp-pic-delete" onClick={()=>{
                                                                    if(Lg_('auth-id') == 5){
                                                                        openMessage() // if guest account
                                                                    }else{
                                                                        Q_('.popup-kp-pic').style.display = 'none'
                                                                        deleteKPpic(printPopKP_pic[0])
                                                                        setPrintPopKP_pic(['',['','','','','','','','','','','']])
                                                                    }
                                                                }}>Удалить</div>
                                                                :   ''
                                                            }
                                                            <div className="popup-kp-pic-close"
                                                                 onClick={(e) => {
                                                                     if(Lg_('auth-id') == 5){
                                                                         openMessage() // if guest account
                                                                     }else{
                                                                         Q_('.popup-kp-pic').style.display = 'none'
                                                                         kpPicEdit(printPopKP_pic[0])
                                                                     }
                                                                 }}
                                                            >{printPopKP_pic[0] === 'new' ? 'Создать' : 'Сохранить'}</div>
                                                        </div>
                                                    </div>
                                                    <div className="popup-close" onClick={() => {
                                                    setPrintPopKP_pic(['',['','','','','','','','','','','']])
                                                    Q_('.popup-kp-pic').style.display = 'none'
                                                }}>×</div>
                                                </div>
                                            :   ''
                                        }
                                    </div>
                                </div>
                                {suborders.length > 0
                                    ?   <p className="page-order-suborders-tit">Дерево подзаказов:</p>
                                    :   ''
                                }
                                {suborders.length > 0
                                    ?   <div className="page-order-suborders">
                                            <OneSubOrder thisOrder={suborders[suborders.length-1]}
                                                         notSub={true}
                                                         fromOrderList={false}
                                                         curOrder={order.id}
                                                         curSum={sumTotal}
                                                         curIspo={curIspo} setCurIspo={setCurIspo}
                                                         curStep={curStep} setCurStep={setCurStep}
                                                         curDtPlan={curDtPlan} setCurDtPlan={setCurDtPlan}
                                                         setOrder={setOrder}
                                                         suborders={suborders} setSuborders={setSuborders}
                                                         setIsContent={setIsContent}
                                                         setChanges={setChanges}
                                                         setSaving={setSaving}
                                                         loadThisOrder={loadThisOrder}
                                                         createSubOrder={createSubOrder}
                                            />
                                        </div>
                                    :   ''
                                }
                                <div className="page-order-topline">
                                    <div className="page-order-topline-breadcrumbs">
                                        {order.parent_root
                                            ?   <div className="bc-start">
                                                    <Link to="/orders">Заказы</Link>
                                                    <span>/</span>
                                                    <Link className="page-order-backtoroot" to={`/orders/${order.parent_root}`}
                                                        onClick={() => {
                                                            loadThisOrder(order.parent_root)
                                                            setOrder({})
                                                            setSuborders([])
                                                            setIsContent([])

                                                            setCurClient('')
                                                            setCurIspo('')
                                                            setCurStep('')
                                                            setCurDtPlan('')

                                                            setSaving(false)
                                                            setChanges(false)
                                                        }}
                                                    >{isLandscape ? 'Заказ ' : ''}№{order.parent_root}</Link>
                                                    {echoBreads(order.id)}
                                                </div>
                                            :   <Link to="/orders">Заказы</Link>
                                        }
                                        <span className="bc-this">
                                            {order.parent_root
                                                ?   `/ ${isLandscape ? 'Подзаказ ' : ''}№` + order.id
                                                :   order.id === 'new'
                                                        ?   '/ Новый заказ'
                                                        :   `/ ${isLandscape ? 'Заказ ' : ''}№${order.id} `
                                            }
                                        </span>
                                        {order.id === 'new' && !order.parent_root
                                            ?   ''
                                            :   <i>от {order.created}</i>
                                        }
                                        {order.new
                                            ?   <strong data-tooltip="Внесите нужные изменения и сохраните заказ, после чего эта надпись исчезнет">
                                                    Этот {order.parent_root ? 'под' : ''}заказ создан, но не отредактирован!
                                                </strong>
                                            :   ''
                                        }
                                    </div>
                                    {order.id === 'new' || order.sb == 1 && suborders.findIndex(el => el.pr == order.id) > -1
                                        ?   ''
                                        :   <div className="page-order-topline-wrp">
                                                <div className="filter page-order-topline-sub" onClick={()=> {
                                                        if(Lg_('auth-id') == 5){
                                                            openMessage() // if guest account
                                                        }else{
                                                            if(!sumReady){return}
                                                            createSubOrder('', '', order.sb != 1 ? 1 : 2)
                                                        }
                                                    }}
                                                     data-disabled={!sumReady ? '1' : '0'}
                                                ><span>+ </span>{order.sb != 1 ? 'Заявка поставщику' : 'Заказ покупателя'}
                                                </div>
                                            </div>
                                    }
                                    { isContent.length < 1 || Lg_('auth-role-prices')*1 === 7
                                        ?   ''
                                        :   <div className="page-order-topline-wrp">
                                                <div className="filter" data-disabled={!sumReady ? '1' : '0'}
                                                     onClick={() => {
                                                         if(!sumReady){return}
                                                         openPrinter()
                                                         setPrintPopKP_set('1')
                                                     }}
                                                >Документы</div>
                                            </div>
                                    }
                                    <div className={`page-order-topline-wrp ${Lg_('auth-role-prices') && Lg_('auth-role-prices')*1 === 7
                                            ? 'hideWhileCalc' : '' }`}
                                    >
                                        <select autoComplete="off" disabled={!sumReady ? true : false}
                                                className="filter" defaultValue={isPrices} disabled={sumReady ? false : true}
                                                onChange={(e) => setIsPrices(e.target.value)}
                                        >
                                            { !Lg_('auth-role-prices') || Lg_('auth-role-prices') && Lg_('auth-role-prices')*1 === 0 || Lg_('auth-role-prices') && Lg_('auth-role-prices')*1 === 1 || Lg_('auth-role-prices') && Lg_('auth-role-prices')*1 === 4 || Lg_('auth-role-prices') && Lg_('auth-role-prices')*1 === 5
                                                ?   <option value="1">Закупочные цены</option>
                                                :   ''
                                            }
                                            { !Lg_('auth-role-prices') || Lg_('auth-role-prices') && Lg_('auth-role-prices')*1 === 0 || Lg_('auth-role-prices') && Lg_('auth-role-prices')*1 === 2 || Lg_('auth-role-prices') && Lg_('auth-role-prices')*1 === 4 || Lg_('auth-role-prices') && Lg_('auth-role-prices')*1 === 6
                                                ?   <option value="2">Партнёрские цены</option>
                                                :   ''
                                            }
                                            { !Lg_('auth-role-prices') || Lg_('auth-role-prices') && Lg_('auth-role-prices')*1 === 0 || Lg_('auth-role-prices') && Lg_('auth-role-prices')*1 === 3 || Lg_('auth-role-prices') && Lg_('auth-role-prices')*1 === 5 || Lg_('auth-role-prices') && Lg_('auth-role-prices')*1 === 6
                                                ?   <option value="3">Розничные цены</option>
                                                :   ''
                                            }
                                        </select>
                                    </div>
                                    {order.id === 'new'
                                        ?   ''
                                        :   <div className="page-order-topline-wrp">
                                                <div className="filter page-order-topline-delete"
                                                     data-disabled={!sumReady ? '1' : '0'}
                                                     onClick={() => {
                                                         if(Lg_('auth-id') == 5){
                                                             openMessage() // if guest account
                                                         }else{
                                                             if(!sumReady){return}
                                                             deleteOrder(order.id)
                                                         }
                                                     }}
                                                >Удалить</div>
                                            </div>
                                    }
                                    <div className="page-order-topline-wrp">
                                        <div className={`create page-order-topline-save ${changes || saving ? 'active' : ''}`}
                                             data-disabled={!sumReady ? '1' : '0'}
                                             onClick={() => {
                                                 if(Lg_('auth-id') == 5){
                                                     openMessage() // if guest account
                                                 }else{
                                                     if(!sumReady){return}
                                                     saveOrder()
                                                 }
                                             }}
                                        >Сохранить заказ
                                            <div className="wd">{Lg_('auth-name')} {Lg_('auth-surname')}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="page-order-params">
                                    <div className="page-order-params-wrp">
                                        <div>
                                            <div>
                                                { order.parent_root
                                                    ?   <div className="param">
                                                        <p>Изначальный клиент</p>
                                                        <input type="text" autoComplete="off"
                                                               defaultValue={isSysDat.companies[order.client_root]
                                                                   ?    isSysDat.companies[order.client_root].nm + ' (id:'+order.client_root+')'
                                                                   :    order.client_root
                                                               }
                                                               disabled={true}
                                                        />
                                                    </div>
                                                    :   ''
                                                }
                                            </div>                                                         {/* Root client */}
                                            <div className="param">
                                                <p>
                                                    { order.parent_root
                                                        ? 'Фирма посредник (заказчик)'
                                                        : 'Клиент'
                                                    }
                                                </p>
                                                <div className="param-selwrp">
                                                    <select className="param-field"
                                                            name="client" autoComplete="off"
                                                            defaultValue={order.client ? order.client : ''}
                                                            disabled={!sumReady || order.parent_root ? true : false}
                                                            onChange={(e) => {
                                                                setSaving(true)
                                                                setCurClient(e.target.value)
                                                            }}
                                                    >
                                                        <option value="3059" key="3059">
                                                            {order.parent_root
                                                                ?   '--- Сторонний поставщик ---'
                                                                :   '--- Не выбран ---'
                                                            }
                                                        </option>
                                                        { order.parent_root
                                                            ?   Object.entries(isSysDat.companies).filter(el => el[1].osn[1] === true).map((el) => (
                                                                    <option value={el[1].id} key={el[1].id}>{el[1].nm} (id:{el[1].id})</option>
                                                                ))
                                                            :   Object.entries(isSysDat.companies).filter(el => el[1].osn[0] === true).sort(function(a, b){return b[1].id-a[1].id}).map((el, i) => {
                                                                    if(el[1].id != 3059){
                                                                        return(
                                                                            <option value={el[1].id} key={el[1].id}>{el[1].nm} (id:{el[1].id})</option>
                                                                        )
                                                                    }
                                                                })
                                                        }
                                                    </select>
                                                    { order.parent_root
                                                        ?   ''
                                                        :   <div className="param-selwrp-edit"
                                                                 data-tooltip="Редактировать выбранного"
                                                                 onClick={()=>{
                                                                     let prevSelVal = Q_('.page-order-params select.param-field[name="client"]').value
                                                                     if(prevSelVal != 3059){
                                                                         Q_('.popup-client').style.display = 'grid'
                                                                         let tmpClient = isSysDat.companies[prevSelVal]
                                                                         setPopClientCur('client')
                                                                         setPopClient(tmpClient)
                                                                     }else{
                                                                         openMessage('Клиент не выбран', 'Выберите клиента из списка или создайте нового, прежде чем его редактировать.')
                                                                     }
                                                                 }}
                                                            ><IcoMenEdit/></div>
                                                    }
                                                    { order.parent_root
                                                        ?   ''
                                                        :   <div className="param-selwrp-new" data-tooltip="Создать нового"
                                                                 onClick={()=>{
                                                                     Q_('.popup-client').style.display = 'grid'
                                                                     let tmpClientBlank = JSON.parse(JSON.stringify(popClientBlank))
                                                                     tmpClientBlank.osn[0] = true
                                                                     setPopClientCur('client')
                                                                     setPopClient(tmpClientBlank)
                                                                 }}
                                                            ><IcoMenNew/></div>
                                                    }
                                                </div>
                                            </div>                              {/* Client / Customer company */}
                                            <div className="param">
                                                <p>Фирма исполнитель</p>
                                                <div className="param-selwrp">
                                                    <select className="param-field"
                                                            name="zakaz_ispolnitel" autoComplete="off"
                                                            defaultValue={order.ispo
                                                                ? order.ispo
                                                                : order.parent_root
                                                                    ? '3059'
                                                                    : Lg_('auth-company-id')
                                                            }
                                                            disabled={!sumReady || order.sb == 2 ? true : false}
                                                            onChange={(e) => {
                                                                setSaving(true)
                                                                setCurIspo(e.target.value)
                                                            }}
                                                    >
                                                        {Object.entries(isSysDat.companies).filter(el => el[1].osn[1] === true).map((el) => (
                                                            <option value={el[1].id} key={el[1].id}>{el[1].nm} (id:{el[1].id})</option>
                                                        ))}
                                                        <option value="3059" key="3059">--- Сторонний поставщик ---</option>
                                                    </select>
                                                    { order.sb == 2
                                                        ?   ''
                                                        :   <div className="param-selwrp-edit"
                                                                 data-tooltip="Редактировать выбранного"
                                                                 onClick={()=>{
                                                                     let prevSelVal = Q_('.page-order-params select.param-field[name="zakaz_ispolnitel"]').value
                                                                     if(prevSelVal != 3059){
                                                                         Q_('.popup-client').style.display = 'grid'
                                                                         let tmpClient = isSysDat.companies[prevSelVal]
                                                                         setPopClientCur('partner')
                                                                         setPopClient(tmpClient)
                                                                     }else{
                                                                         openMessage('Сторонний поставщик', 'Опция "сторонний поставщик" является пустой и не редактируется. Выберите другую фирму исполнителя из выпадающего списка или создайте новую, прежде чем её редактировать.')
                                                                     }
                                                                 }}
                                                            ><IcoMenEdit/></div>
                                                    }
                                                    { order.sb == 2
                                                        ?   ''
                                                        :   <div className="param-selwrp-new" data-tooltip="Создать нового"
                                                                 onClick={()=>{
                                                                     Q_('.popup-client').style.display = 'grid'
                                                                     let tmpClientBlank = JSON.parse(JSON.stringify(popClientBlank))
                                                                     tmpClientBlank.osn[1] = true
                                                                     setPopClientCur('partner')
                                                                     setPopClient(tmpClientBlank)
                                                                 }}
                                                            ><IcoMenNew/></div>
                                                    }
                                                </div>
                                            </div>                              {/* Contractor company */}
                                            <div className="param">
                                                <p>Ответственный сотрудник</p>
                                                <select className="param-field"
                                                        name="responsible" autoComplete="off" defaultValue={order.responsible ? order.responsible : ''}
                                                        disabled={!sumReady ? true : false}
                                                        onChange={() => setSaving(true)}
                                                >
                                                    { isSysDat.users.map((el) => (
                                                        <option value={el.value} key={el.value}>{el.role}: {el.name}</option>
                                                    ))}
                                                </select>
                                            </div>                              {/* Responsible */}
                                            <div className="param param-hidden">
                                                <p>Закупочная цена</p>
                                                <input className="param-field" name="zakaz_price_zacupo" type="text" autoComplete="off"/>
                                            </div>           {/* Purchase price */}
                                            <div className="param param-hidden">
                                                <p>Партнёрская цена</p>
                                                <input className="param-field" name="zakaz_price_partner" type="text" autoComplete="off"/>
                                            </div>           {/* Partner price */}
                                            <div className="param param-hidden">
                                                <p>Розничная цена</p>
                                                <input className="param-field" name="zakaz_price_roznica" type="text" autoComplete="off"/>
                                            </div>           {/* Retail price */}
                                            <div className="param param-hidden">
                                                <p>Сохранённый тип цены</p>
                                                <input className="param-field" name="prices_type" type="number" autoComplete="off"
                                                       defaultValue={isPrices}
                                                />
                                            </div>           {/* Saved price type */}
                                        </div>
                                        <div>
                                            <div className="param">
                                                <p>Стадия заказа</p>
                                                <select className="param-field"
                                                        name="step" autoComplete="off" defaultValue={order.st ? order.st : ''}
                                                        disabled={!sumReady ? true : false}
                                                        onChange={(e) => {
                                                            setSaving(true)
                                                            setCurStep(e.target.value)
                                                        }}
                                                >
                                                    {Object.entries(isSysDat.st).sort(function(a, b){return b[0]-a[0]}).map((el) => (
                                                        <option value={el[0]} key={el[0]}>{el[1]}</option>
                                                    ))}
                                                </select>
                                            </div>                              {/* Step */}
                                            <div className="param">
                                                <p>Планируемая дата готовности</p>
                                                <input className="param-field"
                                                       name="zakaz_ready_plan" type="datetime-local" autoComplete="off"
                                                       defaultValue={order.dt_plan ? order.dt_plan : ''}
                                                       disabled={!sumReady ? true : false}
                                                       onChange={(e) => {
                                                           setSaving(true)
                                                           setCurDtPlan(e.target.value)
                                                       }}
                                                />
                                            </div>                              {/* Date plan */}
                                            <div className="param">
                                                <p>Фактическая дата готовности</p>
                                                <input className="param-field"
                                                       name="zakaz_ready_fact" type="datetime-local" autoComplete="off"
                                                       defaultValue={order.dt_fact ? order.dt_fact : ''}
                                                       disabled={!sumReady ? true : false}
                                                       onChange={() => setSaving(true)}
                                                />
                                            </div>                              {/* Date fact */}
                                        </div>
                                        <div>
                                            <div className="param">
                                                <p>Город заказа</p>
                                                <select className="param-field"
                                                        name="city" autoComplete="off" defaultValue={order.ct
                                                    ? order.ct
                                                    : Lg_('auth-city-id')
                                                }
                                                        disabled={!sumReady || Lg_('auth-role-cities')*1 === 1 || order.parent_root ? true : false}
                                                        onChange={() => {
                                                            setChanges(false)
                                                            setTimeout(function () {
                                                                setChanges(true)
                                                            }, 50)
                                                        }}
                                                >
                                                    {Object.entries(isSysDat.cities).map((el) => (
                                                        <option value={el[0]} key={el[0]}>{el[1]}</option>
                                                    ))}
                                                </select>
                                            </div>                              {/* City */}
                                            <div className="param">
                                                <p>Адрес проведения работ</p>
                                                <input className="param-field"
                                                       name="address" type="text" autoComplete="off" defaultValue={order.address ? order.address : ''}
                                                       disabled={!sumReady || order.parent_root ? true : false}
                                                       onChange={() => {
                                                           setSaving(true)
                                                       }}
                                                />
                                            </div>                              {/* Address */}
                                            <div className="param">
                                                <p>Способ оплаты</p>
                                                <select className="param-field"
                                                        name="payment_type" autoComplete="off" defaultValue={order.payment_type ? order.payment_type.value : ''}
                                                        disabled={!sumReady ? true : false}
                                                        onChange={() => {
                                                            setChanges(false)
                                                            setTimeout(function () {
                                                                setChanges(true)
                                                            }, 50)
                                                        }}
                                                >
                                                    { isSysDat.payment_type.map((el) => (
                                                        <option value={el.value} key={el.value}>{el.name}</option>
                                                    ))}
                                                </select>
                                            </div>                              {/* Payment */}
                                        </div>
                                        <div>
                                            <div className="param">
                                                <p>Описание заказа</p>
                                                <textarea className="param-field" name="description" rows="5" autoComplete="off"
                                                          defaultValue={order.description ? order.description : ''}
                                                          disabled={!sumReady ? true : false}
                                                          onChange={() => setSaving(true)}
                                                />
                                            </div>                              {/* Discription */}
                                        </div>
                                    </div>
                                </div>
                                <div className="page-order-content">
                                    { !isLandscape
                                        ?   <p className="title">Состав:</p>
                                        :   ''
                                    }
                                    <div className={`page-order-result ${Lg_('auth-role-prices')*1 === 7 ? 'hideWhileCalc' : ''}`}>
                                        { isContent.length > 0
                                            ?   <p className="title">
                                                    Итоговая стоимость: <span><strong className="page-order-result-summ-in" data-price0="">0</strong> р</span>
                                                </p>
                                            :   ''
                                        }
                                    </div>
                                    <div className="page-order-content-wrp">
                                        { isContent
                                            ?   isContent.map((el, i) => {
                                                    if(el.type === 'nom' && !isSysDat.nomenclature[el.id]){}else{
                                                        return(
                                                            <OneElement key={i+'_'+el.pos}
                                                                element={el}
                                                                root={true}
                                                                deep={0}
                                                                paCount={false}

                                                                order={order}
                                                                isContent={isContent} setIsContent={setIsContent}
                                                                changes={changes} setChanges={setChanges}
                                                                rolled={rolled} setRolled={setRolled} rootpos={el.pos}
                                                                setPricePopData={setPricePopData}
                                                                setNomPopParams={setNomPopParams}

                                                                addElement={addElement}
                                                                deleteElement={deleteElement}
                                                                cloneElement={cloneElement}
                                                                editNomPrice={editNomPrice}
                                                            />
                                                        )
                                                    }
                                                })
                                            :   ''
                                        }
                                    </div>
                                    <div className="page-order-content-bottom">
                                        <div className="page-order-content-add-wrp">
                                            <BlockAddElement from="order" deep="0"
                                                 paType="" paPos=""
                                                 isNom={addNom} setNom={setAddNom}
                                                 isCat={addCat} setCat={setAddCat}
                                                 active={addActive} setActive={setAddActive}
                                                 addElement={addElement}
                                            />
                                        </div>
                                        { !changes && !saving
                                            ?   <div></div>
                                            :   <div className={`create active ${!sumReady ? 'hideWhileCalc' : ''}`}
                                                      onClick={() => saveOrder()}
                                                >Сохранить {isLandscape ? 'заказ' : ''}
                                                    <div className="wd">{Lg_('auth-name')} {Lg_('auth-surname')}</div>
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        :   <h6>Загрузка номенклатур и системных данных, ожидайте...<span>Если очень долго грузится, то нажмите обновить страницу</span></h6>
                    :   order.error
                        ?   <h6>Заказ удалён!<span>нет данных об этом заказе</span></h6>
                        :   <h6>Загрузка заказа, ожидайте...<span>Если долго не грузится, то нажмите обновить страницу</span></h6>
                :   <h6>Загрузка заказа, ожидайте...<span>Если долго не грузится, то нажмите обновить страницу</span></h6>
            }
        </div>
    )
};

export default PgOrder;