import React, {useContext} from 'react';
import {AuthContext} from "../context/Context";
import {useNavigate} from "react-router-dom";
import IcoTrash from "./IcoTrash";
import IcoList from "./IcoList";

const OneClient = (props) => {
    // states and vars
    const navigate = useNavigate()
    const {Q_, Lg_, isSysDat, deleteClient, setPopClient, setPopClientCur, ordersReload, setOrdersReload, setFilterClient, openMessage} = useContext(AuthContext)

    return (
        <div className="one-client" onClick={() => {
            Q_('.popup-client').style.display = 'grid'
            let tmpClient = isSysDat.companies[props.thisClient.id]
            setPopClientCur('client')
            setPopClient(tmpClient)
        }}>
            <div className="one-client-osn">
                <p>{props.thisClient.nm} <span>- id:{props.thisClient.id}</span></p>
                <p>{isSysDat.cities[props.thisClient.osn[2]]}</p>
                <p>{props.thisClient.osn[3]}{props.thisClient.osn[4] ? ', '+props.thisClient.osn[4] : ''}</p>
                <div className="one-client-list" data-tooltip="Показать заказы этого клиента"
                     onClick={(e) => {
                         e.preventDefault()
                         e.stopPropagation()
                         Q_('.popup-wait').style.display = 'grid'
                         navigate('/orders/')
                         setTimeout(function () {
                             setOrdersReload(!ordersReload)
                             setFilterClient(props.thisClient.id)
                         },1500)
                     }}
                ><IcoList/></div>
                <div className="one-client-delete" data-tooltip="Удалить"
                     onClick={(ev) => {
                         ev.preventDefault()
                         ev.stopPropagation()
                         if(Lg_('auth-id') == 5){
                             openMessage() // if guest account
                         }else{
                             deleteClient(props.thisClient.id)
                         }
                     }}
                ><IcoTrash/></div>
            </div>
            {props.thisClient.fiz[0]
                ?   <div className="one-client-fiz">
                        <p>Физ.лицо: {props.thisClient.fiz[1]}</p>
                        <p>{props.thisClient.fiz[2] ? `Паспорт: ${props.thisClient.fiz[2]} ${props.thisClient.fiz[3]}` : ''}</p>
                        <p>{props.thisClient.fiz[7] ? `День рождения: ${props.thisClient.fiz[7]}` : ''}</p>
                    </div>
                :   ''
            }
            {props.thisClient.ur[0]
                ?   <div className="one-client-ur">
                        <p>Юр.лицо: {props.thisClient.ur[1]} {props.thisClient.ur[2] ? `"${props.thisClient.ur[2]}"` : ''}</p>
                        <p>{props.thisClient.ur[4] ? `ИНН: ${props.thisClient.ur[4]}` : ''}</p>
                        <p>{props.thisClient.ur[11] ? `Директор: ${props.thisClient.ur[11]}` : ''}</p>
                    </div>
                :   ''
            }
            {props.thisClient.gos[0]
                ?   <div className="one-client-gos">
                    <p>Гос.учереждение: {props.thisClient.gos[1]}</p>
                    <p>{props.thisClient.gos[2] ? `Адрес: ${props.thisClient.gos[2]}` : ''}</p>
                </div>
                :   ''
            }
        </div>
    )
}

export default OneClient